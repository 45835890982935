<template>
    <div class="pathways" >
        <h1 class="mb-2">Pathways</h1>
        <!-- <p class="mb-3">Pathways are a simple way to link your various tools together so that critical data is kept in sync. <a :href="'/settings/v1/' + client.url + '/integrations'">Once you've made an integration</a> into one of your marketing or fundraising systems, it will show up below (if data is able to be pushed). Below each system, you will see the available segments within each data set and data blend. If there are additional segments you'd like to push, <a href="#" @click.prevent="addNewSegment()" data-bs-toggle="modal" data-bs-target="#segment-modal">simply create it here</a>.</p> -->

        <div class="row px-2 mb-5">
            <nav id="pathway-tab-nav" class="navbar navbar-expand primary-tab-bar pb-0">
                <div class="container-fluid">
                    <div class="collapse navbar-collapse">
                    <ul class="navbar-nav mb-lg-0">
                        <li class="nav-item" v-for="(tab_name, index) in pathway_tabs">
                            <a class="nav-link d-flex flex-nowrap pb-0 mb-0 mx-2 primary-tab" :class="{'primary-tab-active': index == pathway_tab_index_active}"
                            href="#" role="button" aria-expanded="false" @click.prevent="setActiveTab(index)">
                                <span>{{ tab_name }}</span>
                                <!--<p>Optional description</p>-->
                            </a>
                        </li>
                    </ul>
                    </div>
                </div>
            </nav>

            <div class="card box-content p-0 mb-5">
                <div class="card-body">
                    <!-- 
                    <div v-if="pathway_tab_index_active == 1">
                       <pathways-manage :connections="connections" :client="client" :segments="local_segments" :datasets= "datasets" 
                       :datablends= "datablends" :census_connections="census_connections"></pathways-manage>
                    </div> -->
                    <div v-if="pathway_tab_index_active == 0">
                        <pathways-push-segment :connection_datasets="connection_datasets" :client="client" :segments="local_segments"
                        :datasets= "datasets" :datablends= "datablends" :census_connections="census_connections"
                        :segment_selected_passed="segment_selected_passed" :pathway_selected_passed="pathway_selected_passed"></pathways-push-segment>
                    </div>
                    <div v-if="pathway_tab_index_active == 1">
                        <pathways-manage :connection_datasets="connection_datasets" :client="client" :segments="local_segments" :tab_name="tab_name" 
                        :datasets="datasets" :datablends="datablends" :census_connections="census_connections"></pathways-manage>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['datasets', 'datablends', 'connections', 'client', 'census_connections', 'segments',
        'segment_selected_passed', 'pathway_selected_passed', 'tab_name'],
        data() {
            return {
                pathway_tabs:['PUSH SEGMENTS', 'MANAGE'],
                pathway_tab_index_active: 0,
                local_datasets: [],
                local_datablends: [],
                local_census_connections: [],
                connection_datasets:[],
            };
        },
        beforeMount() {
            this.local_datasets = this.datasets;
            this.local_datablends = this.datablends;
            this.local_census_connections = this.census_connections;
            this.local_segments = this.segments;
            //loop thgrough local_segments and remove an element if the dataset and datablend are null
            this.local_segments = this.local_segments.filter(segment => segment.data_source != null);
            this.local_segments.forEach(seg =>{
                this.$set(seg, 'pushed_pathways', []);
            })

            if (this.tab_name =='push_segment'){
                this.pathway_tab_index_active = 0;
            }
            if (this.tab_name =='manage_pathways' || this.tab_name =='manage_segments'){
                this.pathway_tab_index_active = 1;
            }

            this.bindAndFilterOutSelfConnectingDatasets();
        },
        methods: {

            setActiveTab(index){
                this.pathway_tab_index_active = index;

                var newURL = '/pathways/'+this.client.url +'/';
                if (index == 0){
                    newURL += 'push_segment';
                } else {
                    newURL += 'manage_pathways';
                }
                
                window.history.replaceState({}, {}, newURL);
                this.$forceUpdate();
            },
            bindAndFilterOutSelfConnectingDatasets(){
                var connection_dataset_list = []
                this.connections.forEach(connect => {
                    
                    var dataset_to_sync_list = []

                    this.local_datablends.forEach(datablend => {
                        datablend.name = datablend.type.charAt(0).toUpperCase() + datablend.type.slice(1) + " Data Blend";
                        if(datablend.segments.length > 0)
                            dataset_to_sync_list.push(datablend)
                    });

                    this.local_datasets.forEach(dataset => {
                        if(dataset.connection_id != connect.id){
                            if(dataset.segments.length > 0)
                                dataset_to_sync_list.push(dataset)
                        }
                    });
                    
                    this.$set(connect, 'datasets_to_sync', dataset_to_sync_list)
                    connection_dataset_list.push(connect)

                });
                this.connection_datasets = connection_dataset_list;
                this.filtered_connection_datasets = connection_dataset_list;

                this.getSegmentDetails();
            },
            getSegmentDetails(){  // The one previously used a lot of object referrencing and cause some bug, I had to destruct the arrays and object to fix the issue
                this.connection_datasets.forEach(conn => {
                    var count_temp = 0
                    var datasets_to_sync_temp = [];
                    conn.datasets_to_sync.forEach(dataset =>{
                        var dataset_temp = {...dataset};  // create new copy of dataset array
                        var segments_temp = [];
                        dataset.segments.forEach(segment =>{
                            var seg_temp = {...segment}; // create new copy of segment object then update the new object
                            var lastSynced = this.lastSynced(conn.id, seg_temp);

                            seg_temp.last_synced = lastSynced.last_synced_at;
                            seg_temp.sync_errors = lastSynced.last_sync_status;

                            if(lastSynced.last_synced_at != null){
                                seg_temp.sync_active = true;
                                var index = this.local_segments.findIndex((seg)=> seg.id == seg_temp.id);
                                if(!this.local_segments[index].pushed_pathways.includes(conn.name)){
                                    this.local_segments[index].pushed_pathways.push(conn.name);
                                }
                                count_temp++;
                            }else{
                                seg_temp.sync_active = false;
                            }
                            segments_temp.push(seg_temp);
                        })
                        dataset_temp.segments = [...segments_temp];
                        datasets_to_sync_temp.push(dataset_temp);
                    })
                    conn.datasets_to_sync = datasets_to_sync_temp;
                    conn.pushed_segment_count = count_temp;
                });
            },
            lastSynced(conn_id, segment) {
                let conn = this.getCensusConnection(conn_id, segment);
                var sync = this.getCensusSync(conn, segment);
                if(sync != null){
                    //Find out if it is a file export
                    if(conn.destination_object == 'file') {
                        return {
                            last_synced_at: sync.updated_at,
                            last_sync_status: null
                        };
                    }
                    return {
                        last_synced_at: sync.last_synced_at,
                        last_sync_status: sync.last_sync_status
                    };
                }
                else{
                    return {
                        last_synced_at: null,
                        last_sync_status: null
                    };
                }
            },
            getCensusConnection(conn_id, segment) {
                for(var i = 0; i < this.local_census_connections.length; i++) {
                    if(this.local_census_connections[i].connection_id == conn_id && segment.source == this.local_census_connections[i].source
                        && segment.data_source_id == this.local_census_connections[i].data_source_id) {
                            return this.local_census_connections[i];
                    }
                }
                return null;
            },
            getCensusSync(conn, segment) {
                if(conn == null) return null;

                for(var j = 0; j < conn.syncs.length; j++) {
                    if(conn.syncs[j].segment_id == segment.id)
                        return conn.syncs[j];
                }
                return null;
            }
        }
    }
</script>
