<template>
    <div class="asset-details">
        <div :id="'asset-card-'+asset.id">
            <div class="card-body position-relative">
                <div class="row" v-if="asset.status !='approved' || is_editing_approved_asset">
                    <div class=" mb-3" :class="((!is_client_edit_toggled && (local_asset.status == 'for_approval' || local_asset.status == 'in_review') && local_asset.latest_review) || is_client_edit_toggled ) ? 'col-7' : 'col-9'">
                        <label class="form-label" :class="{'border-bottom' : asset.status =='approved' && !is_editing_approved_asset}">Name</label>
                        <input class="form-control" v-model="local_asset.name" @change="inputFieldValueChange()" :class="((errors['asset_name'] != '' )? 'is-invalid':'')"/>
                        <div class="text-danger invalid-feedback" v-if="((errors['asset_name'] && errors['asset_name']) != '' )" v-html="errors['asset_name']">
                        </div>
                    </div>
                    <div class="col-3 mb-3">
                        <label class="form-label">Status</label>
                        <select class="form-select" id="status-select" v-model="local_asset.status" @change="inputFieldValueChange()" >
                            <option value="" disabled selected>Select the status...</option>
                            <option value="draft">Draft</option>
                            <option value="for_approval">Ready for Review</option>
                            <option value="in_review">Reviewed</option>
                            <option value="approved">Approved</option>
                        </select>
                    </div>
                    <div class="col-2 mb-3" v-if="(!is_client_edit_toggled && (local_asset.status == 'for_approval' || local_asset.status == 'in_review') && local_asset.latest_review) || is_client_edit_toggled">
                        <label class="form-check-label form-label">Client Edits</label>                    
                        <div class="copy-toggle-button form-check form-switch" >
                            <input class="form-check-input" type="checkbox" role="switch" :checked="is_client_edit_toggled" @change="toggleClientEdits" 
                            data-bs-toggle="tooltip" data-bs-placement="bottom" 
                            title="When this switch is on, it shows the latest client approved copy of the asset detail. When it is off, it shows the original copy.">
                        </div>                        
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col mb-3">
                        <label class="form-label border-bottom">Name</label>
                        <p class="mb-0">{{ local_asset.name }}</p>
                    </div>
                    <!-- <div  class="ribbon"><span>{{local_asset.status | propercase}}</span></div> -->
                </div>

                <div class="row">
                    <div class="col">
                        <label class="form-label" :class="{'border-bottom': asset.status =='approved' && !is_editing_approved_asset}">Asset Description
                            <!-- <a class="ms-2" :href='"mailto:kevin@nextafter.com?subject=Asset Description Suggestion&body=Asset ID: "+local_asset.id+"%0D%0ACampaign Type ID: "+local_asset.campaign.campaign_type_id+"%0D%0AAsset Type ID: "+local_asset.asset_type_id+"%0D%0A"+local_asset.description' 
                            target="_blank" v-if="asset.status !='approved' || is_editing_approved_asset" data-bs-toggle="tooltip" title="Did you write a description that made an awesome asset? Click this button to email it to Kevin." >
                            <i class="fas fa-envelope"></i>
                        </a> -->
                    </label>
                        <textarea :id="'description-'+asset.id" v-if="asset.status !='approved' || is_editing_approved_asset" class="form-control full-height"
                        v-model="local_asset.description" @change="inputFieldValueChange('description-'+asset.id)" ref="textarea"></textarea>

                        <p v-else-if="local_asset.description != null && local_asset.description != '' && local_asset.description != 'TODO'" class="mb-0">{{ local_asset.description }}</p>
                    </div>
                </div>

                <div class="divider mt-3">The content below will be automatically generated or you can add information manually</div>
                
                <div class="mt-3" v-if="(asset.status !='approved' || (asset.status=='approved' && is_editing_approved_asset))">
                    <div v-for="(input_field, index) in local_inputs" :key="asset.id + input_field.placeholder">
                        <div class="row mb-3">
                            <div class="col-12">

                                <label class="form-label mb-2">
                                    <span data-bs-toggle="tooltip" data-bs-placement="bottom" :title="input_field.description">{{input_field.name}}
                                    <em v-if="input_field.optional == 1">(Optional)</em></span>
                                    <a v-if="local_asset.source == 'generated' && (input_field.type=='text'|| input_field.type=='textarea' || input_field.type=='wysiwyg')" 
                                    class="ms-2" href="#" data-bs-toggle="modal" :data-bs-target="'#refine-prompt-modal-'+local_asset.id"
                                    @click="regenerateIndividualInput(input_field)">
                                        <span data-bs-toggle="tooltip" title="Regenerate this element">
                                            <i class="fas fa-undo"></i>
                                        </span>
                                    </a>
                                    <a v-if="input_field.type=='image'" class="ms-2" href="#" @click.prevent="addEmptyImageSlot(index)">
                                        <span data-bs-toggle="tooltip" title="Add Image">
                                            <i class="fas fa-plus"></i>
                                        </span>
                                    </a>
                                </label>

                                <div>
                                    <div :class="getErrorForMaxLength(input_field)" :maxlength="input_field.max_length" :currentlength="(input_field.value != null) ? input_field.value.length: 0" v-if="(input_field.type=='text' || input_field.type=='link')">
                                        <input type="text" v-model="input_field.value" :maxlength="input_field.max_length"
                                        class="form-control" :class="getErrorForInputField(input_field)" @change="inputFieldValueChange()">
                                    </div>
                                    <textarea :id="input_field.placeholder+'-'+asset.id" v-if="(input_field.type=='textarea' || input_field.type=='template')"  v-model="input_field.value"  :maxlength="input_field.max_length" class="full-height form-control" 
                                    :class="((errors[input_field.placeholder] != '' )? 'is-invalid':'')" @change="inputFieldValueChange(input_field.placeholder+'-'+asset.id)" ref="textarea"></textarea>
                                    
                                    <div v-if="input_field.type=='image'" class="image-array-section" >
                                        <div class="me-3" v-for="(image_url, image_index) in input_field.value">
                                            <vue-dropzone ref="image-dropzone" class="input-dropzone" :class="(image_url == null)?'':'has-background'" :id="'image-dropzone-' + local_asset.id +'-'+image_index"
                                                @vdropzone-s3-upload-success="imageUpload" :style="(image_url != null) ? 'background-image: url(\'' +image_url+'\')' : ''" :disabled="asset.status =='approved'"
                                                @vdropzone-sending="imageUploadStarted(input_field, image_index)"
                                                :options="imageDropzoneOptions" :awss3="awss3" ></vue-dropzone>
                                            <div class="image-field-buttons">
                                                <span>
                                                    <!-- <label class="form-label mb-2" v-if="local_asset.source == 'generated'">
                                                        <a  class="ms-2" href="#" data-bs-toggle="modal" 
                                                        :data-bs-target="'#refine-prompt-modal-'+local_asset.id" @click="regenerateIndividualInput(input_field, image_index)">
                                                            <i class="fas fa-undo"></i>Regenerate
                                                        </a> 
                                                    </label>  -->

                                                    <label class="form-label mb-2" v-if="image_url != null">
                                                        <a class="ms-2" href="#" @click.prevent="deleteImage(index, image_index)"><i class="fas fa-trash"></i>Delete</a> 
                                                    </label>                                                
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <vue-dropzone v-if="input_field.type=='file'" ref="file-dropzone"
                                        class="input-dropzone" :id="'file-dropzone-' + local_asset.id"
                                        :options="fileDropzoneOptions" :disabled="asset.status =='approved'"
                                        v-on:vdropzone-processing="onProcessing"
                                        v-on:vdropzone-success="onSuccess"
                                        v-on:vdropzone-sending="onSending"></vue-dropzone>
                                    <!-- <vue-editor v-if="input_field.type=='wysiwyg'"  v-model="input_field.value"
                                        :editorToolbar="customToolbar" @focus="inputFieldValueChange()" :class="((errors[input_field.placeholder] != '' )? 'is-invalid':'')"></vue-editor>
                                     -->
                                     <wysiwyg-editor-advanced v-if="input_field.type=='wysiwyg'" :if_show_button_input="if_show_button_input" :input_value="input_field.value" :input_index="index" :asset_id="local_asset.id"
                                        @wysiwygValueChange="wysiwygValueChange"></wysiwyg-editor-advanced>

                                    <select v-if="input_field.type=='dropdown'"  v-model="input_field.value" class="form-select w-auto form-control"
                                    :class="((errors[input_field.placeholder] != '' )? 'is-invalid':'')" @change="inputFieldValueChange()">
                                        <option value="" disabled selected>Select the value...</option>
                                        <option v-for="option in input_field.options" :value=option>{{ option }}</option>
                                    </select>                                    
                                </div>

                                <div class="text-danger invalid-feedback" v-if="(errors[input_field.placeholder] != null && errors[input_field.placeholder] != '' )" v-html="errors[input_field.placeholder]"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else >
                    <div class="mt-3 ">
                        <div v-for="input_field in this.local_inputs">
                            <div class="row mb-3" v-if="(input_field.value != '' && input_field.value != null) || input_field.optional == 0">
                                <div class="col-12">
                                    <label class="form-label border-bottom">{{input_field.name}}</label>
                                    <div v-if="input_field.type=='image'" class="image-array-section">
                                        <div v-for="(image_url, image_index) in input_field.value">
                                            <img :src="image_url" class="me-3 mb-1 img-thumbnail">
                                        </div>
                                    </div>
                                    <div v-else v-html="input_field.value"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <!-- Success Message -->
                        <div class="alert alert-danger" v-if="form.error">
                            There was an error saving the asset information.
                        </div>
                        <!-- Update Button -->
                        <span class="dropup"  v-if="asset.status !='approved'">
                            <button type="button" class="btn btn-primary me-2 dropdown-toggle"
                                 :disabled="form.busy"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-btn fa-edit"></i> <span>{{ (local_asset.source == 'generated'? 'Regenerate Asset ' : 'Generate Asset ' )}}</span>
                            </button>

                            <ul class="dropdown-menu pt-0 prompt-dropdown" ref="dropdown">
                                <li class='px-3 py-2 text-white form-label bg-dark'>Generate asset based upon ...</li>
                                <li class="dropdown-item btn btn-link" @click.prevent="generatePrompt('description', null)">Description Only</li>
                                <li v-if="pages != null && pages.length > 0"><button class="dropdown-item btn btn-link" href="#" @click.stop="toggleLandingOptions()">Other Assets
                                    <i v-if="!is_landing_pages_expanded" class="fa fa-caret-right mx-1"></i>
                                    <i v-else class="fa fa-caret-down mx-1"></i>
                                </button></li>
                                <ul v-if="pages != null && pages.length > 0 && is_landing_pages_expanded" class="my-1">
                                    <li v-for="page in pages" ><button class="btn btn-link p-0 dropdown-item" @click="generatePrompt('page', page.id)">{{ page.asset_type.name.substring(0, page.asset_type.name.length-1) }}: {{ page.name }}</button></li>
                                </ul>
                                <li><button class="dropdown-item btn btn-link" href="#" @click.stop="toggleDonorPersonaOptions()">Constituent Personas
                                    <i v-if="!is_donor_personas_expanded" class="fa fa-caret-right mx-1"></i>
                                    <i v-else class="fa fa-caret-down mx-1"></i>
                                </button></li>
                                <ul v-if="client.personas != null && is_donor_personas_expanded" class="my-1">
                                    <li v-for="persona in client.personas" ><button class="btn btn-link p-0 dropdown-item" @click="generatePrompt('donor_persona', persona.description)">{{ persona.name }}</button></li>
                                </ul>
                                <ul v-if="client.personas == null && is_donor_personas_expanded" class="my-1">
                                    <li><a :href="'/settings/'+ client.url +'/#personas'" target="_blank" class=" dropdown-item">Define your first persona <span class="ms-2"><i class="fa-solid fa-arrow-up-right-from-square "></i></span></a></li>
                                </ul>

                                <li><button class="dropdown-item btn btn-link" href="#" @click.stop="toggleBehavioralConceptOptions()">Behavioral Psychology Concept
                                    <i v-if="!is_concept_expanded" class="fa fa-caret-right mx-1"></i>
                                    <i v-else class="fa fa-caret-down mx-1"></i>
                                </button></li>
                                <ul v-if="is_concept_expanded" class="my-1">
                                    <li v-if="concepts.length == 0">No behavioral concepts defined yet</li>
                                    <li v-for="concept in concepts"><button class="btn btn-link p-0 dropdown-item" @click="generatePrompt('concept', concept.id)"
                                        data-bs-toggle="tooltip" data-bs-placement="right" :title="concept.definition">
                                         {{ concept.name }} </button></li>
                                </ul>

                                <!--<li class="dropdown-item btn btn-link" @click.prevent="generatePrompt('similar_organization', null)">Top Performing Assets for Similar Organizations</li>-->
                                <li><a class="dropdown-item btn btn-link" href="#" data-bs-toggle="modal" :data-bs-target="'#provide-example-modal-'+local_asset.id">
                                    Provide Examples
                                </a></li>
                                <li v-if="local_asset.source == 'generated'"><a class="dropdown-item btn btn-link" href="#" data-bs-toggle="modal" :data-bs-target="'#refine-prompt-modal-'+local_asset.id" @click="is_variant=false;">
                                    Provide Additional Direction to AI
                                </a></li>
                            </ul>
                        </span>

                        <span class="dropup" v-if="hasAllInputs() && (asset.status !='approved' || (asset.status =='approved' && !is_editing_approved_asset))">
                            <button type="button" class="btn me-2 dropdown-toggle"
                                 :disabled="form.busy" :class="(asset.status =='approved' && !is_editing_approved_asset)? 'btn-primary' : 'btn-secondary'"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-btn fa-copy"></i> Make a Variant
                            </button>

                            <ul class="dropdown-menu pt-0 prompt-dropdown" ref="variant_dropdown">
                                <li class='px-3 py-2 text-white form-label bg-dark'>Generate a variant based upon ...</li>
                                <li class="dropdown-item btn btn-link" @click.prevent="copyApprovedAsset(local_asset)">Duplicate Asset</li>
                                <li><button class="dropdown-item btn btn-link" href="#" @click.stop="toggleDonorPersonaOptions()">Constituent Personas
                                    <i v-if="!is_donor_personas_expanded" class="fa fa-caret-right mx-1"></i>
                                    <i v-else class="fa fa-caret-down mx-1"></i>
                                </button></li>
                                <ul v-if="client.personas != null && is_donor_personas_expanded" class="my-1">
                                    <li v-for="persona in client.personas" ><button class="btn btn-link p-0 dropdown-item" @click="generateVariantPrompt('donor_persona', persona.description)">{{ persona.name }}</button></li>
                                </ul>
                                <ul v-if="client.personas == null && is_donor_personas_expanded" class="my-1">
                                    <li><a :href="'/settings/'+ client.url +'/#personas'" target="_blank" class=" dropdown-item">Define your first persona <span class="ms-2"><i class="fa-solid fa-arrow-up-right-from-square "></i></span></a></li>
                                </ul>

                                <li><button class="dropdown-item btn btn-link" href="#" @click.stop="toggleBehavioralConceptOptions()">Behavioral Psychology Concept
                                    <i v-if="!is_concept_expanded" class="fa fa-caret-right mx-1"></i>
                                    <i v-else class="fa fa-caret-down mx-1"></i>
                                </button></li>
                                <ul v-if="is_concept_expanded" class="my-1">
                                    <li v-if="concepts.length == 0">No behavioral concepts defined yet</li>
                                    <li v-for="concept in concepts"><button class="btn btn-link p-0 dropdown-item" @click="generateVariantPrompt('concept', concept.id)"
                                        data-bs-toggle="tooltip" data-bs-placement="right" :title="concept.definition">
                                         {{ concept.name }} </button></li>
                                </ul>
                                <li><a class="dropdown-item btn btn-link" href="#" data-bs-toggle="modal" :data-bs-target="'#refine-prompt-modal-'+local_asset.id" @click="is_variant=true;">
                                    Describe the Modification
                                </a></li>
                            </ul>
                        </span>
                        <button type="button" class="btn btn-secondary me-2" v-if="(asset.status =='approved' && !is_editing_approved_asset)"
                                :disabled="form.busy" @click="editApprovedAsset()">
                            <i class="fa fa-btn fa-edit"></i> Edit
                        </button>
                        <div v-if="(asset.status =='approved' && is_editing_approved_asset)">
                            <button type="submit" class="btn btn-primary me-2"
                                    :disabled="form.busy" @click="saveEditOnApprovedAsset()">
                                <i class="fa fa-btn fa-save"></i> Save
                            </button>
                            <button type="button" class="btn btn-secondary"
                                    :disabled="form.busy" @click="cancelEditOnApprovedAsset()">
                                <i class="fa fa-btn fa-close"></i> Cancel
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="modal example-modal fade" :id="'provide-example-modal-'+local_asset.id" aria-labelledby="provide-example-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="close btn" :data-bs-dismiss="'provide-example-modal-'+local_asset.id" @click="closeModal('provide-example-modal-'+local_asset.id)">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-0">Provide Examples to Generate Asset</h4>
                            <p>You can add up to 5 examples that will be used to inform the type of tone, grammatical style, and marketing tactics you are wanting.</p>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <div class="row d-flex align-items-center justify-content-center mb-2">
                            <div class="col-2 col-sm-1" v-if="provided_examples_index > 0">
                                <button type="button" class="btn btn-none" @click="moveExamplesLeft()">
                                    <i class="fa-solid fa-chevron-left"></i>
                                </button>
                            </div>

                            <div class="col">
                                <div class="mb-2">
                                    <label class="form-label">Example #{{ provided_examples_index+1 }}</label>
                                    <textarea class="form-control" name="example_content"
                                        v-model="provided_examples_array[provided_examples_index]"></textarea>
                                </div>
                            </div>
                            <div class="col-2 col-sm-1" v-if="provided_examples_index < 4">
                                <button type="button" class="btn btn-none" @click="moveExamplesRight()">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                        <div class="mb-4">
                            <button type="submit" class="btn btn-primary"
                                    :disabled="form.busy" @click="generatePrompt('provided_examples', provided_examples_array)" >
                                <i class="fa fa-btn fa-edit"></i> <span v-if="local_asset.source == 'generated'">Regenerate</span><span v-else>Generate</span> Asset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal example-modal fade" :id="'refine-prompt-modal-'+local_asset.id" aria-labelledby="refine-prompt-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="close btn" :data-bs-dismiss="'refine-prompt-modal-'+local_asset.id" @click="closeModal('refine-prompt-modal-'+local_asset.id)">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 v-if="field_selected_regenerate==null" class="mt-0">Provide Additional Direction to Avid AI</h4>
                            <h4 v-if="field_selected_regenerate!=null" class="mt-0">Regenerate {{ field_selected_regenerate.name }}</h4>
                            <p>Use the field below to describe how you want the generated content to be modified. The AI will then regenerate the content based upon your directions.</p>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">

                        <textarea class="form-control mb-3" name="example_content"
                            v-model="provided_examples_array[0]"></textarea>

                        <div class="mb-4">
                            <button type="submit" v-if="is_variant == false" class="btn btn-primary" @click="generatePrompt('refine_prompt', provided_examples_array[0])"
                                :data-bs-dismiss="'refine-prompt-modal-'+local_asset.id">
                                <i class="fa fa-btn fa-edit"></i> {{(field_selected_regenerate==null? 'Regenerate Asset' : 'Regenerate '+ field_selected_regenerate.name)}}
                            </button>
                            <button type="submit" v-else class="btn btn-primary" @click="generateVariantPrompt('refine_prompt', provided_examples_array[0])"
                                :data-bs-dismiss="'refine-prompt-modal-'+local_asset.id">
                                <i class="fa fa-btn fa-edit"></i> Generate New Asset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import { VueEditor } from "vue2-editor";
    import Swal from 'sweetalert2';
    import moment from 'moment';

    export default {
        components: {vueDropzone: vue2Dropzone, VueEditor, Swal},
        props: {
            asset: {},
            client: {},
            concepts: {
                type: Array,
                default: []
            },
            pages: {
                type: Array,
                default: []
            }
        },
        data() {
            return {    
                local_asset: {},
                local_inputs:[],
                is_client_edit_toggled: false,
                is_changes_made:false,
                is_variant:false,
                is_editing_approved_asset: false,
                is_donor_personas_expanded: false, // used to control the option in generate prompt dropdown
                is_concept_expanded: false, // used to control the option in generate prompt dropdown
                is_landing_pages_expanded: false,
                if_show_button_input: false,
                errors: {},
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                provided_examples_array: [],
                provided_examples_index: 0,
                field_selected_regenerate: null,
                imageDropzoneOptions: {
                    url: "#",
                    acceptedFiles: "image/*",
                    renameFile: function (file) {
                        let new_name = "client/" + Date.now() + "_" + file.name;
                        return new_name;
                    },
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> DROP IMAGE TO UPLOAD",
                    clickable: true,
                    createImageThumbnails: true,                    
                    thumbnailWidth: 280,
                    thumbnailHeight: 280
                },
                awss3: {
                      signingURL: '/api/client/get_signed_url', //Where you will get signed url
                      headers: {
                        'X-Requested-With':'XMLHttpRequest',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
                      },
                      params : {},
                      sendFileToServer : false //If you want to upload file to your server along with s3
                },
                fileDropzoneOptions: {
                    url: "#",
                    method: 'put',
                    timeout: 0,
                    parallelUploads: 2,
                    dictDefaultMessage: "Click or Drag/Drop a File Here",
                    headers: {
                        'charset': "utf-8",
                        "Cache-Control": "",
                    },
                    withCredentials: false,
                    accept: function(file, done) {

                        var url_parts = window.location.pathname.split('/');
                        var client_url = url_parts[2];
                        this.options.headers['Content-Type'] = file.type;
                        window.axios.post( "/api/file_upload/get_signed_url", { url: client_url, file_name: Date.now() + "_" +file.name }).then(function(data) {

                           //I can't access the variable in the "data" property so I'll just save it to the html
                          if($("#dropzone").data("file_urls") == undefined)
                            $("#dropzone").data("file_urls", []);

                            //Save the URL for when it is actually sent in a second
                            var arr = $("#dropzone").data("file_urls");
                            arr[file.upload.uuid] = data.data.url;

                            $("#dropzone").data("file_urls", arr);
                            done();
                        });

                    },
                    init: function() {
                        const dz = this;

                        dz.on("processing", (file) => {
                            dz.options.headers["Content-Type"] = file.type;
                        });
                    },
                    maxFilesize: null,
                },
                // customToolbar: [["bold", "italic", "underline"], ["link"],[{ list: "ordered" }, { list: "bullet" }], [{ indent: "-1" }, { indent: "+1" }],["clean"]],
                active_input_field: null
            };
        },
        beforeMount() {
            this.local_asset = {...this.asset};
            this.loadInputs();
            this.checkIfShowButtonInput(); 
            this.errors['asset_name']='';
        },
        mounted(){         
            if(this.local_asset.source == 'generating'){
                this.checkGenerationStatus();
            }
        },
        watch: {
            asset(new_value, old_value){
                this.local_asset = {...new_value};

                if(this.local_asset.source == 'generating'){
                    this.checkGenerationStatus();
                }
            }
        },
        updated() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {
            checkIfShowButtonInput(){
                if(this.asset.asset_type.name == "Donor Emails" || this.asset.asset_type.name == "Prospecting Emails"
                || this.asset.asset_type.name == "Housefile Emails"){
                    this.if_show_button_input = true;
                }
            },
            toggleClientEdits(){
                this.is_client_edit_toggled = !this.is_client_edit_toggled;
                if (this.is_client_edit_toggled == true){
                    this.local_asset = {...this.asset.latest_review};
                    this.local_asset.status = this.asset.status;
                } else {
                    this.local_asset = {...this.asset};
                }
                // Hide tooltip
                var switchButtonTooltip = document.querySelector('.tooltip')
                switchButtonTooltip.classList.remove("show");
            
                this.loadInputs();
            },
            getAssetDetailCardClass(){
                var card = document.querySelector("#asset-card-"+this.asset.id);
                if (card){
                    if(this.is_changes_made){
                        card.classList.add("unsaved-asset-warning");
                        return;
                    } else if (card.classList.contains("unsaved-asset-warning")){
                        card.classList.remove("unsaved-asset-warning")
                        return;
                    }                    
                }
            },
            wysiwygValueChange(newValueWithIndex){
                this.local_inputs.forEach((input, index) => {
                    if (input.type=='wysiwyg' && index == newValueWithIndex.index){
                        if (this.local_inputs[index].value != newValueWithIndex.value){
                            this.is_changes_made = true;
                        }
                        this.local_inputs[index].value = newValueWithIndex.value;
                    }
                });
                this.saveAsset();
            },
            loadInputs(){
                this.local_inputs = [];
                this.local_asset.asset_type.inputs.forEach(input => {
                    var val = null;
                    if(this.local_asset.inputs != null) {
                        let ph = this.local_asset.inputs.find(i => i.placeholder == input.placeholder);
                        if(ph != null)
                            val = ph.value;
                    }
                    var input_object = {
                        name: input.name,
                        description:input.description,
                        max_length:input.max_length,
                        placeholder:input.placeholder,
                        type:input.type,
                        optional:input.optional,
                        options:input.options,
                        value: val,
                        orig_value: val
                    }
                    if(input.type=='image'){ // construct image array
                        if (!Array.isArray(val)){
                            input_object.value = [val];
                            input_object.orig_value = [val];
                        }
                    }
                    
                    this.local_inputs.push(input_object);

                    if(input.max_length != null && val != null && input.max_length < val.length){
                        this.errors[input.placeholder] = 'The maximum number of characters is ' + input.max_length + ". When generating text, the AI has a hard time following character limit rules&mdash;you'll need to adjust."
                    }
                    else
                        this.errors[input.placeholder]='';

                });
            },
            addEmptyImageSlot(index){
                var tooltip = document.querySelector('.tooltip')
                if (tooltip){
                    tooltip.classList.remove('show'); // hide tooltip                    
                }
                this.local_inputs[index].value.push(null);
            },
            resizeTextarea(id) {
                if(id == undefined)
                    var element = document.querySelectorAll('textarea');
                else
                    var element = [document.getElementById(id)];

                for(var index = 0; index<element.length; index++){
                    if (element[index] && element[index].style){
                        element[index].style.height = '';
                        element[index].style.height = Math.max(element[index].offsetHeight, element[index].scrollHeight) + "px";
                    }                            
                }
            },
            hasAllInputs(){
                for(var i = 0; i < this.local_inputs.length; i++){
                    if((this.local_inputs[i].value == null || this.local_inputs[i].value == "") && (this.local_inputs[i].optional == 0) &&
                        (this.local_inputs[i].type == 'text' || this.local_inputs[i].type == 'textarea' || this.local_inputs[i].type=='wysiwyg'))
                        return false;
                }
                return true;
            },
            getDateAsString(value) {
                return moment.utc(String(value)).local().format('M/DD/YYYY h:mm a')
            },
            inputFieldValueChange(textarea_id){
                this.is_changes_made = true;

                if(textarea_id) // resize textarea when editing
                    this.resizeTextarea(textarea_id);
                    
                this.saveAsset(); // auto-save on field value change
            },
            copyApprovedAsset(assetToCopy){
                this.$emit("copyAsset", assetToCopy);
            },
            regenerateIndividualInput(field){
                this.field_selected_regenerate = field;
            },
            generateVariantPrompt(type, value){
                if(this.saveAsset())
                    this.$emit("generateVariantPrompt", this.local_asset, type, value);

                if(type == 'refine_prompt')
                    this.closeModal('refine-prompt-modal-' + this.local_asset.id);

                //Remove the "show" class
                this.$refs.variant_dropdown.classList.remove("show");
            },
            editApprovedAsset(){
                this.is_editing_approved_asset = true;
            },
            saveEditOnApprovedAsset(){
                this.saveAsset();
                //Only toggle back if they kept it as 'approved'
                if(this.local_asset.status == 'approved')
                    this.is_editing_approved_asset = false;
            },
            cancelEditOnApprovedAsset(){
                this.local_asset = {...this.asset};
                this.local_inputs = [];
                this.loadInputs();
                this.is_editing_approved_asset = false;
            },
            getErrorForMaxLength(input) {
                var classes = ''
                if (input.value != null && input.max_length != null && input.value.length > input.max_length){
                    this.errors[input.placeholder] = 'The maximum number of characters is ' + input.max_length + ". When generating text, the AI has a hard time following character limit rules&mdash;please adjust the text above."
                }
                else if(input.value != null && input.value != "")
                    this.errors[input.placeholder] = "";

                
                if (input.max_length != null)
                    classes += 'max-warning'

                if(this.errors[input.placeholder] != null && this.errors[input.placeholder] != '' )
                    classes +=' is-invalid';

                return classes;
            },
            getErrorForInputField(input){
                if(this.errors[input.placeholder] != null && this.errors[input.placeholder] != '' )
                    return 'is-invalid';
                else
                    return '';
            },
            clearErrors(){
                for (const error_name in this.errors) {
                    this.errors[error_name] = ""
                }
                this.form.error = false;
            },
            hasErrors(action){
                var is_non_draft_status = this.local_asset.status != null && this.local_asset.status != ""  && this.local_asset.status != "draft";

                //The name is required to save no matter the status

                if(this.local_asset.name == null || this.local_asset.name == ""){
                    this.errors.asset_name = "A name is required.";
                } else
                    this.errors.asset_name = "";


                if(is_non_draft_status || action=="approve"){ // only check for empty field when status is not draft

                    // if(this.local_asset.status == null || this.local_asset.status == ""){
                    //     this.errors.status = "Status is required.";
                    // } else
                    //     this.errors.status = "";

                    this.local_inputs.forEach(input => {
                        //Just handle the null exceptions
                        if(input.value == null)
                            input.value = '';

                        if (input.value == "" && input.optional == 0){
                            this.errors[input.placeholder] = input.name + " is required.";
                        } else if (input.max_length != null && input.value.length > input.max_length){
                            this.errors[input.placeholder] = "Maximum length exceeded. The max length for " + input.name + " is " + input.max_length;
                        } else {
                            this.errors[input.placeholder] = ""
                        }
                    })

                    for (const error_name in this.errors) {
                        if (this.errors[error_name] != "") {
                            return true;
                        }
                    }
                } else { // only do value length checking when status is draft
                    this.local_inputs.forEach(input => {
                        if (input.value &&input.max_length != null && input.value.length > input.max_length){
                            this.errors[input.placeholder] = "Maximum length exceeded. The max length for " + input.name + " is " + input.max_length;
                        } else {
                            this.errors[input.placeholder] = ""
                        }
                    })

                    for (const error_name in this.errors) {
                        if (this.errors[error_name] != "") {
                            return true;
                        }
                    }

                }
                if(this.errors.asset_name != "")
                    return true;

                return false;
            },
            saveAsset(){
                var self = this;
                this.is_changes_made = false;
                this.getAssetDetailCardClass();

                this.clearErrors();
                if(this.hasErrors() && this.local_asset.status != 'draft') { // if has error but in draft, still allow saving, regardless of the error message
                    this.form.error = true;
                    this.$forceUpdate();
                    return false;
                }
                this.form.busy = true;

                var asset_input = []
                this.local_inputs.forEach(input_field =>{
                    if(input_field.type == "wysiwyg" && input_field.value){
                        var formatted_value = input_field.value.replace('data-bs-toggle="modal" ', '')
                        .replace('data-bs-target="#insert-image-modal-'+this.local_asset.id +'\" ', '')
                        .replace('data-bs-target="#insert-button-modal-'+this.local_asset.id +'\" ', '');

                        asset_input.push({
                            placeholder: input_field.placeholder,
                            value: formatted_value
                        });
                    }  else if(input_field.type == "image" && Array.isArray(input_field.value) && input_field.value.length == 1 && input_field.value[0] == null){
                        // If image array is empty, empty image array would be an array has only one element and the only element is null
                        asset_input.push({
                            placeholder: input_field.placeholder,
                            value: null
                        });
                    } else {
                        asset_input.push({
                            placeholder: input_field.placeholder,
                            value: input_field.value
                        });                        
                    }
                });
                this.local_asset.inputs = asset_input;

                if(this.local_asset.id == -1) {
                    window.axios.post('/api/campaigns/'+this.client.url+'/assets', this.local_asset)
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {
                            this.local_asset = response.data.asset;
                            this.loadInputs();
                            this.$emit("assetSaved", this.local_asset);
                            
                            self.form.successful = true;
                            this.is_changes_made = false;
                            setTimeout( function() {self.form.successful = false;} , 5000);
                        }
                        else {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                    });
                }
                else {
                    if(this.is_client_edit_toggled == true){ 
                        // When toggle to see the copy client edited, saving the change will overwrite the original copy
                        this.local_asset.id = this.asset.id
                    }
                    window.axios.put('/api/campaigns/'+this.client.url+'/assets/'+this.local_asset.id, this.local_asset)
                      .then(response => {
                        self.form.busy = false;
                        if(response.status == 200) {   
                            this.local_asset = response.data.asset;
                            this.loadInputs();
                            this.$emit("assetSaved", response.data.asset);
                            this.displayAlertOnBottom('Asset Saved!', true);

                            self.form.successful = true;
                            setTimeout( function() {self.form.successful = false} , 5000);
                        }
                        else {
                            this.displayAlertOnBottom('Error on Saving Asset!', false);
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        }
                    });
                }
                return true;
            },
            displayAlertOnBottom(text, ifSuccess){
                Swal.fire({
                  html: '<h5 class="mb-0 mt-1">'+text+'</h5>',
                  target: '#public-links',
                  icon: ifSuccess? 'success' : 'warning',
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 3000,
                  iconColor: 'white',
                  customClass: {
                    popup: 'bg-primary'
                  },
                });
            },
            toggleDonorPersonaOptions(){
                this.is_donor_personas_expanded = !this.is_donor_personas_expanded;
            },
            toggleLandingOptions() {
                this.is_landing_pages_expanded = !this.is_landing_pages_expanded;
            },
            toggleBehavioralConceptOptions(){
                this.is_concept_expanded = !this.is_concept_expanded;
            },
            moveExamplesRight() {
                if(this.provided_examples_array.length-1 == this.provided_examples_index && this.provided_examples_array.length < 5) {
                    this.provided_examples_array.push("");
                }
                this.provided_examples_index++;
            },
            moveExamplesLeft() {
                if(this.provided_examples_index > 0)
                    this.provided_examples_index--;
            },
            generatePrompt(option, content){
                if(this.field_selected_regenerate){
                    this.generateIndividualPrompt(content);
                    return;
                }
                var request_body = {
                    option_selected: option,
                    content: content,
                    description: this.local_asset.description
                };
                this.local_asset.generating = true;
                this.$emit("assetGenerating", this.local_asset);

                window.axios.put('/api/campaigns/'+this.client.url+'/generate/'+this.local_asset.id, request_body)
                    .then(response => {
                    this.checkGenerationStatus();
                });

                this.provided_examples_array = [];
                this.provided_examples_index = 0;

                if(option == 'refine_prompt')
                    this.closeModal('refine-prompt-modal-' + this.local_asset.id);
                else if(option == 'provided_examples')
                    this.closeModal('provide-example-modal-' + this.local_asset.id);
            },
            generateIndividualPrompt(content){
                var request_body = {
                    content: content,
                    description: this.local_asset.description
                };

                this.local_asset.generating = true;
                this.$emit("assetGenerating", this.local_asset);

                window.axios.put('/api/campaigns/'+this.client.url+'/generate/'+this.local_asset.id+'/'+this.field_selected_regenerate.placeholder, request_body)
                    .then(response => {
                    this.checkGenerationStatus();
                });

                this.closeModal('refine-prompt-modal-' + this.local_asset.id);
            },
            checkGenerationStatus() {
                if(this.local_asset.id > 0)
                    window.axios.get('/api/campaigns/'+this.client.url+'/generate_status/'+this.local_asset.id)
                        .then(response => {
                            if(response.data.status == 'done') {
                                this.local_asset = response.data.asset;
                                this.loadInputs();
                                this.local_asset.generating = false;
                                this.$emit("assetGenerating", this.local_asset);
                            }
                            else {
                                setTimeout(this.checkGenerationStatus, 2000);
                            }
                    });
            },
            closeModal(id) {
                var myModalEl = document.getElementById(id)
                var modal = bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                this.provided_examples_array = [];
                this.provided_examples_index = 0; 
                this.field_selected_regenerate = null;
            },
            imageUploadStarted(input_field, image_index) {
                this.active_input_field = {
                    image_field: input_field,
                    image_index: image_index
                };
            },
            imageUpload(s3ObjectLocation){
                s3ObjectLocation = s3ObjectLocation.replace("%2F", "/");
                this.active_input_field.image_field.value[this.active_input_field.image_index] = s3ObjectLocation;
                this.$forceUpdate();
                this.active_input_field = null;
                this.inputFieldValueChange();
            },
            deleteImage(input_field_index, image_index){
                var self = this;
                Swal.fire({
                  title: "Are you sure you want to delete this image?",
                  text: "This image will be removed from this asset." ,
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.local_inputs[input_field_index].value.splice(image_index,1)
                        if (this.local_inputs[input_field_index].value.length == 0){
                            // when deleting the only image in the array, reset the array to have one empty image slot
                            this.local_inputs[input_field_index].value = [null]
                        }
                        this.inputFieldValueChange();
                    }
                });
            },
            onProcessing(file) {
                //Set the upload URL for this specific file
                var file_urls = $("#dropzone").data('file_urls');
                document.getElementById("dropzone").dropzone.options.url = file_urls[file.upload.uuid];
            },
            onSuccess( file, res ) {
                var data = new FormData();
                data.append("file_name", file.name);
                data.append("gcs_path", file.xhr.responseURL);
                data.append("client_url", this.client.url);

                //Remove it from the dropzone UI
                var fadeTarget = document.getElementsByClassName("dz-success")[0];
                var fadeEffect = setInterval(function () {
                    if (!fadeTarget.style.opacity) {
                        fadeTarget.style.opacity = 1;
                    }
                    if (fadeTarget.style.opacity > 0) {
                        fadeTarget.style.opacity -= 0.1;
                    } else {
                        clearInterval(fadeEffect);
                        document.getElementById("dropzone").dropzone.removeFile(file);
                    }
                }, 50);

                this.local_files.unshift({
                    id: Math.floor(Math.random() * -100000),
                    source: 'Upload',
                    status: "Processing",
                    name: file.name,
                    created_at: new Date().getTime()
                });
                this.local_conn.num_files++;

                var self = this;
                var id_to_remove = this.local_files[0].id;
                window.axios.post('/api/file_upload/new_file', data).then(function(data) {
                    //Insert the file returned in data.file to the top of the local_files variable
                    for(var i = 0 ; i < self.local_files.length; i++)
                        if(self.local_files[i].id == id_to_remove) {
                            self.local_files[i] = data.data.file;
                            break;
                        }

                    self.$forceUpdate();
                    this.inputFieldValueChange();
                });
            },
            onSending( file, xhr, formData ) {
                var _send = xhr.send;
                xhr.send = function() {
                  _send.call(xhr, file);
                };
            },
        }
    }
</script>
