<template>
    <div id="pathways-manage-segments">
        <div class="row">
            <div class="col-10">
                <h5>Your Segments</h5>
                <p>The various sources of data you've connected to Avid AI all can be segmented for marketing or analysis purposes. If there is a segment not yet defined, you can build it by clicking on the Add Segment button.</p>
            </div>
            <div class="col-2">
                <a class="btn btn-secondary float-end"  :href="'/segment/' + client.url +'/new'">
                    <img class="icon" src="/img/icons/dialexa-icons/plus.svg">Add Segment
                </a>
            </div>
        </div>

        <div class="card my-3">
            <div class="card-header">
                <div class="d-flex flex-row justify-content-between align-items-center gap-3">
                    <div class="d-flex justify-content-start">
                        <h5>Segments ({{filtered_segment_list.length}})</h5>
                    </div>
                    <div class="d-flex justify-content-end justify-self-end gap-2">
                        <div class="position-relative">
                            <input type="text" class="search-box form-control me-1" placeholder="Search by Segment Name..." aria-label="Search by Segment Name..." v-model="search_key" @input="handleFilterSelection()">
                            <i class="fa fa-search search-placeholder-icon" v-if="search_key == ''"></i>
                        </div>
                        <button class="btn btn-secondary me-1 dropdown-toggle" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                            <img class="icon pe-1" src="/img/icons/dialexa-icons/sort.svg">Filter
                        </button>
                        <div class="dropdown-menu ps-3">
                            <p>Source:</p>
                            <label class="dropdown-item" v-for="item in source_list" :key="'source-'+item">
                                <input class="form-check-input" type="checkbox" :value="item"  @change="handleFilterSelection()" v-model="filters.source" :true-value="[]">
                                <span class="ms-2" >
                                {{ item }}
                                </span>
                            </label>
                            <hr>
                            <p>Pushed Pathway:</p>
                            <label class="dropdown-item" v-for="item in dest_list" :key="'dest-'+item">
                                <input class="form-check-input" type="checkbox" :value="item"  @change="handleFilterSelection()"  v-model="filters.destination" :true-value="[]">
                                <span class="ms-2" >
                                {{ item }}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body avid-table pathways-manage-segment-table p-0">
                <table class="table table-responsive m-0 ">
                    <thead>
                        <tr class="table-header-row">
                            <th v-on:click="sortByKey('name')" class="table-column-1">
                                <div class="d-flex flex-row">
                                    Segment
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='name' && sort_order=='asc')? 'sort-key-active' :''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='name' && sort_order=='des')? 'sort-key-active' :''">                                                            
                                    </div>
                                </div>
                            </th>
                            <th v-on:click="sortByKey('sizes')" class="table-column-2">
                                <div class="d-flex flex-row">
                                    Contacts
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='sizes' && sort_order=='asc')? 'sort-key-active' :''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='sizes' && sort_order=='des')? 'sort-key-active' :''">
                                    </div>
                                </div>
                            </th>
                            <th v-on:click="sortByKey('data_source')" class="table-column-3">
                                <div class="d-flex flex-row">
                                    Data Source
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='data_source' && sort_order=='asc')? 'sort-key-active' :''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='data_source' && sort_order=='des')? 'sort-key-active' :''">                                                          
                                    </div>
                                </div>
                            </th>
                            <th v-on:click="sortByKey('pushed_pathways')" class="table-column-4">
                                <div class="d-flex flex-row">
                                    Pushed Pathways
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='pushed_pathways' && sort_order=='asc')? 'sort-key-active' :''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='pushed_pathways' && sort_order=='des')? 'sort-key-active' :''">                                                            
                                    </div>
                                </div>
                            </th>
                            <th class="table-column-5" style="width:182px;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(segment, index) in segment_list_shown" :class="`table-row-${index+1}`">
                            <td class="align-start table-column-1">
                                {{ segment.name }}
                            </td>
                            <td class="table-column-2 text-nowrap">
                                {{ getDefaultSize(segment.sizes) | number_with_zero }} <i class="fas fa-info-circle text-primary ms-2" data-bs-toggle="tooltip" title="The actual number of records synced to the destination will vary based what that system uses to determine unique records."></i>
                            </td>
                            <td class="align-start table-column-3">
                                {{  segment.data_source?segment.data_source.name : '' }}
                            </td>
                            <td class="align-start table-column-4">
                                <div v-for="pathway in segment.pushed_pathways" class="badge badge-secondary me-2 my-1" v-if="pathway.indexOf('File') === -1">
                                    {{pathway}}
                                </div>
                            </td>

                            <td class="align-start table-column-5 show-on-row-hover">
                                <button class="btn btn-none me-1" :disabled="export_busy[segment.id] != null && export_busy[segment.id] == true" v-if="export_connection_id != null"
                                    @click.prevent="exportSegment(segment)">
                                        <img class="icon" src="/img/icons/dialexa-icons/file-download.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Export Segment"
                                        v-if="export_busy[segment.id] == null || export_busy[segment.id] == false ">
                                        <div v-else class="spinner-border spinner-border-sm ms-2" role="status"><span class="visually-hidden">Loading...</span></div>
                                </button>
                                <a class="btn btn-none px-1"  :href="'/segment/' + client.url +'/clone/' + segment.id">
                                    <img class="icon" src="/img/icons/dialexa-icons/document-copy.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Copy Segment" >
                                </a>
                                <a class="btn btn-none px-1"  :href="'/segment/' + client.url +'/' + segment.id">
                                    <img class="icon" src="/img/icons/dialexa-icons/pencil.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit Segment" >
                                </a>
                                <button class="btn btn-none px-1" @click.prevent="deleteSegment(segment)">
                                    <img class="icon" src="/img/icons/dialexa-icons/trash.svg" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                            title="Delete Segment" > 
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="table-border-bottom-0">
                        <tr>
                            <td colspan="100%" class="py-0">
                                <div class="table-pagination">

                                    <div class="table-pagination--group">
                                        <span>Showing: </span>
                                        <select class="form-select" name="num_per_page" id="num_per_page" v-model="num_per_page" @change="reloadPagination()">
                                            <option value="5000" >ALL</option>
                                            <option v-for="num in num_per_page_options" :value="num">{{ num }}</option>
                                        </select>
                                        <span>Segments</span>
                                    </div>

                                    <div class="table-pagination--group">
                                        {{ item_interval }} of {{ filtered_segment_list.length }}
                                    </div>

                                    <div class="table-pagination--group">
                                        <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToPrevPage()" :disabled="page_index == 0 ">
                                            <img class="icon" src="/img/icons/dialexa-icons/chevron-left.svg">
                                        </button>   

                                        <div v-if="page_index_array.length<=3">
                                            <button  v-for="page in page_index_array" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                            {{page}}
                                            </button>  
                                        </div>
                                        <div v-else>
                                            <button v-for="page in page_index_array.slice(0,2)" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                            {{page}}
                                            </button>  

                                            <button v-if="page_index > 2" class="btn btn-none px-1" disabled>
                                            ...
                                            </button>  
                                            <button v-if="page_index >= 2 && page_index< page_index_array.length -1" class="btn btn-none px-1 current-page">
                                                {{page_index + 1 }} 
                                            </button>  

                                            <button v-if="page_index < page_index_array.length -2" class="btn btn-none px-1" disabled>
                                            ...
                                            </button>  
                                            <button  class="btn btn-none px-1" :class="page_index ==page_index_array.length -1 ? 'current-page':''" @click="moveToPage(page_index_array.length)">
                                            {{ page_index_array.length }}
                                            </button>  
                                        </div>

                                        <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToNextPage()" :disabled="page_index == page_count -1 ">
                                            <img class="icon" src="/img/icons/dialexa-icons/chevron-right.svg">
                                        </button>   
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <!-- <segment-modal :client="client" :segment="segment" :datasets="datasets" :datablends="datablends"
        @addSegmentFromModal="addSegmentFromModal" @updateSegmentFromModal="updateSegmentToDataSet"></segment-modal> -->

    </div>
</template>

<script>
    export default {
        props: ['datasets', 'datablends', 'connection_datasets', 'client', 'segments', 'census_connections'],
        data() {
            return {
                filtered_segment_list: [],
                segment_list_shown:[],
                segment: {
                    name: '',
                    data_source_id: null,
                    filter: null
                },
                search_key: '',
                sort_key: null,
                sort_order: 'asc',
                filters: {},
                source_list: [],
                dest_list: [],
                // pagination
                num_per_page: 50, // default to 50
                num_per_page_options: [5,10,15,20,25,30,50,100],
                page_count: null,
                page_index: 0,
                page_index_array: [],
                item_interval: '',
                export_busy:[],
                export_connection_id: null
            };
        },
        beforeMount() {
            this.filtered_segment_list = [...this.segments];

            //Loop through census connections and find the one that is a file
            this.connection_datasets.forEach(conn => {
                if(this.isFile(conn)){
                    this.export_connection_id = conn.id;
                }
            });

            this.reloadPagination();
            this.setupFilter();
        },
        updated() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
        },
        methods: {
            isFile(conn) {
                return conn.integration_id == '71b0c3b0-8390-49ec-9984-1c04b7384d38';
            },
            exportSegment(segment) {
                //See if there is a census_connection set up
                for(var i = 0; i < this.census_connections.length; i++) {
                    if(this.census_connections[i].connection_id == this.export_connection_id && segment.source ==
                        this.census_connections[i].source && segment.data_source_id ==
                        this.census_connections[i].data_source_id) {

                        var form_data = {
                            segment_id: segment.id,
                            census_connection: this.census_connections[i]
                        }

                        this.export_busy[segment.id] = true;
                        this.$forceUpdate();

                        //Call the webhook to save the new sync
                        window.axios.post('/api/census/export', form_data)
                            .then(response => {
                                //Make a link and then "click" on it
                                var link = document.createElement("a");
                                link.href = response.data.url;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                this.export_busy[segment.id] = false;
                                this.$forceUpdate();
                            })
                            .catch(error => {
                                if(error.response.status == 400){
                                    //Fields weren't mapped, time to redirect....
                                    this.export_busy[segment.id] = false;
                                    document.location.href = '/pathways/' + this.client.url + '/' + segment.id + '/' + this.export_connection_id;
                                }

                            });
                        return;
                    }
                }

                //If they get this far, the fields weren't mapped. Send to the mapping page that will also export
                document.location.href = '/pathways/' + this.client.url + '/' + segment.id + '/' + this.export_connection_id;
            },
            getDefaultSize(sizes) {
                if(!sizes)
                    return null;
                for (var i = 0; i < sizes.length; i++){
                    if (sizes[i].id == 0)
                        return sizes[i].size;
                }
                return null
            },
            setupFilter(){
                var destinations = [];
                this.connection_datasets.forEach(conn =>{
                    destinations.push(conn.name)
                });

                var sources = [];
                this.datablends.forEach( db =>{
                    if(db.segments.length > 0){
                        sources.push(db.name)
                    }
                });
                this.datasets.forEach( ds =>{
                    if(ds.segments.length > 0){
                        sources.push(ds.name)
                    }
                });

                this.source_list= sources;
                this.dest_list= destinations;
                this.filters = {
                    source: this.source_list,
                    destination: this.dest_list
                }
            },

            handleFilterSelection(){
                var filtered_list = [];

                this.segments.forEach(seg => {
                    let data_source = seg.dataset ? seg.dataset : seg.datablend;
                    if(!data_source)
                        data_source = seg.data_source;

                    let in_search = this.search_key == '' || seg.name.toLowerCase().includes(this.search_key.toLowerCase());
                    if(!data_source && in_search){
                        filtered_list.push(seg);
                    } else if(this.filters.source.includes(data_source.name) && in_search){
                        if (!seg.pushed_pathways.length){
                            filtered_list.push(seg);
                        } else if (this.filters.destination.some(item => seg.pushed_pathways.includes(item))){
                            filtered_list.push(seg)
                        }
                    }
                });
            
                this.filtered_segment_list = filtered_list;
                this.reloadPagination();
                this.$forceUpdate();
            },
            addNewSegment() {
                if(this.datablends.length > 0){
                    this.segment = {
                        id: null,
                        source: 'data_blend',
                        name: '',
                        data_source_id: this.datablends[0].id,
                        filter: null,
                        expires_at: null
                    };
                }
                else {
                    this.segment = {
                        id: null,
                        source: 'data_set',
                        name: '',
                        data_source_id: this.datasets[0].id,
                        filter: null,
                        expires_at: null
                    };
                }
            },
            // addSegmentFromModal(seg){
            //     if (seg && seg.id){ // newly created segment, push it to the dataset table
            //         this.addSegmentToDataSet(seg)
            //     }
            // },
            addSegmentToDataSet(seg) {
                this.filtered_segment_list.push(seg);
                this.sortDataWithLabel();
                this.reloadPagination();
            },
            updateSegmentToDataSet(seg) {
                var index = this.filtered_segment_list.findIndex(segment => segment.id == seg.id);
                this.filtered_segment_list[index] = seg;
                // this.sortDataWithLabel();
                this.reloadPagination();
            },
            editSegment(seg) {
                this.segment = seg;
            },
            deleteSegment(seg) {
                var self = this;

                //Show a warning
                Swal.fire({
                  title: "Are you sure you want to delete this segment?",
                  text: "The segment will no longer be available for data analysis and any connections with 3rd party systems will also be removed.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/segment/'+self.client.url + '/' + seg.id)
                        .then(response => {
                            if (response.status == 200) {
                                var index = this.filtered_segment_list.findIndex(segment => segment.id == seg.id);
                                this.filtered_segment_list.splice(index, 1);
                                // this.sortDataWithLabel();
                                this.reloadPagination();

                                Swal.fire({
                                    title: "Deleted!",
                                    text: "This segment has been removed.",
                                    icon: "success",
                                    iconColor:  "#25473E",
                                    confirmButtonColor: "#25473E"
                                });
                            }
                        });
                  }
                });
            },
            reloadPagination(){
                this.page_count = Math.ceil(this.filtered_segment_list.length / this.num_per_page);
                // this.page_index = 0;
                this.page_index_array = Array.from({length: this.page_count}, (_, i) => i + 1);
                this.getItemInterval();
            }, 
            getItemInterval(){
                var from = this.page_index * this.num_per_page + 1;
                var to = (this.page_index +1) * this.num_per_page;
                if(to>this.filtered_segment_list.length)
                    to = this.filtered_segment_list.length;
                if (from >= to) 
                    this.item_interval = to
                else 
                    this.item_interval = from +' - ' +to;
                
                this.segment_list_shown = this.filtered_segment_list.slice(from-1, to);
            },
            moveToPrevPage(){
                this.page_index--;
                this.getItemInterval();
            },
            moveToPage(page){
                this.page_index = page-1;
                this.getItemInterval();
            },
            moveToNextPage(){
                this.page_index++;
                this.getItemInterval();
            },
            sortByKey(key){
                this.sort_key = key;
                if (this.sort_key != key){
                    this.sort_order = 'asc'; // reset to ascending sorting order
                } else{
                    this.sort_order = (this.sort_order == 'asc'? 'des':'asc');
                }
                this.sortDataWithLabel();
            },
            sortDataWithLabel(){
                if (this.sort_key == 'data_source'){
                    if(this.sort_order == 'asc')
                        this.filtered_segment_list.sort((a, b) => {
                            if (!a.data_source)
                                return -1;
                            if (!b.data_source)
                                return 1;
                            return (a.data_source.name > b.data_source.name) ? 1 : -1;
                        });
                    else
                        this.filtered_segment_list.sort((a, b) => {
                            if (!a.data_source)
                                return 1;
                            if (!b.data_source)
                                return -1;
                            return (a.data_source.name < b.data_source.name) ? 1 : -1;
                        });   
                }
                else if(this.sort_key == 'sizes'){
                    if(this.sort_order == 'asc')
                        this.filtered_segment_list.sort((a, b) => {
                            // Find element with id = 0 in 'a'
                            const aFound = (a[this.sort_key] == null) ? false : a[this.sort_key].find(item => item.id === 0);
                            const aValue = aFound ? aFound.size : 0;

                            // Find element with id = 0 in 'b'
                            const bFound = (b[this.sort_key] == null) ? false : b[this.sort_key].find(item => item.id === 0);
                            const bValue = bFound ? bFound.size : 0;

                            // Compare the size values
                            return aValue - bValue;
                        });
                    else
                        this.filtered_segment_list.sort((a, b) => {
                            // Find element with id = 0 in 'a'
                            const aFound = (a[this.sort_key] == null) ? false : a[this.sort_key].find(item => item.id === 0);
                            const aValue = aFound ? aFound.size : 0;

                            // Find element with id = 0 in 'b'
                            const bFound = (b[this.sort_key] == null) ? false : b[this.sort_key].find(item => item.id === 0);
                            const bValue = bFound ? bFound.size : 0;

                            // Compare the size values
                            return bValue - aValue;
                        });
                }else{
                    if(this.sort_order == 'asc')
                        this.filtered_segment_list.sort((a, b) => (a[this.sort_key ] > b[this.sort_key ]) ? 1 : -1);
                    else
                        this.filtered_segment_list.sort((a, b) => (a[this.sort_key ] < b[this.sort_key ]) ? 1 : -1);                    
                }
                this.reloadPagination();
            },
        }
    }
</script>
