<template>
    <div class="datasets" >
        <h1 class="mb-3">{{datablend.type | propercase }} Data Blend Aggregations</h1>
        <p>Once the various disparate data sources have been assembled into a single record, there is often the need to perform additional custom calculations. Using the fields below, you can create unique aggregations that will be appended to this data blend at the end of the data processing process.</p>
        <div class="card box-content mb-3">
            <div class="card-body">
                <h4>Aggreggations</h4>
                <p><em><strong class="text-danger">WARNING: </strong>This is a power-user level screen. We'll build a better UI once we know how we're going to use this and how the various queries end up looking.</em></p>
                <p><a data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                    aria-controls="collapseExample">View Examples</a></p>
                <div class="collapse border-start border-3 border-secondary ps-3" id="collapseExample">
                    <label class="form-label">A Boolean Value</label>
                    <p class="font-monospace mb-3">SELECT COUNTIF(t.is_online) > 0 FROM UNNEST(transactions) AS t WHERE NOT _is_dummy</p>
                    <label class="form-label">An Aggregated Value (SUM, COUNT, AVG)</label>
                    <p class="font-monospace mb-3">SELECT SUM(IF(t.subchannel = 'PTV App',t.amount,NULL)) FROM UNNEST(transactions) AS t WHERE NOT _is_dummy</p>
                    <label class="form-label">A Full Transaction Record</label>
                    <p class="font-monospace mb-3">SELECT AS STRUCT t.* FROM UNNEST(transactions) AS t WHERE t.channel = 'Email' ORDER BY t.date LIMIT 1</p>
                    <label class="form-label">A Cohort Segmentation</label>
                    <p class="font-monospace">SELECT CASE WHEN COUNTIF(t.channel = 'Email') &gt; 0 AND COUNTIF(t.channel &lt;&gt; 'Email') &gt; 0 THEN 'Email & Others Cohort' WHEN COUNTIF(t.channel = 'Email')&gt;0 THEN 'Email Only' ELSE 'Others Only' END FROM UNNEST(transactions) AS t</p>
                </div>

                <div v-for="(agg, index) in local_db.custom_aggregations">
                    <!-- Name -->
                    <div class="mb-3">
                        <label class="form-label">Aggregation #{{index+1}} Name</label>
                        <input type="text" class="form-control" name="agg_name" v-model="agg.name"
                            :class="((errors[index].name != '' )? 'is-invalid':'')">
                        <div class="text-danger invalid-feedback" v-if="errors[index].name != ''">
                            {{errors[index].name}}
                        </div>
                    </div>

                    <div :class='(index+1 == local_db.custom_aggregations.length ) ? "mb-3" : "mb-5"'>
                        <label class="form-label">SQL Code</label>
                        <textarea class="form-control font-monospace" name="agg_sql" v-model="agg.sql" @change="validateSQL(index)"
                            :class="{'is-invalid':errors[index].sql != '', 'is-valid' : (errors[index].sql == '' && agg.sql.trim() != '' && !is_validating[index]), 'is-validating' : is_validating[index]}"></textarea>
                        <div class="text-danger invalid-feedback" v-if="errors[index].sql != ''">
                            {{errors[index].sql}}
                        </div>
                    </div>
                </div>

                <button class="btn btn-sm btn-secondary mb-3" v-on:click="addAggregation()">
                    <span><i class="fas fa-fw fa-plus"></i>Add Aggregation</span>
                </button>
            </div>
        </div>
        <div class="alert alert-danger mb-3 box-content" v-if="form.error">
            There was an error in the fields above. Please make any necessary corrections and try again.
        </div>

        <button class="btn btn-primary mb-3 btn-big" v-on:click="update()" :disabled="form.busy">
            <span><i class="fas fa-save"></i>Save</span>
        </button>
    </div>
</template>

<script>

    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal
        },
        props: ['datablend', 'client'],
        data() {
            return {
                local_db: [],
                form: {
                    busy: false,
                    error: false
                },
                errors : [],
                is_validating: []
            };
        },
        beforeMount() {
            this.local_db = this.datablend;

            if(this.local_db.custom_aggregations == null){
                this.local_db.custom_aggregations = [{name: "", sql: ""}];
                this.errors.push({name: "", sql: ""});
                this.is_validating.push(false);
            }
            else {
                //Add blank error messages
                for(let i = 0; i<this.local_db.custom_aggregations.length; i++){
                    this.errors.push({name: "", sql: ""});
                    this.is_validating.push(false);
                }
            }
        },
        methods: {

            addAggregation() {
                if(this.local_db.custom_aggregations == null)
                    this.local_db.custom_aggregations = [];

                this.local_db.custom_aggregations.push({name: "", sql: ""});
                this.errors.push({name: "", sql: ""});
                this.is_validating.push(false);
                //Force refresh the screen
                this.$forceUpdate();
            },
            validateSQL( index ) {
                let data = {
                    'custom_aggregation': this.local_db.custom_aggregations[index]
                }
                this.errors[index].sql = '';
                this.is_validating[ index ] = true;

                //Force refresh the screen
                this.$forceUpdate();
                window.axios.post('/api/data_blend/'+this.client.url+'/' + this.local_db.id + '/validate', data)
                  .then(response => {
                    if(!response.data.valid) {
                        this.errors[index].sql = response.data.error;
                    }
                    this.is_validating[index] = false;
                    this.$forceUpdate();
                });

            },
            update() {
                var self = this;

                //Clear out any empty aggregations
                this.form.error = false;
                for(var i = this.local_db.custom_aggregations.length-1; i >= 0; i--) {
                    if(this.local_db.custom_aggregations[i].name == "" &&
                        this.local_db.custom_aggregations[i].sql == ""){
                        this.local_db.custom_aggregations.splice(i, 1);
                        this.errors.splice(i, 1);
                        this.is_validating.splice(i, 1);
                    }
                    else if(this.local_db.custom_aggregations[i].name == "" ) {
                        this.errors[i].name = 'Enter a unique name or delete the SQL value to remove the aggregation from the data blend.';
                        this.form.error = true;
                    }
                    else if(this.local_db.custom_aggregations[i].sql == "" ) {
                        this.errors[i].sql = 'Enter BigQuery SQL code above or delete the name to remove the aggregation from the data blend.';
                        this.form.error = true;
                    }
                    //If the SQL was previously invalidated
                    else if(this.errors[i].sql != ""){
                        this.form.error = true;
                    }
                }

                if(this.form.error) return;
                this.form.busy = true;

                window.axios.put('/api/data_blend/'+this.client.url+'/' + this.local_db.id, this.local_db)
                  .then(response => {

                    self.form.busy = false;
                    Swal.fire({
                        title: "Success!",
                        html: "The aggregations have been added to the data blend and the refresh process has been started. The new columns should be available in a few minutes for segmentation and analysis.",
                        icon: "success",
                        iconColor:  "#46705F",
                        confirmButtonColor: "#46705F"
                    }).then((result) => {
                        window.location.replace("/settings/v1/"+this.client.url+"/data_blends");
                    });

                });


            }
        }
    }
</script>
