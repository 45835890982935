<template>
    <div class="advanced-filter-dropdown-container">
        <div v-if='field_type=="date_range"'>
            <div class="mb-2">
                <label class="form-label">Start Date</label>
                <datepicker v-model="local_options_selected.start_date" input-class="form-control date" placeholder='MM/DD/YYYY' :bootstrap-styling="true" :use-utc="true" format="M/d/yyyy" @selected="emitEvent()"  :clear-button="true" />
            </div>
            <div>
                <label class="form-label">End Date</label>
                <datepicker v-model="local_options_selected.end_date" input-class="form-control date" placeholder='MM/DD/YYYY' :bootstrap-styling="true" :use-utc="true" format="M/d/yyyy" @selected="emitEvent()" :clear-button="true" />
            </div>
        </div>
        <div v-if='field_type=="month_range"'>
            <div class="mb-2">
                <label class="form-label">Start Month</label>
                <datepicker v-model="local_options_selected.start_date" input-class="form-control date" :minimumView="'month'" :maximumView="'month'" placeholder='MM/YYYY' :bootstrap-styling="true" :use-utc="true" format="M/yyyy" @selected="emitEvent()"  :clear-button="true" />
            </div>
            <div>
                <label class="form-label">End Month</label>
                <datepicker v-model="local_options_selected.end_date" input-class="form-control date" :minimumView="'month'" :maximumView="'month'" placeholder='MM/YYYY' :bootstrap-styling="true" :use-utc="true" format="M/yyyy" @selected="emitEvent()" :clear-button="true" />
            </div>
        </div>
        <div class="advanced-filter-dropdown-multi-select" v-else-if="field_type=='multi_select'" :hidden="if_field_hidden">
            <label class="form-label">{{ field_label }}</label>
            <div v-if="is_searchable">
                <button class="form-select advanced-filter-dropdown" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside" :disabled="local_if_field_disabled">
                    <span class="selected-value" v-if="local_if_field_disabled">Loading Options ...</span>
                    <span class="selected-value" v-else-if="!local_if_field_disabled && local_options_selected && local_options_selected.length > 0"
                        data-bs-toggle="tooltip" :title="field_label+ ': '+ local_options_selected.join(', ')"
                    >{{ local_options_selected.length }} {{local_options_selected.length > 1? 'options': 'option'}} selected</span>
                    <span class="selected-value" v-else>&nbsp;&nbsp;&nbsp;</span>
                </button>
                <div v-if="(options && options.length>0 ) || num_searches > 0 || search_key != ''" class="dropdown-menu"
                    aria-labelledby="optionDropdown">
                    <!-- search bar -->
                    <div class="input-group search-box" v-if="is_searchable">
                        <input type="text" class="form-control" placeholder="Search..." aria-label="Search..." v-model="search_key" @input="debouncedSearchOptions">

                        <i class="fa fa-search"></i>
                        <button class="btn btn-secondary" type="button" id="button-addon2" @click="clearSearchKey()">X</button>

                    </div>

                    <label v-if="num_searches > 0" class="dropdown-item mt-3">
                        <span class="ms-2" >
                            <div class="spinner-border spinner-border-sm text-primary float-left" role="status"> <span class="visually-hidden">Searching...</span></div> Searching...
                        </span>
                    </label>
                    <label v-else-if="local_options == null || local_options.length == 0 " class="dropdown-item mt-3">
                        <span class="ms-2" >
                            <em>No Results Returned</em>
                        </span>
                    </label>
                    <div v-else>

                        <label class="dropdown-item mt-2" :key="'select_deselect_all'">
                            <input class="form-check-input" type="checkbox" :checked="if_select_all" @change="toggleSelectAll()">
                            <span class="ms-2" >
                                {{getSelectAllFieldText()}}
                            </span>
                        </label>
                        <div class="dropdown-divider"></div>

                        <label class="dropdown-item d-block" v-for="item in local_options" :key="item" @mouseover="setHover(item, true)" @mouseleave="setHover(item, false)">
                            <input class="form-check-input" type="checkbox" :value="item" v-model="local_options_selected" :id="item" @change.prevent="changeMultiSelection(item)">
                            <span class="ms-2" >
                                {{ item }}
                            </span>
                            <button class="btn btn-sm btn-secondary float-end only-selection-btn py-0" v-show="show_only_button[item]" @click.prevent="toggleOnly(item)">ONLY</button>
                        </label>
                    </div>
                </div>
                <div v-else class="dropdown-menu" aria-labelledby="optionDropdown">
                    <span class="ms-2" >
                        ** All values are blank **
                    </span>
                </div>                
            </div>

            <div v-else>
                <div v-if="local_options == null">
                    <input type="text" class="form-control" disabled="disabled" value="No options exist given the current filters"></input>
                </div>
                <div class="row advanced-filter-dropdown-tile-container" v-else-if="local_options.length == 2 && local_options.includes('true') && local_options.includes('false')">
                    <div class="col-12 col-xl-6 pb-2 px-1" v-for="item in local_options" > 
                        <div class="card tile h-100" :class="local_options_selected.includes(item) ? 'selected-tile': ''" @click="changeMultiSelection(item)">
                            <div class="card-body tile-body">
                                <div class="tile-content">
                                    <input class="form-check-input me-2 my-0" type="checkbox" :value="item" :checked="local_options_selected.includes(item)" :id="item">
                                    <!-- <i class="fa-4x" :class="asset_type.icon_class"></i> -->
                                    <p v-if="item == 'true'" class="my-0 tile-title input-field-description"><i class="fa fa-check fa-xl green"></i>Yes</p>
                                    <p v-if="item == 'false'" class="my-0 tile-title input-field-description"><i class="fa fa-close fa-xl red" ></i>No</p>
                                </div>
                            </div>                           
                        </div>
                    </div>
                </div>
                <div class="advanced-filter-dropdown-tile-container" v-else-if="local_options.length == 0">
                    <input type="text" class="form-control" disabled="disabled" value="Loading options..."></input>
                </div>
                <div class="row advanced-filter-dropdown-tile-container" v-else>
                    <div class="col-12 col-xl-6 pb-2 px-1" v-for="item in local_options" > 
                        <div class="card tile h-100" :class="local_options_selected.includes(item) ? 'selected-tile': ''" @click="changeMultiSelection(item)">
                            <div class="card-body tile-body">
                                <div class="tile-content">
                                    <input class="form-check-input me-2 my-0" type="checkbox" :value="item" :checked="local_options_selected.includes(item)" :id="item">
                                    <!-- <i class="fa-4x" :class="asset_type.icon_class"></i> -->
                                    <p class="my-0 tile-title input-field-description">{{ item }}</p>
                                </div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="advanced-filter-dropdown-single-select" v-else-if="field_type=='single_select'" :hidden="if_field_hidden">
            <label class="form-label">{{ field_label }}</label>
            <div v-if="is_searchable">
                <button class="form-select advanced-filter-dropdown" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside" :disabled="local_if_field_disabled">
                    <span class="selected-value" v-if="local_if_field_disabled">Loading Options ...</span>
                    <span class="selected-value" v-else-if="!local_if_field_disabled && local_options_selected && local_options_selected.length > 0" data-bs-toggle="tooltip" :title="field_label+ ': '+ local_options_selected">{{ local_options_selected}}</span>
                    <span class="selected-value" v-else>&nbsp;&nbsp;&nbsp;</span>     
                </button>
                <div v-if="(options && options.length>0 ) || search_key != ''" class="dropdown-menu" aria-labelledby="optionDropdown">
                    <!-- search bar -->
                    <div class="input-group search-box" v-if="is_searchable">
                        <input type="text" class="form-control" placeholder="Search..." aria-label="Search..." v-model="search_key" @input="debouncedSearchOptions">
                        <button class="btn btn-secondary" type="button" id="button-addon2" @click="clearSearchKey()">X</button>
                    </div>
                    <div class="dropdown-divider" v-if="is_searchable"></div>

                    <label class='d-block' v-for="item in local_options" :key="item">
                        <a class="dropdown-item" href="#" v-on:click.prevent="select(item)">
                            <i class="fa fa-circle-check text-primary" v-if="item===options_selected"></i>
                            <i class="fa fa-circle-check text-primary hidden" v-else></i>
                            <span>
                                {{ item }}
                            </span>                
                        </a>
                    </label>
                </div>  
                <div v-else class="dropdown-menu" aria-labelledby="optionDropdown">
                    <span class="ms-2" >
                        ** All values are blank **
                    </span>
                </div>                 
            </div>

            <div v-else>
                <div class="row advanced-filter-dropdown-tile-container" >
                    <div class="col-12 col-xl-6 pb-2 ps-2 pe-0" v-for="item in local_options" > 
                        <div class="card tile h-100" :class="local_options_selected==item ? 'selected-tile': ''" @click="select(item)">
                            <div class="card-body text-center tile-body">
                                <p class="my-0 tile-title input-field-description">{{ item }}</p>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="field_type=='date'">
            <label class="form-label">{{ field_label }}</label>
            <!-- <datepicker v-model="field.default_value[0]" :use-utc="true" format="MMM d, yyyy" input-class="form-control" ></datepicker> -->
        </div>
    </div>
</template>

<script>
    import vSelect from "vue-select";
    import Datepicker from 'vuejs-datepicker';
    import debounce from 'lodash.debounce';

    export default {
        props: {
            field_label: '',
            field_type: '',
            if_field_disabled: false,
            options: [],
            options_selected: [],
            group_index: {
                default: null,
            },
            field_index: {
                default: null,
            },
            db_search: {
                default: false,
            },
            active_group_index: 0,        
        },
        components: {
            vSelect, Datepicker
        },
        data() {
            return {
                local_options: [],
                local_options_selected: this.field_type=='multi_select'? []: '',
                local_if_field_disabled: false,
                search_key: '',
                if_select_all: false,
                if_field_hidden: false,
                show_only_button: {},
                is_loading: false,
                is_searchable: false,
                debouncedSearchOptions: null,
                num_searches: 0

            };
        },
        created() {
            this.debouncedSearchOptions = debounce(this.searchOptions, 500);
        },
        mounted() {
            // this.local_options_original = this.options;       
            this.local_options = this.options;
            this.local_if_field_disabled = this.if_field_disabled;
            if(this.options != null && this.options.length > 5){
                this.is_searchable = true;
            }
            this.local_options_selected = this.options_selected ? this.options_selected : [];
            if((this.field_type == 'date_range' || this.field_type == 'month_range') && (this.options_selected == null || this.options_selected == '' || (this.options_selected.end_date == null && this.options_selected.start_date == null)))
                this.local_options_selected = {start_date: "", end_date: ""};
            if(this.options != null)
                this.options.forEach((option)=>{
                    this.show_only_button[option] = false
                })
        },
        updated(){
            if(this.local_options_selected){
                this.checkSelectAllStatus();          
            }
        },
        watch:{
            options(new_value, old_value){

                this.local_if_field_disabled = false;
                this.local_options = new_value;

                if(new_value && new_value.length == 0 && this.search_key == '')
                    this.if_field_hidden = true;

                if(new_value != null && new_value.length > 5){
                    this.is_searchable = true;
                }
          
                if (this.active_group_index == this.group_index && (old_value == null || old_value.length == 0) && new_value != null && new_value.length > 0){
                    this.reorderOptionList(); // when option list first loaded from get_samples api call
                } else if(this.num_searches > 0){ // when db_search results comes back
                    this.reorderOptionList();   
                }
                if(this.num_searches > 0)
                    this.num_searches--;
            },
            active_group_index(new_value, old_value){
                // reset search
                if(this.search_key != ''){
                    this.search_key = '';
                    this.searchOptions();
                }
                if (new_value == this.group_index){
                   this.reorderOptionList();  // Reorder options list on the current selected tab
                }
            },
            options_selected(new_value, old_value){
                this.local_options_selected = this.options_selected;   
            },
            if_field_disabled(new_value, old_value){
                this.local_if_field_disabled = this.if_field_disabled;
            }
        },
        methods: {
            reorderOptionList(){
                // console.log('reorder');
                // put selected options on top of the list

                if(this.field_type=='multi_select'){
                    //If it is an array
                    if(Array.isArray(this.local_options_selected) && this.local_options_selected.length > 0){
                    
                        let all_options = [...this.local_options];
                        let option_list_temp = []; // add selected options to the top

                        this.local_options_selected.forEach((selected_value) => {
                            var index = all_options.findIndex((val) => val == selected_value);
                            if(index >= 0){
                                all_options.splice(index, 1);// remove selected options from this.local_options
                                option_list_temp.push(selected_value);
                            }
                        })
                        // add remaining options list back  
                        this.local_options = option_list_temp.concat(all_options);          
                    }    
                }

                if(this.field_type=='single_select'){
                    if(this.local_options_selected){
                        let all_options = [...this.local_options];
                        let option_list_temp = []; 
                        option_list_temp.push(this.local_options_selected);// add selected options to the top

                        var index = all_options.findIndex((val) => val == this.local_options_selected);
                        if(index >= 0){
                            all_options.splice(index, 1);// remove selected options from this.local_options
                        }
              
                        // add remaining options list back  
                        this.local_options = option_list_temp.concat(all_options);   
                    }     
                }
            
            },
            changeMultiSelection(option){
                if(!this.is_searchable){
                    if (this.local_options_selected.includes(option)){
                        const index = this.local_options_selected.indexOf(option);
                        this.local_options_selected.splice(index, 1);
                    } else {
                       this.local_options_selected.push(option); 
                    }
                }
                
                this.emitEvent();
            },
            emitEvent(){
                var filter_details = {
                    group_index: this.group_index,
                    field_index: this.field_index,
                    filter_value: this.local_options_selected
                }
                this.$emit('updateFilter', filter_details);
            },
            removeSelectedOption(item){
                var index = this.local_options_selected.findIndex((element) => element == item);
                this.local_options_selected.splice(index, 1); 
            },
            select(item){
                this.local_options_selected=item;
                this.$forceUpdate();
                this.emitEvent();
            },
            setHover(item, flag){
                this.show_only_button[item] = flag
                this.$forceUpdate();
            },
            searchOptions(){
                if (!this.db_search){
                    if(this.search_key == '') {
                        this.local_options = this.options;
                    } else{
                        if(this.field_type=='single_select' || this.field_type=='multi_select'){
                            this.local_options = this.options.filter((option) => option.toLowerCase().includes(this.search_key.toLowerCase()));
                        }
                    } 
                    this.reorderOptionList();                   
                } else {
                    this.num_searches++;
                    // this.local_options = null;
                    this.$forceUpdate();
                    var search_details = {
                        group_index: this.group_index,
                        field_index: this.field_index,
                        search_key: this.search_key
                    }
                    this.$emit('searchInLongOptionList', search_details);
                }
            },
            clearSearchKey(){
                this.search_key = '';
                if(this.db_search){
                    this.searchOptions();
                }
                else
                    this.local_options = this.options;
            },
            checkSelectAllStatus(){
                // check if the selected options list contains all option available  
                // if true, select all should be checked
                // else, select all should be unchecked
                if(this.field_type=='multi_select')
                 this.if_select_all =this.local_options && this.local_options.every(o => this.local_options_selected.includes(o));
            },
            toggleSelectAll(){
                var self = this;
                
                this.if_select_all = !this.if_select_all;

                if(this.if_select_all){ // select all: add all visible options to selected list
                    this.local_options.forEach((option)=>{
                        if (!self.local_options_selected.includes(option))
                            this.local_options_selected.push(option);
                    });
                } else { // deselect all: remove all visible options from selected list
                    this.local_options.forEach((option)=>{
                        var index = self.local_options_selected.indexOf(option);
                        if (index !== -1) {
                            this.local_options_selected.splice(index, 1);
                        }
                    });
                }
                this.emitEvent();
            },
            getSelectAllFieldText(){
                var text = 'Select';
                if(this.search_key==''){
                    text += ' All            '
                } else{
                    text += ' Visible Options'
                }
                return text;
            }, 
            toggleOnly(option){
                this.local_options_selected = [];
                this.local_options_selected.push(option);
                // uncheck the checkbox for select All
                this.if_select_all = false;
                this.emitEvent();
            }
        }

    }
</script>
    
