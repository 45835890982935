<template>
    <div id="org_settings_users">
        <div class="row">
            <div class="col-10">
                <h4>Team Members</h4>
                <p>Manage your team by adding, modifying, or removing users.</p>
            </div>
            <div class="col-2">
                <a :href="addUserUrl()" class="btn btn-primary float-end">
                    <span>
                        <i class="fas fa-fw fa-plus"></i>
                        Add Team Member
                    </span>
                </a>       
            </div>
        </div>

        <div class="row my-3">
            <div class="col-12 position-relative">
                <input type="text" class="search-box form-control me-1" placeholder="Search by Name..." aria-label="Search by Name..." v-model="search_key" @input="filterUsers()" autocomplete="off">
                <i class="fa fa-search search-placeholder-icon" v-if="search_key === ''"></i>
            </div>
        </div>

        <div class="row">
            <div class="col-12 avid-table">
                <table class="table table-responsive border rounded table-with-pagination my-3">
                    <thead>
                        <tr class="table-header-row">
                            <th @click="sortByKey('name')" class="table-column-1">
                                <div class="d-flex flex-row">
                                    Name
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key === 'name' && sort_order === 'asc') ? 'sort-key-active' : ''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key === 'name' && sort_order === 'des') ? 'sort-key-active' : ''">
                                    </div>
                                </div>
                            </th>
                            <th @click="sortByKey('email')" class="table-column-3">
                                <div class="d-flex flex-row">
                                    Email
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key === 'email' && sort_order === 'asc') ? 'sort-key-active' : ''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key === 'email' && sort_order === 'des') ? 'sort-key-active' : ''">                                                          
                                    </div>
                                </div>
                            </th>
                            <th @click="sortByKey('user_type')" class="table-column-3">
                                <div class="d-flex flex-row">
                                    User Type
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key === 'user_type' && sort_order === 'asc') ? 'sort-key-active' : ''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key === 'user_type' && sort_order === 'des') ? 'sort-key-active' : ''">                                                          
                                    </div>
                                </div>
                            </th>
                            <th class="table-column-4 text-end">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(user, index) in users_shown" :key="user.id" :class="`table-row-${index + 1}`">
                            <td class="align-start table-column-1">
                                <img class="avatar rounded-circle me-3" :src="user.image" /> 
                                {{ user.name }}
                            </td>
                            <td class="align-start table-column-3">
                                {{ user.email }}
                            </td>
                            <td class="align-start table-column-3">
                                {{ user.user_type  | propercase}}
                            </td>

                            <td class="align-start table-column-4 show-on-row-hover text-end">
                                <a :href="editUserUrl(user.id)" class="btn btn-none px-1" title="Edit User">
                                    <img class="icon" src="/img/icons/dialexa-icons/pencil.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit Client">
                                </a>
                                <button class="btn btn-none px-1" @click="confirmDeleteUser(user)" title="Delete User">
                                    <img class="icon" src="/img/icons/dialexa-icons/trash.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete User">
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="table-border-bottom-0">
                        <tr>
                            <td colspan="100%" class="py-0">
                                <div class="table-pagination">
                                    <div class="table-pagination--group">
                                        <span>Showing: </span>
                                        <select class="form-select" v-model="num_per_page" @change="reloadPagination()">
                                            <option value="5000">ALL</option>
                                            <option v-for="num in num_per_page_options" :key="num" :value="num">{{ num }}</option>
                                        </select>
                                        <span>Users</span>
                                    </div>

                                    <div class="table-pagination--group">
                                        {{ item_interval }} of {{ filtered_users.length }}
                                    </div>

                                    <div class="table-pagination--group">
                                        <button v-if="page_count > 1" class="btn btn-none px-1" @click="moveToPrevPage()" :disabled="page_index === 0">
                                            <img class="icon" src="/img/icons/dialexa-icons/chevron-left.svg">
                                        </button>   

                                        <div v-if="page_index_array.length <= 3">
                                            <button v-for="page in page_index_array" :key="page" class="btn btn-none px-1" :class="page === (page_index + 1) ? 'current-page' : ''" @click="moveToPage(page)">
                                                {{ page }}
                                            </button>  
                                        </div>
                                        <div v-else>
                                            <button v-for="page in page_index_array.slice(0,2)" :key="page" class="btn btn-none px-1" :class="page === (page_index + 1) ? 'current-page' : ''" @click="moveToPage(page)">
                                                {{ page }}
                                            </button>  

                                            <button v-if="page_index > 2" class="btn btn-none px-1" disabled>
                                                ...
                                            </button>  
                                            <button v-if="page_index >= 2 && page_index < page_index_array.length -1" class="btn btn-none px-1 current-page">
                                                {{ page_index + 1 }} 
                                            </button>  

                                            <button v-if="page_index < page_index_array.length -2" class="btn btn-none px-1" disabled>
                                                ...
                                            </button>  
                                            <button :class="page_index === (page_index_array.length -1) ? 'current-page' : ''" class="btn btn-none px-1" @click="moveToPage(page_index_array.length)" :key="page_index_array.length">
                                                {{ page_index_array.length }}
                                            </button>  
                                        </div>

                                        <button v-if="page_count > 1" class="btn btn-none px-1" @click="moveToNextPage()" :disabled="page_index === page_count -1">
                                            <img class="icon" src="/img/icons/dialexa-icons/chevron-right.svg">
                                        </button>   
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <!-- Delete Confirmation Modal (Optional, using SweetAlert instead) -->
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    props: ['agency'], // 'user' prop for user type and permissions
    data() {
        return {
            filtered_users: [...this.agency.users],
            users_shown: [],
            search_key: '',
            sort_key: null,
            sort_order: 'asc',
            num_per_page: 20,
            num_per_page_options: [5,10,15,20,25,30,50,100],
            page_count: 0,
            page_index: 0,
            page_index_array: [],
            item_interval: '',
        };
    },
    mounted() {
        this.reloadPagination();
    },
    methods: {
        filterUsers() {
            if (this.search_key.trim() === "") {
                this.filtered_users = [...this.agency.users];
            } else {
                const key = this.search_key.toLowerCase();
                this.filtered_users = this.agency.users.filter(user => user.name.toLowerCase().includes(key));
            }
            this.reloadPagination();
        },
        sortByKey(key) {
            if (this.sort_key === key) {
                this.sort_order = (this.sort_order === 'asc') ? 'des' : 'asc';
            } else {
                this.sort_key = key;
                this.sort_order = 'asc';
            }
            this.sortData();
        },
        sortData() {
            if (this.sort_order === 'asc') {
                this.filtered_users.sort((a, b) => a[this.sort_key].localeCompare(b[this.sort_key]));
            } else {
                this.filtered_users.sort((a, b) => b[this.sort_key].localeCompare(a[this.sort_key]));
            }
            this.reloadPagination();
        },
        reloadPagination() {
            this.page_count = Math.ceil(this.filtered_users.length / this.num_per_page);
            this.page_index = 0;
            this.page_index_array = Array.from({length: this.page_count}, (_, i) => i + 1);
            this.updateItemInterval();
        },
        updateItemInterval() {
            const from = this.page_index * this.num_per_page + 1;
            let to = (this.page_index + 1) * this.num_per_page;
            if (to > this.filtered_users.length) to = this.filtered_users.length;
            this.item_interval = `${from} - ${to}`;
            this.users_shown = this.filtered_users.slice(from - 1, to);
        },
        moveToPrevPage() {
            if (this.page_index > 0) {
                this.page_index--;
                this.updateItemInterval();
            }
        },
        moveToPage(page) {
            this.page_index = page - 1;
            this.updateItemInterval();
        },
        moveToNextPage() {
            if (this.page_index < this.page_count -1) {
                this.page_index++;
                this.updateItemInterval();
            }
        },
        confirmDeleteUser(user) {
            Swal.fire({
                title: `Are you sure you want to remove ${user.name}?`,
                text: "They will lose access to all dashboards and any communications from Avid.",
                icon: "warning",
                iconColor: "#D11F1F",
                showCancelButton: true,
                confirmButtonColor: "#D11F1F",
                confirmButtonText: "Yes, confirm",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteUser(user);
                }
            });
        },
        deleteUser(user) {
            // Placeholder for API call to delete user
            // Replace with actual API endpoint and method
            axios.delete(`/api/agencies/${this.agency.id}/users/${user.id}`)
                .then(response => {
                    // Remove user from the local list
                    const index = this.agency.users.findIndex(u => u.id === user.id);
                    if (index !== -1) {
                        this.agency.users.splice(index, 1);
                        this.filterUsers();
                    }
                    Swal.fire({
                        html: `<h4 class="mb-0 text-white">${user.name} was removed!</h4>`,
                        icon: 'success',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                })
                .catch(error => {
                    console.error('Error deleting user:', error);
                    Swal.fire({
                        html: `<h4 class="mb-0 text-white">Error removing this user!</h4>`,
                        icon: 'warning',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: {
                            popup: 'bg-primary'
                        },
                    });
                });
        },
        addUserUrl() {
            // Placeholder URL for adding a new user
            return `/admin/agency/${this.agency.id}/user/add`;
        },
        editUserUrl(user_id) {
            // Placeholder URL for editing a user
            return `/admin/agency/${this.agency.id}/user/${user_id}`;
        }   
    }
}
</script>
