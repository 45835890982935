<template>
    <div class="chart-configuration">
        <div class="card box-content">
            <div class="card-body">
                <div class="row">
                    <div :class="{'col-4' : render_large_chart}" >
                        <div class="mb-3">
                            <label class="form-label">Chart Type</label>
                            <select class="form-select" id="chart-type-select" v-model="local_chart.type"
                                :class="{'is-invalid' : local_errors.chart_type!= ''}"
                                v-on:change="changeChartTypeSelection()">
                                <option value="" disabled selected>Select the chart type...</option>
                                <option value="scorecard">Scorecard</option>
                                <option value="pie">Pie Chart</option>
                                <option value="line">Line Chart</option>
                                <option value="cumulative_line">Cumulative Line Chart</option>
                                <option value="bar_line">Bar-Line Combo Chart</option>
                                <option value="column">Column Chart</option>
                                <option value="stacked_column">Stacked Column Chart</option>
                                <option value="100_percent_stacked_column">100% Stacked Column Chart</option>
                                <option value="horizontal_bar">Horizontal Bar Chart</option>
                                <option value="stacked_bar">Stacked Horizontal Bar Chart</option>
                                <option value="100_percent_stacked_bar">100% Stacked Horizontal Bar Chart</option>
                                <option value="table">Table</option>
                                <option value="textbox">Textbox</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="local_errors.chart_type != ''">
                                {{local_errors.chart_type}}
                            </div>
                        </div>
                        <div class="mb-3" v-if="local_chart.type!='textbox'">
                            <div class="row"><div class="col">
                                <label class="form-label">Dimensions</label>
                                <button type="button" id="add-new-dimension" class="btn btn-xs btn-secondary float-end"
                                    data-bs-toggle="modal" data-bs-target="#add-metric-filter-modal"
                                    @click="add_new_option='Dimension'">
                                        <i class="fas fa-plus" data-bs-toggle="tooltip"
                                        title="Creating a new Dimension option will add it to the Dimension option dropdown."></i> Add New
                                    </button>
                                </div>
                            </div>
                            <accordion-multi-select id="dimensions-multi-select" :options="dimension_options"  :show_hidden_fields="true"  :max_num_values="max_values.dimensions" @input="local_errors.group_by=''"
                            v-model="local_chart.group_by" :field_name="'dimensions'" 
                            :class="{'is-invalid' : local_errors.group_by!= ''}"></accordion-multi-select>
                            <div class="text-danger invalid-feedback" v-if="local_errors.group_by != ''">
                                {{local_errors.group_by}}
                            </div>
                        </div>
                    </div>

                    <div :class="{'col-4' : render_large_chart}" v-if="local_chart.type!='textbox'">
                        <div class="mb-3">
                            <div class="row ">
                                <div class="col">
                                    <label class="form-label">Metrics</label>
                                    <button type="button" id="add-new-metric" class="btn btn-xs btn-secondary float-end"
                                    data-bs-toggle="modal" data-bs-target="#add-metric-filter-modal"
                                    @click="add_new_option='Metric'">
                                        <i class="fas fa-plus" data-bs-toggle="tooltip"
                                        title="Creating a new Metric option will add it to the Metrics option dropdown."></i> Add New
                                    </button>
 
                                </div>
                            </div>
                            <accordion-multi-select id="metrics-multi-select" :options="metric_options" 
                                @input="local_errors.metrics=''" v-model="local_chart.metrics" :field_name="'metrics'"
                                :show_hidden_fields="true" :class="{'is-invalid' : local_errors.metrics!= ''}"
                                :max_num_values="max_metrics" ></accordion-multi-select>
                            <div class="text-danger invalid-feedback" v-if="local_errors.metrics != ''">
                                {{local_errors.metrics}}
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row ">
                                <div class="col">
                                    <label class="form-label">Filters (Optional)</label>
                                    <button type="button" id="add-new-filter" class="btn btn-xs btn-secondary float-end"
                                    data-bs-toggle="modal" data-bs-target="#add-metric-filter-modal"
                                    @click="add_new_option='Filter'">
                                        <span><i class="fas fa-plus" data-bs-toggle="tooltip"
                                        title="Creating a new Filter option will add it to the Filters option dropdown."></i></span> Add New
                                    </button>
                                </div>
                            </div>
                            <accordion-multi-select id="filters-multi-select" :options="filter_options"
                            v-model="local_chart.filter" :field_name="'filters'" :show_hidden_fields="true"></accordion-multi-select>
                        </div>

                    </div>

                    <div :class="{'col-4' : render_large_chart}" v-if="local_chart.type!='textbox'">
                        <div class="mb-3">
                            <label class="form-label" v-if="local_chart.type != 'scorecard'">Order By</label>
                            <label class="form-label" v-else>Order By (Top Value is Shown)</label>
                            <button type="button" class="btn btn-xs" @click="addOrderBy()" v-if="max_values.order_by > order_bys.length"><span><i class="fas fa-plus"></i></span></button>
                            <div class="input-group mb-3" v-for="(order, index) in order_bys">
                                <accordion-multi-select :options="order_by_options" :show_hidden_fields="true"  :field_name="'order_by_'+index" v-model="order.value" :max_num_values="1" ></accordion-multi-select >
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{order.direction}}</button>
                                  <ul class="dropdown-menu dropdown-menu-end">
                                    <li><a class="dropdown-item" href="#" v-on:click.prevent="order.direction='ASC'">Ascending</a></li>
                                    <li><a class="dropdown-item" href="#" v-on:click.prevent="order.direction='DESC'">Descending</a></li>
                                  </ul>
                            </div>

                        </div>
                        <div class="mb-3">
                            <label class="form-label">Max Results (<em>Optional</em>)</label>
                            <input type="number" class="form-control" v-model="local_chart.max_results" >
                        </div>
                        <div class="mb-3"  v-if="local_chart.type != 'scorecard' && local_chart.type != 'pie'">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="highlight-last-value" v-model="local_chart.highlight_last_value">
                                <label  class="form-check-label" for="highlight-last-value">Highlight Only the Last Value <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="When checked, only the last column will be highlighted to visually emphasize the most recent value."></i></label>
                            </div>
                        </div>
                        <div class="mb-3"  v-if="local_chart.type != 'scorecard' && local_chart.type != 'pie'">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="show-all-data-labels" v-model="local_chart.show_all_data_labels">
                                <label  class="form-check-label" for="show-all-data-labels">Show All Data Labels <i class="fas fa-info-circle"></i></label>
                            </div>
                        </div>
                        <div class="mb-3"  v-if="local_chart.type == 'column' || local_chart.type == 'horizontal_bar'">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="show-series-percentage" v-model="local_chart.show_series_percentage">
                                <label  class="form-check-label" for="show-series-percentage">Show as Percentage of Series Total <i class="fas fa-info-circle"></i></label>
                            </div>
                        </div>
                        <div class="mb-3"  v-if="local_chart.type != 'scorecard' && local_chart.type != 'pie'">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="include-projections" v-model="local_chart.include_projections">
                                <label  class="form-check-label" for="include-projections">Include Projections <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="When checked, Avid will project out future years' results based upon the trends of recent prior years."></i></label>
                            </div>
                        </div>
                    </div>    
                </div>
                <div>                         
                    <div class="mb-3" v-if="local_chart.type!='textbox'">
                        <label class="form-label">Preview Filters <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="These are filters only applied to the chart while using the Chart Builder. They will be applied via the Report Page once 'in the wild.'"></i></label>
                        <div>
                            <div class="badge badge-primary me-2 p-1 px-2 d-inline-flex align-items-center" :key="filter.pos"
                                v-for="(filter, index) in local_chart.temp_filters">
                                {{filter.text}}
                                <button class="btn btn-xs btn-link p-0 ps-1" title="Remove Filter"
                                    v-on:click.prevent="removeTempFilter(index)">
                                    <i class="fas fa-close"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-else>
                        <div class="col-8"> 
                            <div class="mb-1">
                                <label class="form-label">Icon or Image URL (Optional)</label>
                                <input type="text" class="form-control" v-model="textbox_details.icon_url" >
                            </div>
                            <div class="mb-1">
                                <label class="form-label">Icon Background Color (Optional)</label>
            
                                <select class="form-select"  v-model="textbox_details.icon_color_class">
                                    <option value="" disabled selected>Select the icon color...</option>
                                    <option value="icon-red" >Red</option>
                                    <option value="icon-orange">Orange</option>
                                    <option value="icon-yellow">Yellow</option>
                                    <option value="icon-green">Green</option>
                                    <option value="icon-blue">Blue</option>
                                    <option value="icon-purple">Purple</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Content</label>
                                <vue-editor v-model="textbox_details.html_content" :editorToolbar="customToolbar" @text-change="textboxContentChange()"></vue-editor>
                            </div>
                        </div>
                        <div class="col-4">
                            <p>Instruction of creating textbox:</p>
                            <ul>
                                <li>When includes an icon or image url, it will always appear on the left side (similar to scorecard)</li>
                                <li>3 types of heading allowed: Heading 1(24px), Heading 2(22px), Heading 3(20px), Normal(14px)</li>
                                <li>Image style: Max height 100%, max width 100%</li>
                                <li><a href="https://docs.google.com/spreadsheets/d/1AacDkFj4f4LgD5grQzW9OYh7aU2-QQj2hzdd3xU5rV4/edit?usp=sharing" target="_blank">See the icon library here</a></li>
                            </ul>
                        </div>

                    </div>
                    
                    <button id="preview-button" class="btn btn-secondary" @click="previewChart()" :disabled="false && form.busy">
                        <i class="fa fa-btn fa-eye"></i>Preview
                    </button>
                </div>
            </div>
        </div>
        <div class="modal fade" id="add-metric-filter-modal" data-bs-backdrop="static"
            aria-labelledby="add-metric-filter-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end close-modal"
                                data-bs-dismiss="add-metric-filter-modal" @click="closeModal()">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-2">{{add_new_option | propercase}} Builder</h4>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <metric-filter-builder :data_blend="local_chart.data_blend" :modal_id="'add-metric-filter-modal'"
                            ref="mf_builder" :type="add_new_option" @update="mfAdded"></metric-filter-builder>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import { VueEditor } from "vue2-editor";
    import Swal from 'sweetalert2';

    export default {
        components: {
            Swal, VueEditor
        },
        props: ['chart', 'render_large_chart', 'metrics_filters'],
        data() {
            return {
                local_chart: null,
                columns:[], // pulled from database
                local_metrics:[], // pulled from database
                local_filters:[], // pulled from database
                local_dimensions:[], // pulled from database
                dimension_options:[],
                metric_options:[],
                filter_options:[],
                order_by_options:[],
                order_bys: [],
                max_values: {
                    dimensions: 2,
                    metrics:2,
                    order_by: 2
                },
                local_errors: {
                    chart_type:'',
                    group_by:'',
                    metrics:''
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                add_new_option: '',
                textbox_details: {
                    icon_url: '',
                    icon_color_class: '',
                    html_content: ''
                },
                customToolbar: [["bold", "italic", "underline"],  [{ 'header': [1, 2, 3, false] }], [{ list: "ordered" }, { list: "bullet" }], ["image", "link", "code-block"]]
            };
        },
        beforeMount() {
            this.local_metrics_filter = this.metrics_filters;
            this.local_chart = this.chart;
            if (this.local_chart.type == 'textbox'){
                // this.local_chart.data = this.chart.html; // textbox content is stored in html field
                this.loadTextboxConfig();
            }
            this.local_db_type_selected = this.chart.data_blend_type;
            this.loadMetricFilterColumnOptions();
            this.changeChartTypeSelection();
            this.refreshColumns();

            //Always add the temporary filters
            if(this.chart.data_blend_type == 'constituent')
                this.local_chart.temp_filters = this.getConstituentFilters();
            else
                this.local_chart.temp_filters = [];
            this.local_chart.include_projections = false;

            //If the order by array is blank, set it to the default structure
            if(this.local_chart.order_by == null){
                this.addOrderBy();
            }
            else {
                //Load the order by array into the right structure for the UI
                this.order_bys = [];
                for(var i = 0; i < this.local_chart.order_by.length; i++)
                {
                    this.order_bys.push({value: [this.local_chart.order_by[i]], direction: this.local_chart.order_by[i].direction});
                }
                if(this.order_bys.length == 0)
                    this.addOrderBy();
            }
        },
        computed: {
            max_metrics () {
                if(this.local_chart.group_by != null && this.local_chart.group_by.length == 2
                    && this.local_chart.type == 'column'){
                    if(this.local_chart.metrics != null && this.local_chart.metrics.length > 1)
                        this.local_chart.metrics = [this.local_chart.metrics[0]];
                    return 1;
                }
                return this.max_values.metrics;
            },
        },
        watch:{
            'chart.data_blend': {
                handler(n, o) {
                    this.local_chart.data_blend = JSON.parse(JSON.stringify(this.chart.data_blend));
                    this.local_chart.data_blend_type = this.chart.data_blend_type;
                    if(this.local_chart.data_blend_type == 'constituent')
                        this.local_chart.temp_filters = this.getConstituentFilters();
                    else
                        this.local_chart.temp_filters = [];
                    this.loadMetricFilterColumnOptions();
                    this.refreshColumns();
                },
                deep: true
            }
        },
        methods: {
            getConstituentFilters() {
                return [
                    {
                        'pos': 0,
                        'main_category': 'Temp Filters',
                        'category': '',
                        'text': 'Last 5 Years',
                        'value': 'temp_filter_1',
                        'formula': 'transactions.time_period._periods_back <= 5'
                    },
                    {
                        'pos': 1,
                        'main_category': 'Temp Filters',
                        'category': '',
                        'text': 'Time Period: Closed Fiscal Year',
                        'value': 'temp_filter_2',
                        'formula': "transactions.time_period.year_type = 'Closed Fiscal Year'"
                    }
                ];
            },
            addOrderBy() {
                this.order_bys.push({value: null, direction: 'DESC'});
            },
            removeTempFilter(index) {
                this.local_chart.temp_filters.splice(index, 1);
                this.$forceUpdate();
            },
            textboxContentChange(){
                this.generateTextboxContent();
            },
            loadTextboxConfig(){
                if (this.local_chart.html){
                    var html_temp = decodeURIComponent(this.local_chart.html)

                    var elem = document.createElement('div');
                    elem.innerHTML = html_temp;
                    let parser = new DOMParser();
                    const doc = parser.parseFromString(html_temp, "text/html");

                    var icon_added = doc.getElementsByTagName("img")[0];
                    if (icon_added){
                        this.textbox_details.icon_url =  doc.getElementsByTagName("img")[0].outerHTML.slice(10, -2);
                        this.textbox_details.icon_color_class = doc.getElementsByTagName("img")[0].parentElement.classList[0];                    
                    }
                    var wysiwyg_content = doc.getElementsByClassName("flex-fill")[0];
                    if (wysiwyg_content){
                        this.textbox_details.html_content =  doc.getElementsByClassName("flex-fill")[0].innerHTML;
                    }
                }
            },
            refreshColumns(){
                var data = {
                  include_trans: true,
                  source: 'data_blend',
                  data_source_id: this.local_chart.data_blend.id
                };
                this.columns = [];

                window.axios.post('/api/bigquery/get_db_columns', data)
                  .then(response => {
                    var cols = response.data.columns;
                    var headers = "";
                    for(var i = 0; i < cols.length; i++){

                        cols[i].main_category = 'Columns';
                    }
                    this.columns = cols;
                    this.loadMultiSelectFieldsOptions();
                  });
            },
            loadMetricFilterColumnOptions(){
                var metrics = [];
                var filters = [];
                var dimensions = [];

                this.local_metrics_filter.forEach(metric_filter_obj =>{
                    //If it is a specific client and that client isn't selected
                    if(metric_filter_obj.data_blend_id != null && metric_filter_obj.data_blend_id != this.local_chart.data_blend_id)
                        return;

                    if (metric_filter_obj.type == 'metric' && metric_filter_obj.data_blend_type == this.local_chart.data_blend_type){
                        metric_filter_obj.main_category = 'Metrics';
                        metric_filter_obj.value = metric_filter_obj.id;
                        metrics.push(metric_filter_obj)
                    } else if (metric_filter_obj.type == 'filter' && metric_filter_obj.data_blend_type == this.local_chart.data_blend_type){
                        metric_filter_obj.main_category = 'Filters';
                        metric_filter_obj.value = metric_filter_obj.id;
                        filters.push(metric_filter_obj)
                    }
                    else if (metric_filter_obj.type == 'dimension' && metric_filter_obj.data_blend_type == this.local_chart.data_blend_type){
                        metric_filter_obj.main_category = 'Dimensions';
                        metric_filter_obj.value = metric_filter_obj.id;
                        dimensions.push(metric_filter_obj)
                    }
                }) 
                this.local_metrics = metrics;
                this.local_filters = filters;
                this.local_dimensions = dimensions;

                this.loadMultiSelectFieldsOptions()
            },
            loadMultiSelectFieldsOptions(){
                    this.dimension_options = this.loadOptionsByCategory(['columns','dimensions'])
                    this.metric_options = this.loadOptionsByCategory(['metrics'])     
                    this.filter_options = this.loadOptionsByCategory(['filters'])   
                    this.order_by_options = this.loadOptionsByCategory(['metrics','columns','dimensions'])
            },
            loadOptionsByCategory(categoryArray){
                var options = []
                
                //Loop through the array (this way the dropdown will be in the order passed into the array)
                for(let i = 0; i < categoryArray.length; i++){
                    if (categoryArray[i] == 'metrics'){
                        options.push(...this.local_metrics)
                    }
                    if (categoryArray[i] =='filters'){
                        options.push(...this.local_filters)
                    }
                    if (categoryArray[i] == 'columns'){
                        options.push(...this.columns)
                    }
                    if (categoryArray[i] == 'dimensions'){
                        options.push(...this.local_dimensions)
                    }
                }
                return options
            },
            changeChartTypeSelection(){
                this.clearErrorForField('chart_type');

                // Column
                if(this.local_chart.type == 'column'){
                    //If there are two dimensions, there can only be one metric but that is handled in a different spot

                    //Max two dimenions
                    this.max_values.dimensions=2;
                    //Max two metrics
                    this.max_values.metrics=2;
                    this.max_values.order_by=2;
                }
                // Horizontal Bar
                else if(this.local_chart.type == 'horizontal_bar'){
                    //Max two dimenions
                    this.max_values.dimensions=2;
                    //Max two metrics
                    this.max_values.metrics=2;
                    this.max_values.order_by=2;
                }
                // Stack column and bar
                else if(this.local_chart.type == 'stacked_column' || this.local_chart.type == '100_percent_stacked_column' || this.local_chart.type == 'stacked_bar'){
                    //Max two dimenions
                    this.max_values.dimensions=2;
                    //Max two metrics
                    this.max_values.metrics=2;
                    this.max_values.order_by=2;
                }
                // line chart
                else if(this.local_chart.type == 'line' || this.local_chart.type == 'cumulative_line'){
                    //Max two dimenions
                    this.max_values.dimensions=2;
                    //Max 5 metrics for now
                    this.max_values.metrics=5;
                    this.max_values.order_by=5;
                }
                // bar-line combo chart
                else if(this.local_chart.type == 'bar_line'){
                    //Only one dimension
                    if(this.local_chart.group_by != null && this.local_chart.group_by.length > 1)
                        this.local_chart.group_by = [this.local_chart.group_by[0]];
                    this.max_values.dimensions=1;
                    //Max 5 metrics for now
                    this.max_values.metrics=5;
                    this.max_values.order_by=5;
                }
                // Pie Chart and scorecard
                else if(this.local_chart.type == 'pie' || this.local_chart.type == 'scorecard'){
                    //Only one dimension
                    if(this.local_chart.group_by != null && this.local_chart.group_by.length > 1)
                        this.local_chart.group_by = [this.local_chart.group_by[0]];
                    this.max_values.dimensions=1;

                    //Only one metric
                    if(this.local_chart.metrics != null && this.local_chart.metrics.length > 1)
                        this.local_chart.metrics = [this.local_chart.metrics[0]];
                    this.max_values.metrics=1;

                    //Only one ordering
                    if(this.order_bys != null && this.order_bys.length > 1)
                        this.order_bys = [this.order_bys[0]];
                    this.max_values.order_by=1;

                // Table
                } else if(this.local_chart.type == 'table'){
                    // allow at most 6 dimensions, "unlimited" metrics and order_by
                    // Will have another table template allows multiple dimensions
                    if(this.local_chart.group_by != null && this.local_chart.group_by.length > 6)
                        this.local_chart.group_by = this.local_chart.group_by.slice(0, 6);
                    this.max_values.dimensions=6;
                    this.max_values.metrics=10;
                    this.max_values.order_by=10;
                
                } 
                this.$forceUpdate();
                this.$emit('updateChartConfig', this.local_chart);
            },
            mfAdded(mf){
                this.local_metrics_filter.push(mf);
                this.loadMetricFilterColumnOptions()
                this.closeModal();
            },
            clearErrorForField(field_name){
                this.local_errors[field_name] = '';
            },
            clearErrors() {//reset errors to empty strings
                this.local_errors = {
                    chart_type:'',
                    group_by:'',
                    metrics:''
                }
            },
            hasErrors() {//validate the form
                if(this.local_chart.type == null || this.local_chart.type == ""){
                    this.local_errors.chart_type = "The chart type is required.";
                } else
                    this.local_errors.chart_type = "";
                
                if(this.local_chart.type !='scorecard' && (this.local_chart.group_by == null || this.local_chart.group_by == ""
                    || this.local_chart.group_by.length==0) ){
                    // scorecard should allow to have 0 dimension
                    this.local_errors.group_by = "A dimension is required.";
                } else
                    this.local_errors.group_by = "";

                if(this.local_chart.metrics == null || this.local_chart.metrics == ""
                    || this.local_chart.metrics.length==0){
                    this.local_errors.metrics = "A metric is required.";
                } else
                    this.local_errors.metrics = "";

                if (this.local_errors.chart_type!="" || this.local_errors.group_by!="" || this.local_errors.metrics!="")
                    return true

                return false
            },
            getOrderBys() {
                let ob = [];
                for(var i = 0; i < this.order_bys.length; i++)
                {
                    if(this.order_bys[i].value != null && this.order_bys[i].value != "") {
                        this.order_bys[i].value[0].direction = this.order_bys[i].direction;
                        ob.push(this.order_bys[i].value[0]);
                    }
                    else {
                        this.order_bys.splice(i, 1);
                        i--;
                    }
                }
                if(this.order_bys.length == 0)
                    this.addOrderBy();

                return ob;
            },
            getChart() {
                return this.local_chart;
            },
            generateTextboxContent(){
                let html_temp = '';
                if (this.textbox_details.icon_url){
                    html_temp += '<div class="d-flex flex-row">';
                    html_temp += '<div class="flex-shrink-1 insights-dashboard-group-icon me-2">';
                    if (this.textbox_details.icon_url.startsWith('http')){ // image
                        html_temp += '<img src="' + this.textbox_details.icon_url + '" class="textbox-image me-2">' ;
                    } else { // icon
                        html_temp += '<div class="'+ this.textbox_details.icon_color_class +' me-2"><img src="' + this.textbox_details.icon_url + '"></div>' ;
                    }
                    
                    html_temp += '</div>';
                    html_temp += '<div class="flex-fill">' + this.textbox_details.html_content +'</div>';
                    html_temp += '</div>';
                }
                else {
                    html_temp = '<div class="flex-fill">' + this.textbox_details.html_content +'</div>';
                }
                
                // this.local_chart.data = html_temp; 
                this.local_chart.html = encodeURIComponent(html_temp);
                this.$emit('updateChartData', this.local_chart);
            },
            previewChart(){
                this.clearErrors();

                if (this.local_chart.type != 'textbox'){
                    if(this.hasErrors()) {
                        return;
                    }
                    this.form.busy = true;

                    this.local_chart.order_by = this.getOrderBys();
                    this.$emit('loadingChartData', true);
                    window.axios.post('/api/chart/get_data', this.local_chart)
                        .then(response => {
                            this.form.busy = false;
                            this.local_chart.data = response.data.data;
                            this.local_chart.labels = response.data.labels;
                            this.local_chart.metric_formats = response.data.formats;
                            this.$emit('updateChartData', this.local_chart);
                    });                    
                } else {
                    this.generateTextboxContent();
                    this.form.busy = false;

                }
            },
            closeModal() {
                var myModalEl = document.getElementById('add-metric-filter-modal')
                var modal = bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
            }
        }
    }
</script>
