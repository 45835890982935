<template>
    <div id="pathways-manage">
        <div class="row px-2">
            <nav id="pathway-manage-nav" class="navbar navbar-expand primary-tab-bar pb-0">
                <div class="container-fluid">
                    <div class="collapse navbar-collapse">
                        <div class="btn-group toggle-btn-group mb-4" role="group">
                            <button type="button" class="btn btn-lg" v-for="(tab_name, index) in pathway_manage_tabs" @click.prevent="setActiveTab(index)"
                                :class="index==pathway_manage_tab_index_active ? 'btn-primary' : 'btn-white'">
                                {{ tab_name }}
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            <div v-if="pathway_manage_tab_index_active == 0">
                <pathways-manage-pathways :connection_datasets="connection_datasets" :client="client" :segments="segments" :datasets="datasets" 
                :datablends="datablends" :census_connections="census_connections"></pathways-manage-pathways>
            </div>

            <div v-if="pathway_manage_tab_index_active == 1">
                <pathways-manage-segments :connection_datasets="connection_datasets" :client="client" :segments="segments" :datasets="datasets"  :census_connections="census_connections"
                :datablends="datablends"></pathways-manage-segments>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['connection_datasets', 'client', 'segments', 'datasets', 'datablends', 'census_connections', 'tab_name'],
        data() {
            return {
                pathway_manage_tabs: ['Pathways', 'Segments'],
                pathway_manage_tab_index_active: 0 
            };
        },
        beforeMount() {
            if (this.tab_name =='manage_pathways'){
                this.pathway_manage_tab_index_active = 0;
            }
            if (this.tab_name =='manage_segments'){
                this.pathway_manage_tab_index_active = 1;
            }
        },
        mounted(){

        },
        methods: {
            setActiveTab(index){
                this.pathway_manage_tab_index_active = index;

                var newURL = '/pathways/'+this.client.url +'/';
                if (index == 0){
                    newURL += 'manage_pathways';
                } else {
                    newURL += 'manage_segments';
                }
                window.history.replaceState({}, {}, newURL);
                this.$forceUpdate();
            },
        }
    }
</script>
