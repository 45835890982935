<template>
    <div id="org_settings_connections">
        <div id="org_settings_connections_notifications" class="row mb-3"  v-if="!if_viewing_connection && unconnected_integration !== false">
            <div class="col-md-7">
                <div class="card system-connection-card h-100">
                    <div class="card-body d-flex">
                        <div class="d-flex flex-column justify-content-center">
                            <canvas id="integration_completion_precentage" class="pie-chart"></canvas>
                        </div>
                    
                        <div class="ms-4 integration-list">
                            <h6>Avid AI Integration Checklist:</h6>
                            <p class="mb-2">Each integration that you connect allows Avid to derive deeper insights and better recommendations.</p>

                            <p class="mb-2" v-for="type in integration_types">
                                <img v-if="connected_integration_list[type] != undefined" class="icon pe-1 green-icon" src="/img/icons/dialexa-icons/tick-circle.svg">
                                <img v-else class="icon pe-1 red-icon" src="/img/icons/dialexa-icons/danger.svg">
                                <span v-if="connected_integration_list[type] != undefined">{{ getIntegrationLabel(type) }} <em>({{connected_integration_list[type] }})</em></span>
                                <a v-else href="#" @click.prevent="addSystemByIntegrationType(type)">{{ getIntegrationLabel(type) }}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card system-connection-card h-100">
                    <div class="card-body m-2">
                        <div class="row">
                            <!-- Column for the icon -->
                            <div class="col-auto"> <!-- Use col-auto to fit the content's width -->
                                <img src="/img/icons/dialexa-icons/plug-connected.svg" class="modal-icon">
                            </div>
                            <!-- Column for text and button -->
                            <div class="col">
                                <h6>Connect your {{getIntegrationLabel(unconnected_integration)}} System</h6>
                                <p  class="mb-3">{{getIntegrationReasoning(unconnected_integration)}}</p>
                                <button class="btn btn-secondary" @click.prevent="addSystemByIntegrationType(unconnected_integration)">
                                    <img class="icon pe-1" src="/img/icons/dialexa-icons/plus.svg">
                                    Connect Your {{getIntegrationLabel(unconnected_integration)}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="!if_viewing_connection">
            <div class="d-flex flex-row justify-content-between gap-3">
                <div class="d-flex flex-row flex-grow-1">
                    <div class="d-flex flex-column gap-2">
                        <h4 class="">Your Connections ({{ connections.length }})</h4>
                        <!-- <p>Qui voluptatem delectus ut aut odit repudiandae consectetur atque temporibus.</p> -->
                    </div>
                </div>
                <div class="d-flex flex-row gap-2">
                    <button class="btn btn-lg btn-primary dropdown-toggle" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                        <img class="icon pe-1" src="/img/icons/dialexa-icons/plus.svg">Add
                    </button>
                    <div class="dropdown-menu dropdown-menu-primary">
                        <label class="dropdown-item" @click.prevent="addSystem()"  data-bs-toggle="modal" data-bs-target="#connections-setting-system-modal">
                            <img class="icon pe-1" src="/img/icons/dialexa-icons/keyboard-open.svg"> System
                        </label>
                        <label class="dropdown-item" @click.prevent="addFile()"  data-bs-toggle="modal" data-bs-target="#file-modal">
                            <img class="icon pe-1" src="/img/icons/dialexa-icons/document-upload.svg"> File
                        </label>
                    </div>
                </div>  
            </div>

            <div class="row">
                <nav class="navbar navbar-expand primary-tab-bar pb-0">
                    <div class="container-fluid">
                        <div class="collapse navbar-collapse">
                            <div class="btn-group toggle-btn-group mb-4" role="group">
                                <button type="button" class="btn btn-lg" v-for="(tab_name, index) in connection_tabs" @click.prevent="setActiveTab(index)"
                                    :class="index==connection_tab_index_active ? 'btn-primary' : 'btn-white'">
                                    {{ tab_name }}
                                </button>
                            </div>
                        </div>                    
                    </div>
                </nav>
            </div>

            <div class="row">
                <connections-settings-systems v-if="connection_tab_index_active == 0" :client="client" :integrations="integrations" 
                :connections="local_connections" @viewConnection="viewConnection"  @updateConnection="updateConnection"></connections-settings-systems>

                <connections-settings-files v-if="connection_tab_index_active == 1" :client="client" :files="local_files" :num_files="num_files"
                @viewFile="viewFile" @openAddFileModal="openAddFileModal" ></connections-settings-files>
            </div>
        </div>

        <div v-else>
            <div>
                <button class="btn btn-text back-button" @click.prevent="backToSelection()">
                    <img class="icon pe-1" src="/img/icons/dialexa-icons/chevrons-left.svg">
                    Back
                </button>   
            </div>
            <connections-settings-view-dataset :user="user" :client="client" :selected_connection_or_file="selected_connection_or_file" ></connections-settings-view-dataset>
        </div>

        <system-modal :id="'connections-setting-system-modal'" :client="client" :integrations="local_intergation_list" :connections="local_connections"
            :connection_selected="connection_selected" @connectionAdded="addConnection"></system-modal>
        <file-modal :client="client" :connection="file_connection" @connectionAdded="addConnection"></file-modal>

    </div>
</template>

<script>

import ChartDataLabels from 'chartjs-plugin-datalabels';
    export default {
        props: ['user', 'client', 'tab_name', 'integrations', 'connections', 'datasets', 'datablends', 'files', 'num_files'],
        data() {
            return {
                connection_tabs: ['Systems', 'Files'],
                connection_tab_index_active: 0,
                integration_types: ['crm', 'email', 'advertising', 'email_form', 'analytics'],
                local_intergation_list: [],
                connected_integration_list: [],
                chart: null,
                local_files: [],
                file_connection: null,
                if_viewing_connection: false,
                selected_connection_or_file: {}, // this can be either connection or file
                connection_id: null,
                file_id: null,
                connection_selected: null,
                local_connections: [],
            };
        },
        beforeMount() {
            //File Upload
            this.local_connections = this.connections;
            this.local_intergation_list = this.integrations;
            this.file_connection = this.connections.filter(conn => conn.integration_id == '71b0c3b0-8390-49ec-9984-1c04b7384d38')[0];
            this.local_files = this.files;
            // If url contains connection id
            this.setScreen();
        },
        mounted() {
            window.addEventListener('popstate', this.handlePopState);

            const params = new URLSearchParams(window.location.search);
            if(params.get('modal') != null){
                //Find the connection with the id = modal
                let conn = this.local_connections.find(x => x.id == params.get('modal'));
                if(conn){
                    this.connection_selected = conn;
                    var modal = new bootstrap.Modal(document.getElementById('connections-setting-system-modal'));
                    modal.show();
                }
            }

            this.renderChart(true);
        },

        computed: {
            unconnected_integration() {
                //Filter based upon the connect_integration_list keys
                var unconnected = this.integration_types.filter(element =>
                    !Object.keys(this.connected_integration_list).includes(element)
                );

                if(unconnected == null || unconnected.length == 0)
                    return false;

                return unconnected[0];
            }
        },
        beforeDestroy() {
            window.removeEventListener('popstate', this.handlePopState);
        },
        methods: {
            getIntegrationLabel(type){
                if(type == 'crm')
                    return 'CRM';
                if(type == 'email')
                    return 'Email Marketing';
                if(type == 'email_form')
                    return 'Email Capture';
                if(type == 'advertising')
                    return 'Advertising';
                if(type == 'analytics')
                    return 'Analytics';
            },
            getIntegrationReasoning(type){
                if(type == 'crm')
                    return 'Avid is designed to analyze your fundraising and marketing efforts. The most important part of this analysis is understanding your donors\' history and what activities produce the best behaviors.';
                if(type == 'email')
                    return 'A key aspect to understanding a donor\'s motivation is analyzing what separates them from similar individuals who aren\'t giving. Avid can help you understand what messages drive the best behavior and the key differences between existing donors and subscribers on your email file.';
                if(type == 'email_form')
                    return 'Understanding what drives your subcribers and donors to "opt in" can provide insights about what content they find most valuable. It also allows Avid to track the progress from initial opt in to downstream conversion.';
                if(type == 'advertising')
                    return 'Avid can help you understand the effectiveness of your advertising campaigns. This includes understanding the full impact of your advertising on donor behavior and the effectiveness of your advertising spend.';
                if(type == 'analytics')
                    return 'Your web analytics platform can provide insights into what content is most popular and can give you ideas for ways to expand your audience. This can lead to new acquisition ideas and better cultivation of your existing donors.';
            },
            setScreen() {
                const params = new URLSearchParams(window.location.search);
                if(params.get('connection') != null){
                    this.connection_id = parseInt(params.get('connection'));
                    this.connection_tab_index_active = 0;

                    // check if connection id exists in the connection array
                    var index = this.local_connections.findIndex(conn => conn.id == this.connection_id);
                    if(index >= 0){
                        this.if_viewing_connection = true;
                        this.selected_connection_or_file = this.local_connections[index];
                    } else {
                        var newURL = '/settings/v1/'+this.client.url +'/integrations';
                        window.history.replaceState({}, {}, newURL);
                    }
                }
                else if(params.get('file') != null){
                    this.file_id = parseInt(params.get('file'));
                    this.connection_tab_index_active = 1;

                    // check if connection id exists in the connection array
                    var index = this.local_files.findIndex(file => file.id == this.file_id);
                    if(index >= 0){
                        this.if_viewing_connection = true;
                        this.selected_connection_or_file = this.local_files[index];
                    } else {
                        var newURL = '/settings/v1/'+this.client.url +'/files';
                        window.history.replaceState({}, {}, newURL);
                    }
                } else {
                    this.if_viewing_connection = false;
                    //If the url contains /files
                    if (window.location.href.includes('/files')){
                        this.connection_tab_index_active = 1;
                        this.tab_name =='files';
                    }
                    else{
                        this.connection_tab_index_active = 0;
                        this.tab_name =='integrations';
                    }
                }
                this.setConnectedIntegrations();
                this.$forceUpdate();
            },
            setConnectedIntegrations(){
                var connected_integrations = [];
                this.connections.forEach((conn) => {
                    conn.integration.integration_types.forEach((integration) =>{

                        //TODO If it is other, check the data sets attached to it
                        if(integration == 'other') {
                            conn.datasets.forEach((dataset) => {
                                let type = '';
                                if(dataset.source_subtype == 'transactions' || dataset.source_subtype == 'constituents' )
                                    type = 'crm';
                                else if(dataset.source_subtype == 'email_form')
                                    type = 'email_form';

                                if(type != '') {
                                    //If the type hasn't been added at all
                                    if(connected_integrations[type] == undefined)
                                        connected_integrations[type] = conn.integration.display_name;
                                    //Make sure it wasn't already added
                                    else if(connected_integrations[type].indexOf(conn.integration.display_name) == -1)
                                        connected_integrations[type] += ", " + conn.integration.display_name;
                                }

                            });

                            return;
                        }

                        if(this.integration_types.includes(integration) == false)
                            return;
                        //Do the
                        if(connected_integrations[integration] == undefined)
                            connected_integrations[integration] = conn.integration.display_name;
                        else
                            connected_integrations[integration] += ", " + conn.integration.display_name ;
                    })
                })
                this.connected_integration_list = connected_integrations;
                
            },
            renderChart(animate) {
                var connection_count= Object.keys(this.connected_integration_list).length;
                var completed_precentage = 100* connection_count / this.integration_types.length; 
        
                const data = {
                labels: [
                    'Completed',
                    'Incomplete',
                ],
                datasets: [{
                    label: 'Connection Completion',
                    data: [ connection_count, this.integration_types.length - connection_count],
                    backgroundColor: ['rgb(34, 117, 81)', 'rgb(209, 31, 31)'],
                    color: ['rgb(255, 255, 255)', 'rgb(255, 255, 255)']
                    }]
                };
               var options = {
                    type: 'doughnut',
                    plugins: [ChartDataLabels],
                    data: data,
                    options: {
                        responsive: true,
                        //    maintainAspectRatio: false,
                        plugins: {
                            legend: { display: false },
                            tooltip: { display: false },
                            datalabels: { display: false },
                            textInside: {
                                title: completed_precentage.toString() + '%',
                                titleColor: 'black',
                                titleSize: 28,
                            //    text: 'completed',
                            //     textColor: 'black',
                            //     textSize: 18, 
                            }
                        },
                        animation: {
                            duration: (animate ? 500 : 0) // Disable animations by setting duration to 0
                        },
                        title: { display: false },
                    },
               };
               Chart.register({
                    id: 'textInside',
                    afterDatasetsDraw: function (chart, _) {
                        const ctx = chart.ctx;
                        const width = chart.width;
                        const height = chart.height;
                        const titleSize = options.options.plugins.textInside.titleSize;
                        ctx.font = titleSize + 'px Arial';
                        ctx.fillStyle = options.options.plugins.textInside.titleColor;
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        const title = options.options.plugins.textInside.title;
                        const titleX = Math.round(width / 2);
                        const titleY = Math.round(height / 2);
                        ctx.fillText(title, titleX, titleY);


                        // const textSize = options.options.plugins.textInside.textSize;
                        // ctx.font = textSize + 'px Arial';
                        // ctx.fillStyle = options.options.plugins.textInside.textColor;
                        // ctx.textAlign = 'center';
                        // ctx.textBaseline = 'middle';
                        // const text = options.options.plugins.textInside.text;
                        // const textX = Math.round(width / 2);
                        // const textY = Math.round(height / 2)+15;
                        // ctx.fillText(text, textX, textY);
                    }
                });

               if(this.chart != null)
                    this.chart.destroy();
   
               var ctx = document.getElementById('integration_completion_precentage');
                if(ctx != undefined)
                    this.chart = new Chart(ctx, options);
                   
           },
            setActiveTab(index){
                this.connection_tab_index_active = index;
                
                var newURL = '/settings/v1/'+this.client.url+'/';

                if (index == 0){
                    newURL += 'integrations';
                } else if (index == 1){
                    newURL += 'files';
                } 
                window.history.pushState({}, {}, newURL);
                this.$forceUpdate();
            },
            viewConnection(conn){
                this.if_viewing_connection = true;
                this.selected_connection_or_file = conn;
                if (this.selected_connection_or_file.datasets && this.selected_connection_or_file.datasets.length >1){
                   this.selected_connection_or_file.datasets.sort((a, b) => (a.name > b.name) ? 1 : -1); 
                }
                

                var newURL = '/settings/v1/'+this.client.url+'/integrations?connection='+conn.id;
                window.history.pushState({}, '', newURL);
            },
            viewFile(file){
                this.if_viewing_connection = true;
                this.selected_connection_or_file = file;

                var newURL = '/settings/v1/'+this.client.url+'/files?file='+file.id;
                window.history.pushState({}, {}, newURL);
            },
            openAddFileModal(){
                var modal = new bootstrap.Modal(document.getElementById('file-modal'));
                modal.show();
            },
            backToSelection(){
                this.if_viewing_connection = false;
                var newURL;
                if(this.selected_connection_or_file.datasets!= undefined){
                    newURL = '/settings/v1/'+this.client.url+'/integrations';
                } else {
                    newURL = '/settings/v1/'+this.client.url+'/files';
                }
                this.selected_connection_or_file = {};
                window.history.replaceState({}, {}, newURL);
                //Next tick
                this.$nextTick(() => { this.renderChart(false); });
            },
            addSystem(){
                this.connection_selected = null;
                this.local_intergation_list = this.integrations;
                var modal = new bootstrap.Modal(document.getElementById('connections-setting-system-modal'));
                modal.show();
            },
            addSystemByIntegrationType(integration_type){
                this.connection_selected = null;
                this.local_intergation_list = this.integrations.filter((integration) => integration.integration_types.includes(integration_type));
                var modal = new bootstrap.Modal(document.getElementById('connections-setting-system-modal'));
                modal.show();
            },
            addFile(){
                // var modal = new bootstrap.Modal(document.getElementById('file-modal'));
                // modal.show();
            },
            handlePopState(event) {
                this.setScreen();
                this.renderChart(false);
            },
            updateConnection(conn) {
                this.connection_selected = conn;
                var modal = new bootstrap.Modal(document.getElementById('connections-setting-system-modal'));
                modal.show();

                //If the "modal" variable is in the URL, remove it usig replace state (leave other querystring variables)
                var newURL = window.location.href.replace(/([&?])modal=\d+(&|$)/, function(match, p1, p2) {
                    return p1 === '?' ? (p2 === '&' ? '?' : '') : p2 ? p1 : '';
                }).replace(/[?&]$/, '');
                window.history.replaceState({}, {}, newURL);

            },
            addConnection(conn) {
                let message = "";
                let index = this.local_connections.findIndex(x => x.id === conn.id);
                //See if the conn.id is in the local_connections array (the id property)
                if(index>= 0){
                    //Loop through the properties and update it that way
                    for (let prop in conn) {
                        this.local_connections[index][prop] = conn[prop];
                    }

                    message = "Your " + conn.integration.display_name + " connection has been updated and will begin syncing momentarily.";
                }
                else {
                    //Add it to the beginnin of the rray
                    this.local_connections.push(conn);
                    message = "Your " + conn.integration.display_name + " connection has been added and will begin syncing momentarily. We'll notify you when your data is ready for use.";
                }

                this.$forceUpdate();

                Swal.fire({
                    title: "Success!",
                    text: message,
                    icon: "success",
                    iconColor:  "#46705F",
                    confirmButtonColor: "#46705F"
                });
            }
        }
    }
</script>
