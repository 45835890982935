<template>
    <div id="org_settings_profile">
        <form @submit.prevent="updateAgency" enctype="multipart/form-data">
            <div class="d-flex mb-4">
                <div class="flex-shrink-1 profile-image px-2 pe-4">
                    <!-- Logo Preview -->
                    <vue-dropzone ref="dropzone" id="dropzone" class="profile-dropzone" v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                    :options="dropzoneOptions" :awss3="awss3" :style="'background-image: url(' +form.logo+')'" ></vue-dropzone>
                    </vue-dropzone>
                </div>
                <div class="px-2 w-100">
                    <h4>Update Agency Details</h4>
                    <div class="row">
                        <div class="col-12">
                            <!-- Name -->
                            <div class="mb-3">
                                <label class="form-label">Agency Name</label>
                                <input type="text"
                                       class="form-control"
                                       :class="{'is-invalid': errors.name}"
                                       v-model="form.name"
                                       @blur="validateName">
                                <div class="invalid-feedback" v-if="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                            <!-- Domain -->
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <label class="form-label">Website Domain</label>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">
                                                https://
                                            </span>
                                            <input type="text"
                                                class="form-control"
                                                :class="{'is-invalid': errors.domain}"
                                                v-model="form.domain"
                                                @blur="validateDomain">
                                        </div>
                                        <div class="invalid-feedback" v-if="errors.domain">
                                            {{ errors.domain }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 mb-3">
                                    <label class="form-label">Dark Color
                                        <i class="fas fa-info-circle" data-bs-toggle="tooltip"
                                        title="Pick a dark color used in titles and other fonts that will be used when generating slides. It should match your branding."></i>
                                    </label>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" :style="{ 'background-color': form.dark_color }">
                                            <verte picker="square" model="hex" v-model="form.dark_color"></verte>
                                        </span>
                                        <input type="text"
                                            class="form-control"
                                            v-model="form.dark_color"
                                            readonly>
                                    </div>
                                </div>
                                <div class="col-sm-3 mb-3">
                                    <label class="form-label">Light Color
                                        <i class="fas fa-info-circle" data-bs-toggle="tooltip"
                                        title="Pick a bright color that will be used as an accent when generating slides. It should match your branding."></i>
                                    </label>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" :style="{ 'background-color': form.light_color }">
                                            <verte picker="square" model="hex" v-model="form.light_color"></verte>
                                        </span>
                                        <input type="text"
                                            class="form-control"
                                            v-model="form.light_color"
                                            readonly>
                                    </div>
                                </div>
                            </div>
                            <!-- URL Slug -->
                            <div class="mb-3">
                                <label class="form-label">Custom Registration URL <i class="fas fa-info-circle" data-bs-toggle="tooltip"
                                    title="You will receive a 20% referral bonus for any nonprofit that signs up for Avid via your custom registration link."></i></label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text">
                                        https://app.avidai.com/register/
                                    </span>
                                    <input type="text"
                                        class="form-control"
                                        :class="{'is-invalid': errors.url_slug}"
                                        v-model="form.url_slug"
                                        @blur="validateUrlSlug">
                                    <button type="button"
                                        class="btn btn-secondary"
                                        :disabled="!form.url_slug"
                                        @click="copyRegistrationUrl"
                                        aria-label="Copy Registration URL">
                                    <i class="fas fa-copy"></i>
                                </button>
                                </div>
                                <div class="invalid-feedback" v-if="errors.url_slug">
                                    {{ errors.url_slug }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger mt-3" v-if="form.error">
                There is an error in the fields above. Please correct and try again.
            </div>
            <button type="submit"
                    class="btn btn-primary mt-3"
                    :disabled="form.busy || hasError()">
                <i class="fa fa-btn fa-save"></i> Save Changes
            </button>
        </form>
    </div>
</template>

<script>
import Verte from 'verte';
import 'verte/dist/verte.css';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2';

export default {
    components: {
        Verte,
        vueDropzone: vue2Dropzone,
    },
    props: ['agency'],
    data() {
        return {
            form: {
                id: this.agency.id,
                name: this.agency.name,
                logo: this.agency.logo,
                domain: this.agency.domain,
                url_slug: this.agency.url_slug,
                light_color: this.agency.light_color || '#EFEFEF',
                dark_color: this.agency.dark_color || '#000000',
                busy: false,
                error: false,
            },
            errors: {
                name: '',
                domain: '',
                url_slug: '',
            },
            awss3: {
                signingURL: '/api/client/get_signed_url', //Where you will get signed url
                headers: {
                'X-Requested-With':'XMLHttpRequest',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
                },
                params : {},
                sendFileToServer : false //If you want to upload file to your server along with s3
            },
            dropzoneOptions: {
                url: "#",
                resizeWidth: 512,
                resizeHeight: 512,
                resizeMethod: 'crop',
                acceptedFiles: "image/*",
                renameFile: function (file) {
                    let new_name = "client/" + Date.now() + "_" + file.name;
                    return new_name;
                },
                dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> DROP IMAGE TO UPLOAD",
                clickable: true
            },
        };
    },
    mounted() {
        // Initialize tooltips
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl);
        });
    },
    methods: {
        validateName() {
            if (!this.form.name.trim()) {
                this.errors.name = 'The agency name is required.';
            } else {
                this.errors.name = '';
            }
        },
        validateDomain() {
            if (!this.form.domain.trim()) {
                this.errors.domain = 'The website domain is required.';
            } else {
                // Additional domain format validation can be added here
                //Remove any protocol and trailing slashes
                this.form.domain = this.form.domain.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
                //Ensure it ends with a valid TLD
                const regex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/;
                if (!regex.test(this.form.domain)) {
                    this.errors.domain = 'The website domain is invalid.';
                    return;
                }
                this.errors.domain = '';
            }
        },
        validateUrlSlug() {
            if (!this.form.url_slug.trim()) {
                this.errors.url_slug = 'The URL is required.';
                return;
            }

            // Regex to allow only lowercase letters, numbers, and hyphens
            const regex = /^[a-z0-9]+(?:-_[a-z0-9]+)*$/;
            if (!regex.test(this.form.url_slug)) {
                this.errors.url_slug = 'The URL slug can only contain lowercase letters, numbers, and hyphens.';
                return;
            }

            // Check uniqueness via API
            this.checkUrlSlugUnique();
        },
        checkUrlSlugUnique() {
            axios.get('/api/agencies/check-url-slug', {
                params: {
                    url_slug: this.form.url_slug,
                    agency_id: this.agency.id,
                }
            })
            .then(response => {
                if (!response.data.unique) {
                    this.errors.url_slug = 'The URL slug has already been taken.';
                } else {
                    this.errors.url_slug = '';
                }
            })
            .catch(error => {
                console.error('Error checking URL slug uniqueness:', error);
                this.errors.url_slug = 'Unable to verify URL slug uniqueness.';
            });
        },
        hasError() {
            return this.errors.name != '' || this.errors.domain != '' || this.errors.url_slug != '';
        },
        updateAgency() {
            // Validate all fields before submission
            this.validateName();
            this.validateDomain();
            this.validateUrlSlug();

            if (this.hasError()) {
                this.form.error = true;
                return;
            }

            this.form.busy = true;
            this.form.error = false;

            axios.put(`/api/agencies/${this.agency.id}`, this.form)
            .then(response => {
                this.form.busy = false;
                Swal.fire({
                    html: '<h4 class="mb-0 text-white">Agency updated successfully!</h4>',
                    icon: 'success',
                    toast: true,
                    position: 'bottom-right',
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        popup: 'bg-primary'
                    },
                });
            })
            .catch(error => {
                this.form.busy = false;
                this.form.error = true;
            });
        },        
        s3UploadSuccess(s3ObjectLocation){
            //Stupid Amazon URL encoding my folder
            s3ObjectLocation = s3ObjectLocation.replace("%2F", "/");
            this.form.logo = s3ObjectLocation;

            //Get the primary colors
            window.axios.post('/api/client/get_colors', this.form)
                .then(response => {
                if(response.data.light_color != null) {
                    this.form.light_color = response.data.light_color;
                    this.form.dark_color = response.data.dark_color;
                }
                });

        },
        copyRegistrationUrl() {
            const baseUrl = 'https://app.avidai.com/register/';
            const fullUrl = `${baseUrl}${this.form.url_slug}`;
            
            if (!navigator.clipboard) {
                // Fallback for older browsers
                const textArea = document.createElement('textarea');
                textArea.value = fullUrl;
                textArea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    document.execCommand('copy');
                    this.showCopySuccess();
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                    Swal.fire({
                        html: '<h4 class="mb-0 text-white">Failed to copy URL.</h4>',
                        icon: 'error',
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 3000,
                        customClass: {
                            popup: 'bg-danger'
                        },
                    });
                }
                document.body.removeChild(textArea);
                return;
            }

            navigator.clipboard.writeText(fullUrl).then(() => {
                this.showCopySuccess();
            }, (err) => {
                console.error('Async: Could not copy text: ', err);
                Swal.fire({
                    html: '<h4 class="mb-0 text-white">Failed to copy URL.</h4>',
                    icon: 'error',
                    toast: true,
                    position: 'bottom-right',
                    showConfirmButton: false,
                    timer: 3000,
                    customClass: {
                        popup: 'bg-danger'
                    },
                });
            });
        },
        showCopySuccess() {
            Swal.fire({
                html: '<h4 class="mb-0 text-white">URL Copied to Clipboard!</h4>',
                icon: 'success',
                toast: true,
                position: 'bottom-right',
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                    popup: 'bg-primary'
                },
            });
        }
    },
}
</script>