<template>
    <div class="campaign-type-editor">
        <div class="asset-type-overview">
        	<h2>{{local_asset_type.id? 'Update': 'Add'}} Asset Type</h2>
         
            <div class="card box-content mt-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label class="form-label">Name</label>
                            <input type="text" id="name-input" class="form-control" v-model="local_asset_type.name"
                                :class="((errors.name != '' )? 'is-invalid':'')" @change="clearErrorForField('name')">
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                {{errors.name}}
                            </div>
                        </div>

                        <div class="col-4 mb-3">
                            <label class="form-label"><a href='https://fontawesome.com/search' target="_blank">Icon Class</a></label>
                            <input type="text" id="icon-class-input" class="form-control" v-model="local_asset_type.icon_class"
                                :class="((errors.icon_class != '' )? 'is-invalid':'')" @change="clearErrorForField('icon_class')">
                            <div class="text-danger invalid-feedback" v-if="errors.icon_class != ''" v-html="errors.icon_class">
                            </div>
                        </div>
                        <div class="col-2 mb-3">
                            <label class="form-label">Has a Launch Date? 
                                <i class="fas fa-info-circle ms-2" title="Does this asset have a launch date or does it need to be ready when the campaign launches?" data-bs-toggle="tooltip" ></i>
                            </label>
                            <select class="form-select" v-model="local_asset_type.has_launch_date">
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.has_launch_date != ''" v-html="errors.has_launch_date">
                            </div>
                        </div>

                        <div class="col-12">
                            <label class="form-label">Description</label>
                            <textarea id="report-description" class="form-control full-height" :class="((errors.description != '' )? 'is-invalid':'')"
                            v-model="local_asset_type.description" ref="textarea"  @input="resizeTextarea()" @change="clearErrorForField('description')"></textarea>
                            <div class="text-danger invalid-feedback" v-if="errors.description != ''">
                                {{errors.description}}
                            </div>
                        </div>
					</div>
                </div>
            </div>

			<div>
				<h5 class="mt-4">Asset Inputs
                    <button type="submit" class="btn btn-white ms-2" id="add-section-button" @click.prevent="addAssetInput" :disabled="form.busy">
                        <i class="fa fa-btn fa-plus me-0"></i>
                    </button>
                </h5>

				<div v-if="local_asset_type.inputs && local_asset_type.inputs.length === 0">
					<div class="card box-content mb-3">
						<div class="card-body mt-3 text-center">
							<h3 class="text-center">No inputs have been added yet.</h3>
							<button type="submit" class="btn btn-secondary" id="add-section-button" @click.prevent="addAssetInput" :disabled="form.busy">
								<i class="fa fa-btn fa-plus"></i> Add an Input
							</button>
						</div>
					</div>
				</div>

                <draggable v-model="local_asset_type.inputs" handle=".handle" class="mb-5">
					<div v-for="(input, index) in local_asset_type.inputs" ref="assetInput"  :key="input.id" class="mb-3 ">
						<div class="card mx-0 " :class="input.if_active ?'active-section' : ' box-content'">
							<div class="card-body">
								<div class="accordion accordion-flush" >
									<div class="accordion-item border-0">
										<div class="accordion-header ms-2 me-5" @click.prevent="setActiveInputSection(index)">
											<div class="text-start collapsed p-2 accordion-button section-accordion-button" type="button" 
												:data-bs-target="'#section-editing-' + index" data-bs-toggle="collapse"
												aria-expanded="false" :aria-controls="'#section-editing-' + index" @click="resizeTextareaExpanding(index)">
												
                                                <h4 class="mb-1">

                                                    <div class="handle float-start me-2">
                                                            <img src="/img/icons/dialexa-icons/vertical-dots.svg" alt="drag" class="icon">
                                                    </div>
                                                    <i class="fa-solid fa-circle-exclamation text-danger" v-if="errors.inputs == null || Object.values(errors.inputs['i'+input.id]).join('') != ''"></i>
                                                    Input: {{ input.name? input.name: 'New Input (unsaved)' }} 
                                                </h4>
                                            </div>
										</div>
										<div :id="'section-editing-' + index" class="collapse accordion-collapse section-accordion-collapse" >
											<div class="mx-2">
												<hr class="mx-3 mb-2 section-divider">

                                                <div class="row">
                                                    <div class="col-12 mb-3">
                                                        <label class="form-label">Name *</label>
                                                        <input type="text" id="name-input" class="form-control" v-model="input.name"
                                                            :class="((errors.inputs['i' + input.id].name != '' )? 'is-invalid':'')" @change="clearErrorForInputField('i' + input.id, 'name')">
                                                        <div class="text-danger invalid-feedback" v-if="errors.inputs['i'+input.id].name != ''">
                                                            {{errors.inputs['i'+input.id].name}}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">

                                                    <div class="col-12 mb-3">
                                                        <label class="form-label">Description</label>
                                                        <textarea id="report-description" class="form-control full-height" v-model="input.description" ref="textarea" @input="resizeTextarea()" ></textarea>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 mb-3">
                                                        <label class="form-label">Type *</label>
                                                        <select class="form-select"  v-model="input.type" :class="((errors.inputs['i'+input.id].type != '' )? 'is-invalid':'')" 
                                                        @change="changeInputType(index)">
                                                            <option value="text">Text</option>
                                                            <option value="textarea">Textarea</option>
                                                            <option value="wysiwyg">WYSIWYG</option>
                                                            <option value="image">Image Upload</option>
                                                            <option value="link">Link</option>
                                                            <option value="file">File Upload</option>
                                                            <option value="dropdown">Dropdown</option>
                                                        </select>
                                                        <div class="text-danger invalid-feedback" v-if="errors.inputs['i'+input.id].type != ''">
                                                            {{errors.inputs['i'+input.id].type}}
                                                        </div>
                                                    </div>

                                                    <div class="col-6 mb-3" v-if="input.type == 'text' || input.type== 'textarea'">
                                                        <label class="form-label">Maximum Number of Characters</label>
                                                        <input type="number" id="name-input" class="form-control" v-model="input.max_length">
                                                    </div>                                                

                                                    <div class="col-12 mb-3" v-if="input.type == 'dropdown'">
                                                        <label class="form-label">Options *                     
                                                            <button type="submit" class="btn btn-white ms-2" @click.prevent="addOption(index)" :disabled="form.busy">
                                                                <i class="fa fa-btn fa-plus me-0"></i>
                                                            </button> 
                                                        </label>
                                                        <div v-for="(o, o_i) in input.options" :key="o_i" class="d-flex mb-1">
                                                            <span class="me-2 mt-2">{{ o_i + 1 }}. </span>
                                                            <input type="text" id="name-input" class="form-control" v-model="input.options[o_i]" >
                                                            <button type="submit" class="btn btn-none ms-2" v-if="input.options.length > 1" @click.prevent="removeOption(index, o_i)" :disabled="form.busy">
                                                                <img class="icon" src="/img/icons/dialexa-icons/trash.svg" >
                                                            </button> 
                                                        </div>
                                                        <div class="text-danger invalid-feedback" v-if="errors.inputs['i'+input.id].options != ''">
                                                            {{errors.inputs['i'+input.id].options}}
                                                        </div>
                                                        
                                                    </div>       
                                                </div>
                                                <div class="row">
                                                    <div class="mb-3">
                                                        <div class="form-check">
                                                            <label class="form-check-label"></label>
                                                            <input type="checkbox" class="form-check-input" v-model="input.optional" >
                                                            Optional <i class="fas fa-info-circle ms-2" title="This means that the input may or may not be generated by the AI" data-bs-toggle="tooltip" ></i>
                                                            <!-- <input class="form-check-input" type="checkbox" :value="item" v-model="local_options_selected" :id="item" @change.prevent="changeMultiSelection(item)">
                                                            <input class="form-check-input" type="checkbox" value="LOWER" v-model="unit.modification">
                                                            <input class="form-check-input" type="checkbox" :value="option" v-model="dimension.value" @change="applyFilter(index, option)"> -->
                                                        
                                                        </div>
                                                    </div>
                                                </div>
											</div>
										</div>
									</div>
									<button type="button" class="asset-accordion-delete btn" aria-label="Close" @click="deleteAssetInput(index)">
										<i class="fa fa-times" aria-hidden="true"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
                </draggable>
			</div>			 
        </div>

        <div v-if="local_asset_type.inputs && local_asset_type.inputs.length >0" class="sticky-footer w-100">
            <div>
                <nav class="col-12 navbar navbar-expand">
                    <button type="submit" class="btn btn-primary ms-2" id="save-button" @click.prevent="update" :disabled="form.busy">
                        <i class="fa fa-btn fa-save"></i> Save 
                    </button>
                    <button class="btn btn-secondary ms-2" id="add-section-button" @click.prevent="addAssetInput" :disabled="form.busy">
                        <i class="fa fa-btn fa-plus"></i> Add Input Field
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
// import { options } from 'laravel-mix';
import draggable from 'vuedraggable';
import Swal from 'sweetalert2';
import vSelect from "vue-select";

    export default {
        components: {draggable, Swal, vSelect },
        props: {
			asset_type: {
				type: Object,
				default: {},
			},
		},
        data() {
            return {
				local_asset_type: {},
                errors: {
                    name: '',
                    icon_class: '',
                    has_launch_date: '',
                    description: '',
                    inputs: {}
                },
                active_input_section: {},
                form: {
                    successful: false,
                    busy: false,
                    error: false 
                },
                if_changes_made: false,
            };
        },
        beforeMount() {
            this.local_asset_type = {...this.asset_type};
            //Add an error object for each input
            this.local_asset_type.inputs.forEach((input, index)=>{
                this.errors.inputs['i'+input.id] = this.getInputErrorObj();
            });
            console.log(this.errors.inputs);
        },
        updated() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {
            revertOverviewChange(){
                if(this.asset_type.name != null) {
                    this.local_asset_type.name = this.asset_type.name;   
                    this.local_asset_type.icon_class = this.asset_type.icon_class;   
                    this.local_asset_type.description = this.asset_type.description;
                }
                this.is_editing_asset_type_overview =false;
            },
            clearErrorForField(field_name){
                this.errors[field_name] = '';
            },     
            clearErrorForInputField(index, field_name){
                this.errors.inputs[index][field_name] = '';
            },      
            resizeTextarea() {
                var element = document.querySelectorAll('textarea')[0];
                if (element && element.style){
                    element.style.height = '';
                    element.style.height = Math.max(element.offsetHeight, element.scrollHeight) + "px";
                }                   
            },
            optionalToggle(index){
                this.local_asset_type.inputs[index].optional = !this.local_asset_type.inputs[index].optional;
                this.$forceUpdate();
            },  
            hasErrors() {//validate the form
                // Error checking for overview section
                if(this.local_asset_type.name == null || this.local_asset_type.name == ""){
                    this.errors.name = "A name is required.";
                } else
                    this.errors.name = "";

                
                if(this.local_asset_type.icon_class == null || this.local_asset_type.icon_class == ""){
                    this.errors.icon_class = "An icon class is required.";
                }
                //The icon class should be a font awesome class (split by space and make sure every part is fa-)
                else if(!this.local_asset_type.icon_class.split(' ').every((part)=>part.startsWith('fa-'))){
                    this.errors.icon_class = "The icon class should be a valid Font Awesome class. Examples for the field above would be 'fa-phone' or 'fa-solid'. <a href='https://fontawesome.com/search' target='_blank'>Click here to look some up.</a>";
                }
                else
                    this.errors.icon_class = "";

                if(this.local_asset_type.has_launch_date == null || this.local_asset_type.has_launch_date == ""){
                    this.errors.has_launch_date = "Does the asset have a launch date?";
                } else
                    this.errors.has_launch_date = "";

                if(this.local_asset_type.description == null || this.local_asset_type.description == ""){
                    this.errors.description = "A description is required.";
                } else
                    this.errors.description = "";


                var if_input_has_error = false;
                if (this.local_asset_type.inputs && this.local_asset_type.inputs.length>0){
                    this.local_asset_type.inputs.forEach((input, index)=>{
                        if(!input.name || input.name.trim() == ""){
                            this.errors.inputs['i'+input.id].name = "A name is required.";
                        } else {
                            this.errors.inputs['i'+input.id].name = "";
                        }
                        if(!input.type || input.type.trim() == ""){
                            this.errors.inputs['i'+input.id].type = "A type is required.";
                        }
                        else {
                            this.errors.inputs['i'+input.id].type = "";
                        }
                        if(input.type == 'dropdown' && (!input.options || input.options.length == 0 || input.options.includes(""))){
                            this.errors.inputs['i'+input.id].options = "Options are required for dropdown field and option values cannot be empty.";
                        }
                        else {
                            this.errors.inputs['i'+input.id].options = "";
                        }
                    })    

                    if_input_has_error = this.checkIfInputHasError();
                }

                if (this.errors.name != "" || this.errors.icon_class!="" || this.errors.has_launch_date!="" || this.errors.description!="" || if_input_has_error){
                    return true
                } else {
                    return false
                }
            },
            checkIfInputHasError(){
                let has_error = false;
                //Loop through the input errors (it is an object, not an array)
                //
                Object.keys(this.errors.inputs).forEach((key)=>{
                    if(Object.values(this.errors.inputs[key]).join('') != ''){
                        has_error = true;                       
                    }
                });

                return has_error;
            },
            getInputErrorObj() {
                return {
					name: '',
                    type: '',
                    max_length: '',
                    options: ''
                };
            },
            addAssetInput(){
                var new_asset_input = this.createNewAssetInput();
                this.local_asset_type.inputs.push(new_asset_input);

                this.errors.inputs["i"+new_asset_input.id] = this.getInputErrorObj();

                this.if_changes_made = true;
                // this.$forceUpdate();
            },
			createNewAssetInput(){
                var asset_input = {
                    //Random negative number
                    id: Math.round(Math.random()*-10000),
                    if_active: false,
					asset_type_id: this.local_asset_type.id,
					name: '',
					description: '',
                    type: 'text', // choose text by default
                    optional: false,
                    max_length: null,
                    options: null,
                    order: this.local_asset_type.inputs.length + 1
                };
                this.if_changes_made = true;
                return asset_input;
            },
            changeInputType(index){
                this.clearErrorForInputField('i'+this.local_asset_type.inputs[index].id, 'type');
                if(this.local_asset_type.inputs[index].type == 'dropdown' && (!this.local_asset_type.inputs[index].options || !this.local_asset_type.inputs[index].options.length == 0)){
                    this.local_asset_type.inputs[index].options = [''];
                }

            },
            addOption(input_field_index){
                this.local_asset_type.inputs[input_field_index].options.push('');
            },
            removeOption(input_field_index, option_index){
                this.local_asset_type.inputs[input_field_index].options.splice(option_index, 1);
            },
            deleteAssetInput(index){
                Swal.fire({
                  title: "Are you sure you want to delete the input field?",
                  text: "Once saving the current Asset Type, this input field will be removed from it and all Campaign Types associated with it.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.errors.inputs['i'+this.local_asset_type.inputs[index].id] = this.getInputErrorObj();
                        this.local_asset_type.inputs.splice(index, 1);
                        this.if_changes_made = true;

                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Input Deleted!</h4>',
                            target: '.asset-type-builder',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            },
                        });
                    }
                });
            },
            setActiveInputSection(index){
                var collapsableSections = document.querySelectorAll('.section-accordion-collapse');
                var collapsableSectionAccordionButtons = document.querySelectorAll('.section-accordion-button');

                for(var i = 0; i < this.local_asset_type.inputs.length; i++){
                    if(i == index){
                        this.local_asset_type.inputs[i].if_active = true;
                        this.active_input_section = this.local_asset_type.inputs[i];
                    } else {
                        collapsableSections[i].classList.remove('show');
                        collapsableSectionAccordionButtons[i].classList.add('collapsed');
                        this.local_asset_type.inputs[i].if_active = false;    
                    }
                }      
                this.$forceUpdate();
            },
            resizeTextareaExpanding(index) {
                if(this.local_asset_type.inputs[index].if_active == true){
                    var element = document.querySelectorAll('textarea');

                    for(var index = 0; index<element.length; index++){
                        if (element[index] && element[index].style){
                            element[index].style.height = '';
                            element[index].style.height = Math.max(element[index].offsetHeight, element[index].scrollHeight) + "px";
                        }                            
                    }                    
                }
            },
            update() {
                // Check for frontend validation errors
                if (this.hasErrors()) {
                    Swal.fire({
                        title: "Error saving the Asset Type",
                        text: "There were some issues saving this Asset Type; please review and make the suggested changes.",
                        icon: "warning",
                        iconColor: "#D11F1F",
                        confirmButtonColor: "#D11F1F",
                        confirmButtonText: "View Errors",
                    });
                    this.$forceUpdate();
                    return;
                }

                // Indicate that the form is being submitted
                this.form.busy = true;

                // Assign the correct order to each input
                if (this.local_asset_type.inputs && this.local_asset_type.inputs.length > 0) {
                    this.local_asset_type.inputs.forEach((input, index) => {
                        input.order = index + 1;
                    });
                }

                // Determine whether to create a new AssetType or update an existing one
                let axiosRequest;
                if (!this.local_asset_type.id) {
                    axiosRequest = window.axios.post('/api/campaigns/asset_type', this.local_asset_type);
                } else {
                    axiosRequest = window.axios.put(`/api/campaigns/asset_type/${this.local_asset_type.id}`, this.local_asset_type);
                }

                // Make the API request
                axiosRequest
                    .then((response) => {
                        if (response.data.success) {
                            // Show success SweetAlert and redirect
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: this.local_asset_type.id
                                    ? "Asset Type has been updated successfully!"
                                    : "Asset Type has been created successfully!",
                                iconColor: "#46705F",
                                confirmButtonColor: "#46705F",
                                confirmButtonText: 'OK',
                            }).then(() => {
                                // Redirect to the admin list page
                                window.location.href = '/campaigns/admin/list';
                            });
                        } else {
                            // Handle unexpected success response structure
                            Swal.fire({
                                title: "Error saving the Asset Type",
                                text: "An unexpected error occurred. Please try again.",
                                icon: "error",
                                iconColor: "#D11F1F",
                                confirmButtonColor: "#D11F1F",
                                confirmButtonText: "OK",
                            });
                        }
                    })
                    .catch((error) => {
                        this.form.busy = false;
                        if (error.response && error.response.status === 422) {
                            // Handle validation errors returned from the API
                            const apiErrors = error.response.data.errors;
                            
                            // Reset current errors
                            this.errors = {
                                name: '',
                                icon_class: '',
                                has_launch_date: '',
                                description: '',
                                inputs: {},
                            };

                            // Iterate through the API errors and map them to the Vue component's errors object
                            Object.keys(apiErrors).forEach((key) => {
                                const messages = apiErrors[key];
                                if (key.startsWith('inputs.')) {
                                    // Example key: inputs.0.name
                                    const parts = key.split('.');
                                    const index = parseInt(parts[1], 10);
                                    const field = parts[2];
                                    const input = this.local_asset_type.inputs[index];
                                    if (input) {
                                        const input_id = input.id;
                                        if (!this.errors.inputs["i"+input_id]) {
                                            this.$set(this.errors.inputs, "i"+input_id, this.getInputErrorObj());
                                        }
                                        if (field === 'options') {
                                            // Handle dropdown options errors
                                            if (!this.errors.inputs["i"+input_id].options) {
                                                this.$set(this.errors.inputs["i"+input_id], 'options', []);
                                            }
                                            messages.forEach((msg, msgIndex) => {
                                                this.errors.inputs["i"+input_id].options[msgIndex] = msg;
                                            });
                                        } else {
                                            this.errors.inputs["i"+input_id][field] = messages.join(' ');
                                        }
                                    }
                                } else {
                                    // Handle overview field errors
                                    this.errors[key] = messages.join(' ');
                                }
                            });

                            // Show validation error SweetAlert
                            Swal.fire({
                                title: "Error saving the Asset Type",
                                text: "There were some issues saving this Asset Type; please review and make the suggested changes.",
                                icon: "warning",
                                iconColor: "#D11F1F",
                                confirmButtonColor: "#D11F1F",
                                confirmButtonText: "View Errors",
                            });
                        } else {
                            // Handle other types of errors
                            Swal.fire({
                                title: "Error saving the Asset Type",
                                text: "An unexpected error occurred. Please try again later.",
                                icon: "error",
                                iconColor: "#D11F1F",
                                confirmButtonColor: "#D11F1F",
                                confirmButtonText: "OK",
                            });
                        }
                    })
                    .finally(() => {
                        // Reset the busy state
                        this.form.busy = false;
                    });
                },
            }
        
    }
</script>

  