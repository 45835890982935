<template>
    <div class="accordion-multi-select w-100" @click="handleClick" >
        <v-select :options="local_options" v-model="value_obj"
            :searchable="true" class="searchable-select v-multi-select column-display"
            :selectable="(option) => option.text != '' && option.text != 'CATEGORY DIVIDER'"
            label="text" @search="fetchOptions"
            @input="emitInput"  multiple
            :filterable="false">
            <template #selected-option="{ text, category }">
              <span>{{text}}</span>
            </template>
    
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes" style="width: 12px; line-height: 8px;">
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                        <path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/>
                    </svg>
                </span>
            </template>
    
            <template #option="{ text, category, extra }">
              <div v-if="text=='CATEGORY DIVIDER'" class="main-category-header" > 
                {{ category }}
              </div>
              <div v-if="(category=='metric' || category=='filter'|| category=='dimension')" class="suboption show"
               :data-subcategory="category">
                {{ text }} 
              </div>
              
              <div v-if="text==''" :data-category="category" class="category-header"
                v-on:click="expandCategory(category, null)"
                data-isexpanded="false">
                {{ category }} <i class="fa-solid fa-caret-right"></i>
              </div>

              <div v-else class="suboption" :data-subcategory="category"
                :class="category==null || category==''? 'show' : ''">
                {{ text }} <span v-html="extra" v-if="extra != null"></span>
              </div>
            </template>
            <template #no-options="{ search, searching, loading }">
                <div class="suboption show" v-if="is_loading">
                    <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                </div>
                <div class="suboption show" v-else>
                    <em>No results found</em>
                </div>
            </template>
        </v-select>
        <input type="hidden" :name="field_name" :value="(value_obj == null ? [null] : value_obj.value)" />
    </div>
    </template>
    <script>
        import vSelect from "vue-select";
    
        export default {
            props: {
                value: [],
                options: {
                    type: Array,
                    default: []
                },
                field_name: {
                  type: String,
                  default: 'vue-multi-select',
                },
                show_hidden_fields: {
                    type: Boolean,
                    default: false,
                },
                max_num_values: {
                    default: 0,
                },
            },
            components: {
                vSelect
            },
            data() {
                return {
                    value_obj: null,
                    local_options: [],
                    local_options_original: [],
                    is_loading: false,
                };
            },
            mounted() {
                this.local_options_original = JSON.parse(JSON.stringify(this.options));                
                this.local_options = this.formatOptions();
                this.loadSelectedOptions();
            },
            watch:{
                value(old_value, new_value){
                    this.loadSelectedOptions()
                },
                options(old_value, new_value){
                    this.local_options_original = JSON.parse(JSON.stringify(this.options));
                    this.loadSelectedOptions();
                }
            },
            methods: {
                handleClick() {
                  this.$emit('editing');
                },
                formatOptions(){
                    var options_list = [];                        
                    var old_category = null;
                    var old_main_category = null;

                    this.local_options_original.forEach(option =>{

                        if(!this.show_hidden_fields && (option.value != null && typeof option.value === 'string' && (option.value.indexOf("_") == 0 || option.value.indexOf("._") >= 0)))
                            return;

                        let key = option.main_category == "Columns" ? option.value: option.id;
                        let text = option.main_category == "Columns" ? option.text: option.name;
                        let type = option.type;
                        let category = option.category;
                        let main_category = option.main_category;

                        if(main_category) { //if has multi-tier of categories, set up main category header
                            
                            if(main_category != old_main_category){
                                options_list.push({
                                    main_category: main_category,
                                    category: main_category,
                                    value:"CATEGORY DIVIDER-"+main_category,
                                    text:"CATEGORY DIVIDER"
                                });
                                old_main_category = main_category;                                
                            }
                        } 
                        
                        if(category && category != old_category){
                            options_list.push({
                                main_category: main_category,
                                category: category,
                                value: "DIVIDER-"+ category,
                                text: "",
                            });
                        }

                        old_category = category;

                        var obj = {
                            main_category: main_category,
                            category: category,
                            type: type,
                            value: key,
                            text: text
                        };

                        //If format is in the object, add it too.
                        if(option.format != undefined)
                            obj.format = option.format;
                        if(option.color != undefined)
                            obj.color = option.color;

                        options_list.push(obj);

                    });

                    return options_list;
                },
                emitInput() {

                    if(this.value_obj == null)
                        this.$emit('input', null);
                    else{
                        //If the max number of values is set, and the value_obj is at the max then just remove the second to last element
                        if(this.max_num_values >0 && this.value_obj.length == this.max_num_values+1)
                            this.value_obj.splice(this.value_obj.length-2, 1);

                        this.$emit('input', this.value_obj);
                    }
                },
                loadSelectedOptions(){
                    var values= (this.value && Array.isArray(this.value)) ? this.value : [this.value];
                    
                    //Reset the array
                    this.local_options = this.formatOptions();

                    if(values != null && values.length != 0 && values!=''){
                        var value_obj_array = []
                        values.forEach(value_obj => {
                            var value_object = null;
                            if (typeof value_obj == 'object'){
                                value_object = this.local_options.find(option => (option.value == value_obj.value) && (option.main_category == value_obj.main_category));   
                            } else { // value_obj is a string
                                value_object = this.local_options.find(option => option.value == value_obj);   
                            }
                            //If it found the object in the list of options...
                            if(value_object != null)
                                value_obj_array.push(value_object)
                        })
                        this.value_obj = value_obj_array;
                    } else{
                        this.value_obj = null
                    }
                },
                expandCategory(cat, expand) {
                    var headline = document.querySelector("div[data-category='"+cat+"']");
                    var lines = document.querySelectorAll("div[data-subcategory='"+cat+"']");
    
                    if(headline == undefined || lines == undefined)
                        return false;
    
                    if((headline.dataset.isexpanded == "false" || !headline.dataset.isexpanded ) || expand === true) {
                        for(var i = 0; i < lines.length; i++)
                            lines[i].style.display="block";
                        var divs = headline.getElementsByClassName("fa-caret-right");
                        for(var i = 0; i < divs.length; i++){
                            divs[i].classList.add("fa-caret-down");
                            divs[i].classList.remove("fa-caret-right");                            
                        }
                        headline.dataset.isexpanded = true;
                    }
                    else {
                        for(var i = 0; i < lines.length; i++)
                            lines[i].style.display="none";
                        var divs = headline.getElementsByClassName("fa-caret-down");
                        for(var i = 0; i < divs.length; i++){
                            divs[i].classList.add("fa-caret-right");
                            divs[i].classList.remove("fa-caret-down");                            
                        }
                        headline.dataset.isexpanded = false;
                    }
    
                    return false;
                },
                fetchOptions (search, loading) {
                    //Reset the array
                    this.local_options = this.formatOptions();
    
                    if(search == "") return;
                    this.is_loading = true;

                    //Look at each column
                    for(var i = this.local_options.length-1; i >= 0 ; i--) {
                        //If the search string isn't in the text or the category
                        if(this.local_options[i].text.toLowerCase().indexOf(search.toLowerCase()) == -1
                            && (this.local_options[i].category == null
                            || this.local_options[i].category.toLowerCase().indexOf(search.toLowerCase()) == -1)
                            && this.local_options[i].text != "" && this.local_options[i].text != "CATEGORY DIVIDER") //And not a category divider
    
                            this.local_options.splice(i, 1);
                    }
    
                    //Get the remaining categories
                    var cats = [];
                    for(var i = 0; i < this.local_options.length; i++)
                        if(this.local_options[i].category != null && this.local_options[i].category != 'metric' &&this.local_options[i].category != 'dimension' && this.local_options[i].category != 'filter'
                        && !cats.includes(this.local_options[i].category) && this.local_options[i].text != "" && this.local_options[i].text != "CATEGORY DIVIDER")
                            cats.push(this.local_options[i].category);

                    //Expand the categories
                    for(var i = 0; i < cats.length; i++)
                        this.expandCategory(cats[i], true);
    
                    //Remove a category if it isn't in the array of categories
                    for(var i = this.local_options.length-1; i >= 0 ; i--)
                        if(this.local_options[i].text == "" && this.local_options[i].category != null && !cats.includes(this.local_options[i].category))
                            this.local_options.splice(i, 1);

                    this.is_loading = false;
                    return true;
                }

            }

        }
    </script>
    
