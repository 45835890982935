<template>
    <div class="pathways-mapping  mb-5">
        <div class="row px-2 " v-if="(local_census_connection.id == null || local_census_connection.id <= 0) && !isFile() ">
            <div class="card box-content mb-5">
                <div class="card-body">
                    <h4>Information</h4>
                    <!-- https://www.facebook.com/customaudiences/value_based/tos/?act=1474160189570282 -->
                    <p>Before data can be sent to {{ local_destination.integration.name }}, you first need to align the columns to those available in this data source and decide how the data should be kept in sync on an ongoing basis. <span v-if="this.census_connection.source == 'data_blend'">If there is information missing from the data source, <a :href="'/integrations/'+client.url+'/mapping/data_set/'+data_set_id +'?step=3'">you can click here to map additional columns to that source</a>.</span></p>
                    <div class="w-100 d-flex flex-row gap-3">
                        <div class="w-50">
                            <label>Destination Object</label>
                            <select class="form-select"  v-model="local_census_connection.destination_object" @change="changeDestination()" :disabled="local_destination.census_destination_objects.length==1 || local_census_connection.id > 0"
                                :class="{'is-invalid' : errors.destination_object != ''}">
                                <option v-for="obj in local_destination.census_destination_objects" :value="obj.full_name" v-if="obj.data == null">{{obj.label}}</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.destination_object != ''">
                                {{errors.destination_object}}
                            </div>
                        </div>
                        <div v-if="operations.length > 1" class="w-50">
                            <label>Method of Sync</label>
                            <select class="form-select" v-model="local_census_connection.operation"
                                :class="{'is-invalid' : errors.operation != ''}" :disabled="local_census_connection.id > 0" >
                                <option v-for="ops in operations" :value="ops" >{{getOperationExplanation(ops)}}</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.operation != ''">
                                {{errors.operation}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row px-2 mb-3" v-if="local_census_connection.destination_object != null && !isFile() ">
            <div class="card box-content">
                <div class="card-body">
                    <pathways-column-mapping :client="client" :source="local_census_connection"
                    :destination="local_destination" :dest_columns="destination_fields" @refresh="refreshColumns()" @prioritize="updatePrioritization"
                    :source_columns="source_fields" v-model="local_census_connection.mappings"
                    ref="column_mapping"></pathways-column-mapping>

                    <button class="btn btn-secondary mt-3" v-on:click="addColumnMapping()"  v-if="local_census_connection.destination_object != null && !isFile()" :disabled="busy==true">
                        <i class="fas fa-plus"></i> Add Column Mapping
                    </button>
                </div>
            </div>
        </div>
        <div class="row px-2 mb-3" v-else-if="isFile() ">
            <div class="card box-content">
                <div class="card-body">
                    <file-column-mapping :client="client" :source="local_census_connection"
                        :source_columns="source_fields" :destination="local_destination"
                        v-model="local_census_connection.mappings" @prioritize="updatePrioritization"
                        ref="file_mapping"></file-column-mapping>
                </div>
            </div>
        </div>

        <div class="alert alert-danger mb-3" v-if="errors.form">
            There was an issue saving this pathway mapping. Check the fields above to get more information.
        </div>

        <div class="row">
            <!-- Save Button -->
            <div class="mb-3 d-flex flex-row gap-2">
                <button type="submit" class="btn btn-primary save-button" @click="save()" :disabled="busy==true">
                    <span v-if="busy"><div class="spinner-border  spinner-border-sm float-left" role="status"> <span class="visually-hidden">Saving...</span></div> Saving</span><span v-else><i class="fa fa-btn fa-save"></i> Save</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

        props: ['client', 'destination', 'census_connection', 'source_fields'],
        data() {
            return {
                local_census_connection: null,
                local_destination: null,
                operations: [],
                destination_fields: [],
                errors:{
                    destination_object: "",
                    operation: "",
                    form: false
                },
                busy: false
            };
        },
        beforeMount() {
            this.local_census_connection = this.census_connection;
            this.local_destination = this.destination;

            //Set the column options if the destination has been saved already
            if(this.local_census_connection.destination_object != null && !this.isFile()){
                for(var i = 0; i < this.destination.census_destination_objects.length; i++) {
                    var obj = this.destination.census_destination_objects[i];
                    if(obj.full_name == this.local_census_connection.destination_object){
                        this.operations = obj.supported_operations;
                        this.destination_fields = obj.fields;
                        break;
                    }
                }
            }
            //If there is only one option
            else if(!this.isFile() && this.destination.census_destination_objects.length == 1){
                this.local_census_connection.destination_object = this.local_destination.census_destination_objects[0].full_name;
                this.destination_fields = this.destination.census_destination_objects[0].fields;
                this.operations = this.destination.census_destination_objects[0].supported_operations;
                this.changeDestination();
            }

            if(!this.isFile() && (this.local_destination.census_destination_objects == null || this.local_destination.census_destination_objects.length == 0)) {
                this.getDestinationObjects();
            }

        },
        computed: {
            data_set_id () {
                if(this.census_connection.source == 'data_blend') {
                    //Loop through the primary data sets and find the one with source_subtype of constituents
                    for(var i = 0; i < this.client.primary_data_sets.length; i++) {
                        if(this.client.primary_data_sets[i].source_subtype == 'constituents')
                            return this.client.primary_data_sets[i].id;
                    }
                }

                return this.census_connection.data_source_id;
            },
        },
        methods: {

            isFile() {
                return this.local_destination.integration_id == "71b0c3b0-8390-49ec-9984-1c04b7384d38";
            },
            getOperationExplanation(ops) {
                if(ops == 'mirror')
                    return "Mirror - Records will added, updated, and removed to keep the sources in full sync"
                if(ops == 'append')
                    return 'Append - New records will be added to the destination but existing will not be updated or removed';
                if(ops == 'upsert')
                    return "Update or Create - Update existing destination records when IDs match, otherwise create new ones if they're missing"
                if(ops == 'update')
                    return "Update Only - Matching records will be updated but nothing will be added or removed"
                if(ops == 'delete')
                    return "Delete - Delete syncs takes the list of provided IDs and deletes them from the destination. Be careful! Providing an incorrect set of IDs can result in data loss!"
                if(ops == 'create' || ops == 'insert')
                    return "Create Only - Create a new object if it doesn't exist in the destination"
            },
            changeDestination() {

                for(var i = 0; i < this.local_destination.census_destination_objects.length; i++) {
                    var obj = this.local_destination.census_destination_objects[i];
                    if(obj.full_name == this.local_census_connection.destination_object){
                        this.operations = obj.supported_operations;
                        this.destination_fields = obj.fields;
                        break;
                    }
                }

                //Now update the operation on the local census connection
                if(this.operations == null){
                    this.local_census_connection.operation = null;
                    this.operations = [];
                    this.destination_fields = [];
                }

                //Set the operation to the first one if it isn't already set
                else if(this.local_census_connection.operation == null){
                    this.local_census_connection.operation = this.operations[0];
                }
                //If this.local_census_connection.operation is set and not in the operation array
                else if(this.local_census_connection.operation != null && !this.operations.includes(this.local_census_connection.operation)) {
                    this.local_census_connection.operation = this.operations[0];
                }

                //Update the pathway mapping vue to remove columns that don't exist once they select a destination
                if(this.local_census_connection.destination_object != null && this.local_census_connection.mappings != null) {
                    for(var i = this.local_census_connection.mappings.length-1; i >= 0; i--) {
                        let found = false;
                        for(var j = 0; j < this.destination_fields.length; j++) {
                        //If the [j].full_name is not in any of the destination columns
                            if(this.destination_fields[j].full_name == this.local_census_connection.mappings[i].destination_column) {
                                found = true;
                                break;
                            }
                        }
                        //If I didn't find the mapping, remove it
                        if(!found)
                            this.local_census_connection.mappings.splice(i, 1);
                    }
                }

                if(this.$refs.column_mapping != null)
                    this.$refs.column_mapping.resetMapping();
            },
            updatePrioritization(priority) {
                this.local_census_connection.prioritization_column = priority;
            },
            getDestinationObjects() {
                var form_data = {
                    client_id: this.client.id,
                    connection_id: this.local_destination.id
                }
                window.axios.post('/api/census/get_destination_objects', form_data)
                  .then(response => {
                    //Now I need to set a timeout to check the destination to see when it changes
                    if(response.data.objects == null || response.data.objects.length == 0)
                        setTimeout(this.getDestinationObjects, 500);
                    else
                        this.local_destination.census_destination_objects = response.data.objects;

                  });
            },
            refreshColumns() {
                var form_data = {
                    client_id: this.client.id,
                    connection_id: this.local_destination.id,
                    destination_object: this.local_census_connection.destination_object
                }
                window.axios.post('/api/census/refresh_destination', form_data)
                  .then(response => {
                    //Now I need to set a timeout to check the destination to see when it changes
                    Swal.fire({
                        title: "Processing...",
                        html: "The refresh process has begun. Once it has finished, the columns options below will be updated.",
                        iconColor:  "#46705F",
                        confirmButtonColor: "#46705F"
                    }).then((result) => {

                        //Now I need to set a timeout to check the destination to see when it changes
                        setTimeout(function(scope) {
                            window.axios.get('/api/connection/' + scope.local_destination.id).then(response => {
                                if(response.data.connection != scope.local_destination){
                                    scope.local_destination = response.data.connection;
                                    for(var i = 0; i < scope.local_destination.census_destination_objects.length; i++) {
                                        var obj = scope.local_destination.census_destination_objects[i];
                                        if(obj.full_name == scope.local_census_connection.destination_object){
                                            scope.destination_fields = obj.fields;
                                            break;
                                        }
                                    }
                                }
                            });
                        }, 5000, this);
                    });
                  });
            },
            validateCensusConnection() {
                this.errors.operation = "";
                this.errors.destination_object = "";

                let is_valid = true;

                if(this.local_census_connection.operation == null || this.local_census_connection.operation == ''){
                    this.errors.operation = 'The method of sync is a required field';
                    is_valid = false;
                }
                if(this.local_census_connection.destination_object == null || this.local_census_connection.destination_object == '') {
                    is_valid = false;
                    this.errors.destination_object = 'The destination object is a required field';
                }

                return is_valid;
            },
            addColumnMapping(){
                this.local_census_connection.mappings.push({
                    id: -1 * Math.random(),
                    client_id: this.client.id,
                    census_connection_id: this.local_census_connection.id,
                    source_column: this.getBlankSourceColumn(),
                    destination_column: null,
                    is_primary_identifier: false,
                });
            },
            getBlankSourceColumn() {
                return {
                    main_category: 'Columns',
                    category: null,
                    type: 'string',
                    value: null,
                    text: ""
                };
            },
            getModelId() {
                let urlString = window.location.href;
                // Parse the URL
                const url = new URL(urlString);
                // Split the pathname part of the URL on '/', filter out empty segments
                const segments = url.pathname.split('/').filter(segment => segment !== '');
                // Get the seventh segment (the model ID) if there is one
                return segments.length == 7 ? segments[segments.length - 1] : null;
            },
            save() {
                var is_valid = true;
                if(this.isFile()){
                    this.local_census_connection.operation = 'create';
                    this.local_census_connection.destination_object = 'file';

                    if(this.$refs.file_mapping != undefined && !this.$refs.file_mapping.isValid())
                        is_valid = false;
                }
                else {
                    //Validate the form and the mapping
                    is_valid = this.validateCensusConnection();
                    if(this.$refs.column_mapping != undefined && !this.$refs.column_mapping.isValid())
                        is_valid = false;
                }

                if(!is_valid)
                    this.errors.form = true;
                else {
                    this.errors.form = false;
                    var _self = this;
                    this.busy = true;

                    let model_id = this.getModelId();

                    if(this.local_census_connection.id == null) {

                        window.axios.post('/api/census_connection', this.local_census_connection)
                          .then(response => {
                            this.busy = false;
                            Swal.fire({
                                title: "Success!",
                                html: "The columns have been successfully mapped. You'll be redirected to the Pathways page momentarily and can select the segments to keep in sync moving forward.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            }).then((result) => {
                                if (document.referrer && document.referrer.includes('pathways'))
                                    window.location.href = document.referrer;
                                else
                                    window.location = "/pathways/"+_self.client.url+"/push_segment";
                            });
                          });
                      }
                      //If there URL has seven folders
                      else if(model_id != null){
                        //if it is a column mapping just for a model
                        window.axios.put('/api/census_model/' + model_id, this.local_census_connection)
                          .then(response => {
                            this.busy = false;
                            Swal.fire({
                                title: "Success!",
                                html: "Your settings have been successfully updated. You'll be redirected to the Pathways page momentarily to continue selecting segments to keep in sync.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            }).then((result) => {
                                //Redirect back to the previous page and refresh it
                                if (document.referrer && document.referrer.includes('pathways'))
                                    window.location.href = document.referrer;
                                else
                                    window.location = "/pathways/"+_self.client.url+"/push_segment";
                            });
                          });
                      }

                      //Just update the old filter
                      else {
                        this.local_census_connection.save_mapping = 1;
                        window.axios.put('/api/census_connection/' + this.local_census_connection.id, this.local_census_connection)
                          .then(response => {
                            this.busy = false;
                            Swal.fire({
                                title: "Success!",
                                html: "Your settings have been successfully updated. You'll be redirected to the Pathways page momentarily to continue selecting segments to keep in sync.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            }).then((result) => {
                                if (document.referrer && document.referrer.includes('pathways'))
                                    window.location.href = document.referrer;
                                else
                                    window.location = "/pathways/"+_self.client.url+"/push_segment";
                            });
                          });
                      }
                }
            }
        }
    }
</script>
