<template>
    <div class="datasets" id="datablends" >
        <h4>Data Blends</h4>
        <p>A "data blend" is a unique view of your data that allows Avid AI to combine information from multiple sources. These data sources are what power the analysis done within Avid AI and can also serve as a source of data to push to your marketing and advertising tools.</p>

        <div class="overflow-table">
            <table class="table table-responsive border rounded table-with-pagination">
                <thead class="table-header-row">
                    <tr>
                        <th class="align-middle ">Data Blend Type</th>
                        <th class="align-middle">Status</th>
                        <th class="align-middle">Segments / Messages</th>
                        <th class="align-middle d-none d-md-table-cell">Last Refreshed</th>
                        <th class="align-middle">Actions</th>
                    </tr>
                </thead>
                <tbody >
                    <tr v-for="(set, index) in local_datablends">

                        <td class="align-middle">
                            {{set.type | propercase}}
                        </td>
                        <td class="align-middle">
                            <span v-if="set.last_sync && set.last_sync.status=='failed'" class="badge badge-danger">Error</span>
                            <span class="badge badge-primary" v-else-if="set.last_sync && set.last_sync.status=='processing'" >Processing</span>
                            <span class="badge badge-secondary" v-else-if="set.last_sync==null && set.type.indexOf('benchmark') == -1" >Initializing</span>
                            <span class="badge badge-success" v-else>Active</span>
                        </td>
                        <td v-if="set.last_sync && set.last_sync.status=='failed'" class="align-middle">
                            The last attempt to compile this Data Blend failed, likely due to a mapping issue. Return to your system under Connections>View Connections>Inclusion Filters or Column Mappings. The errored column(s) will be highlighted in red.
                        </td>
                        <td class="align-middle" v-else>
                            <div class="badge badge-secondary with-actions me-2 my-1 p-2" v-for="seg in set.segments"
                                @mouseover="setHover(set.id, seg.id, true)"
                                @mouseleave="setHover(set.id, seg.id, false)"
                                :class="(hover[set.id + '-' + seg.id]==true ? 'hover' : '')">
                                {{seg.name}}
                                <transition name="fade-slow">
                                    <div class="actions" v-if="hover[set.id + '-' + seg.id]==true">
                                        <a class="btn btn-xs btn-link p-0 px-1 text-white" title="Edit Segment"
                                            :href="'/segment/' + client.url +'/' + seg.id" ><i class="fas fa-edit"></i></a>
                                        <button class="btn btn-xs btn-link p-0 px-1" title="Delete Segment"
                                            v-on:click.prevent="deleteSegment(seg)"><i class="fas fa-trash"></i></button>
                                    </div>
                                </transition>


                            </div>
                        </td>

                        <td class="align-middle d-none d-md-table-cell">{{ set.last_processed_at | date }}</td>
                        <td class="align-middle text-end" style="min-width: 118px;" v-if="set.type.indexOf('benchmark') == -1">
                            <a class="btn btn-none" :href="'/segment/'+client.url+'/new?datablend='+local_datablends[index].id">
                                <img src="/img/icons/dialexa-icons/plus.svg" data-bs-toggle="tooltip" title="Add Segment" class="icon"></a>
                            <a class="btn btn-none" data-bs-toggle="tooltip"
                                title="Edit Data Blend" :href="'/integrations/'+client.url+'/data_blend/'+local_datablends[index].id">
                                <img src="/img/icons/dialexa-icons/edit.svg" class="icon">
                            </a>
                            <button class="btn btn-none me-0" data-bs-toggle="tooltip"
                                title="Force Rebuild Data Blend"
                                @click="refreshDataBlend(local_datablends[index].id)">
                                <img src="/img/icons/dialexa-icons/sync.svg" class="icon"></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <button class="btn btn-secondary mb-3" @click="clearCache()">Clear Client Cache</button>
        <transition name="slide">
            <div class="notification success" v-if="notification_message != ''">
                <span v-html="notification_message"></span>
                <a class='btn' v-on:click="notification_message=''"><i class="fas fa-times-circle"></i></a>
            </div>
        </transition>
    </div>
</template>

<script>

    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';
    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal, Datepicker
        },

        props: ['datablends', 'client'],
        data() {
            return {
                local_datablends: [],
                hover: [],
                source_type: 'data_blend',
                seg_id: -1,
                form: {
                    saving: false,
                    error: false
                },
                errors :{
                    name: "",
                    value: ""
                },
                segment: {
                    name: '',
                    source: '',
                    data_source_id: null,
                    filter: null
                },
                notification_message: ""
            };
        },
        filters: {
            date: function (value) {
                if(value == null) return ""
                return moment.utc(value).local().format('M/DD/YYYY h:mm a');
            },
        },
        computed: {
            show_is_primary() {
                for(var i = 0; i < this.local_datasets.length; i++) {
                    if(!this.local_datasets[i].is_primary) {
                        return true;
                    }
                }
                return false;
            }
        },
        mounted() {
            this.local_datablends = Object.values(this.datablends);
        },
        updated() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {
            setHover(set, seg, flag) {
                this.hover[set + '-' + seg] = flag;
                //Force refresh
                this.$forceUpdate();
            },
            deleteSegment(seg) {
                var self = this;

                //Show a warning
                Swal.fire({
                  title: "Are you sure you want to delete this segment?",
                  text: "The segment will no longer be available for data analysis and any connections with 3rd party systems will also be removed.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/segment/'+self.client.url + '/' + seg.id)
                        .then(response => {
                            //Remove it from the initiative
                            if(seg.source == 'data_set'){
                                for(var i = 0; i < self.local_datasets.length; i++)
                                    for(var j = 0; j < self.local_datasets[i].segments.length; j++)
                                        if(self.local_datasets[i].segments[j].id == seg.id)
                                            self.local_datasets[i].segments.splice(j, 1);
                            }
                            else{
                                for(var i = 0; i < self.local_datablends.length; i++)
                                    for(var j = 0; j < self.local_datablends[i].segments.length; j++)
                                        if(self.local_datablends[i].segments[j].id == seg.id)
                                            self.local_datablends[i].segments.splice(j, 1);
                            }

                            //Tell them we're done
                            Swal.fire({
                                title: "Deleted!",
                                text: "This segment has been removed.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            });
                        });
                  }
                });
            },
            refreshDataBlend(data_blend_id) {
                window.axios.post('/api/data_blend/'+this.client.url + '/' + data_blend_id + "/refresh")
                    .then(response => {
                        //Tell them we're done
                        Swal.fire({
                            title: "The Data is Rebuilding",
                            text: "The data blend will be rebuilt and all segments will be resynced within a few minutes.",
                            icon: "success",
                            iconColor:  "#46705F",
                            confirmButtonColor: "#46705F"
                        });
                    });
                //Update the set.last_sync to be now && set.last_sync.status to be processing
                for(var i = 0; i < this.local_datablends.length; i++){
                    if(this.local_datablends[i].id == data_blend_id){
                        this.local_datablends[i].last_sync = new Date();
                        this.local_datablends[i].last_sync.status = 'processing';
                        this.$forceUpdate();
                        break;
                    }
                }
            },
            filterLogicIsValid() {
                //First reset the errors
                this.errors.value = "";

                if(!this.$refs.filter.isFormValid()){
                    this.errors.filter = "Please enter values for all of the inputs above.";
                    return false;
                }

                return true;

            },
            closeSegmentModal() {
                var myModalEl = document.getElementById('segment')
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();

                this.addNewSegment(null, null);
            },
            clearCache() {
                this.form.saving = true;
                window.axios.get('/api/client/' + this.client.url + "/clear_cache")
                    .then(response => {
                        this.form.saving = false;
                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">Cache Cleared!</h4>',
                            target: '#datablends',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            }
                        });
                    });
            }
        }
    }
</script>
