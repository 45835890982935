<template>
    <div class="view-report p-2 mb-4" id="view-report-page">
        <div class="row mx-0"> 
            <div :class="(is_filter_panel_expanded ? 'has-filter-panel-open' : 'w-100')" class="p-0">
                <div class="row mx-0 p-0"> 
                    <div class="col-8 px-0">
                        <h1>{{ page.name }}</h1> 
                    </div>

                    <div class="col-4 px-0">
                        <button type="button" class="btn btn-primary float-end ms-2 mb-2" v-on:click="toggleFilterPanel()">
                            <img class="icon pe-0" src="/img/icons/dialexa-icons/sort.svg">
                            <span class="ps-1" v-if="!is_filter_panel_expanded">Filters ({{ filter_count }})</span>
                        </button>                    
                        <a :href="'/export/'+page.client.url+'/' + page.url + '/' + local_page.sections[active_tab_index].id + params" target="_blank" class="btn btn-secondary float-end mb-2" id="export-button" >
                            <img class="icon pe-0" src="/img/icons/dialexa-icons/presention-chart.svg">
                            <span class="ps-1" v-if="!is_filter_panel_expanded">Generate Presentation</span>
                        </a>
                    </div>

                    <p class="col-12 px-0">{{ page.description }}</p>
                    <!--<p class="col-12 px-0"><a :href="'/insights/'+page.client.url+'/clearCache'">Clear Cache <em>(Remove this link in production)</em></a></p>-->

                </div>
                 
                <div class="row mt-3 px-2">
                    <div class="card box-content">
                        <div class="card-body">
                            <nav id="report-section-nav" class="navbar navbar-expand secondary-tab-bar p-0 mb-2">
                                <div class="container-fluid px-0 mb-3">
                                    <div class="collapse navbar-collapse" id="filter-navbar">
                                    <ul class="navbar-nav mb-2 mb-lg-0">
                                        <li class="nav-item" v-for="(section, index) in local_page.sections">
                                            <a class="nav-link d-flex flex-nowrap secondary-tab" :class="{'secondary-tab-active': section.is_section_tab_active}" 
                                            href="#" role="button" aria-expanded="false" @click.prevent="setActiveTab(index)" data-bs-toggle="tooltip" :title="section.description">
                                                <h6>{{ section.title }}</h6>
                                                <!--<p>Optional description</p>-->
                                            </a>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </nav>

                            <div v-for="(section, index) in local_page.sections" v-if="section.is_section_tab_active" >
                                <report-view-section :page="local_page" :client="client" :connections="connections" :page_section="section" :is_filter_panel_expanded="is_filter_panel_expanded"
                                :if_refreshing="if_refreshing" :applied_chart_filter="applied_chart_filter" :if_benchmark_report="false" :section_index="index" 
                                @exploreChartInSection="exploreChartInSection"></report-view-section> 
                            </div>                              
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <transition name="slide-fade" appear duration="500">
            <div class="border-start border-secondary" :class="is_filter_panel_expanded ? 'open' : 'hidden'" id="filter-panel">
            <!-- <div class="border-start border-secondary" id="filter-panel"> -->
                <div id="filter-panel--heading" class="p-4 pb-0 mt-2">
                    <h4>Filters</h4>
                    <button type="button" class="btn btn-none float-end" @click="toggleFilterPanel()">
                        <i class="fa-regular fa-circle-xmark"></i>
                    </button>
                </div>
                <hr id="filter-panel-heading-hr" class="mx-4">
                <report-view-filter-panel :page="local_page" :applied_chart_filter="applied_chart_filter" :section_id="section_id" 
                @refreshing="refreshing" @updatePage="updatePage" @removeChartFilter="removeChartFilter"></report-view-filter-panel>
            </div>
        </transition>

        <div id="chart-meta-data" v-if="!if_benchmark_report" @mousedown="startDrag" :style="{ left: x + 'px', top: y + 'px' }">
            <div class="tooltip-container" @mouseenter="adjustTooltipPosition">
                <button class="btn btn-secondary"></button>
                <div class="meta-details">
                    <h4>Report Details</h4>
                    <div v-if="meta_data != null && meta_data._avid_max_display_date != null"><label>Last {{date_type}}Date:</label> {{ (meta_data._avid_max_display_date.date != null ? meta_data._avid_max_display_date.date : meta_data._avid_max_display_date) | date  }}</div>
                    <div v-if="applied_filters.length > 0"><label>Filters:</label></div>
                    <div class="filter-badge-container" v-if="applied_filters.length > 0">
                    <div class="badge badge-primary" :key="filter.filter_id + filter.filter_value"
                        v-for="(filter, index) in applied_filters" v-if="filter.filter_id != -1" v-on:click.prevent="setActiveTab(filter.group_index)">
                        <span v-if="filter.filter_type == 'single_select'">
                            {{filter.filter_label}} = "{{filter.filter_value}}"
                        </span>
                        <span v-if="filter.filter_type == 'multi_select'">
                            {{filter.filter_label}}{{ filter.filter_value.length >1 ? ' ('+filter.filter_value.length+')' : ' = "'+ filter.filter_value[0] +'"'}}
                        </span>
                        <span v-if="filter.filter_type == 'date_range'">
                            {{filter.filter_label}}: {{ dateRangeDisplay(filter.filter_value) }}
                        </span>
                        <span v-if="filter.filter_type == 'month_range'">
                            {{filter.filter_label}}: {{ monthRangeDisplay(filter.filter_value) }}
                        </span>
                    </div>
                    <div class="badge badge-secondary text-dark" :key="filter.filter_id + filter.filter_value"
                        v-for="(filter, index) in applied_filters" v-if="filter.filter_id == -1">
                        <span v-if="filter.filter_type == 'single_select'">
                            {{filter.filter_label}} = "{{filter.filter_value}}"
                        </span>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';

    export default {
        components: {
            Swal
        },
        props: ['page', 'client', 'connections', 'section_id'],
        data() {
            return {
                local_page: {},
                if_benchmark_report: false,
                active_tab_index: 0,
                is_filter_panel_expanded:false,
                filter_count: 0,
                if_refreshing: false,
                applied_chart_filter: {
                    if_chart_filter_applied: false,
                    section_index: null,
                    section_chart_id:null,
                    chart_selected: {},
                    chart_filters:[]
                },
                x: 0,
                y: 0,
                isDragging: false,
                meta_data: null,
                params: ""
            };
        },
        beforeMount() {
            this.local_page = this.page;
            if (this.local_page.data_blend_type.startsWith('benchmark'))
                this.if_benchmark_report = true;

            this.setDefaultFilterValues();
            this.getFilterCount();
            this.loadSectionTab();
            if(!this.if_benchmark_report)
                this.loadMetaData();

        },
        mounted() {
            if (this.section_id>0){
                var index = this.local_page.sections.findIndex(section => section.id == this.section_id);
                if (index >= 0){
                    this.setActiveTab(index);  
                }else {
                    this.setActiveTab(0);  
                }
            }
            if(!this.if_benchmark_report){
                this.setStartPosition();
                window.addEventListener('resize', this.setStartPosition);                
            }

        },
        beforeUnmount() {
            if(!this.if_benchmark_report){
              window.removeEventListener('resize', this.setStartPosition);  
            }

        },
        computed: {
            date_type: function() {
                if(this.page.data_blend_type == 'constituent')
                    return "Transaction ";
                else if(this.page.data_blend_type == 'subscriber')
                    return "Email ";
                return "";
            },
            applied_filters: function() {
                let applied_filters_list = [];


                for (var i = 0; i <this.local_page.dimensions.length; i++){
                    let dim = this.local_page.dimensions[i];

                    //Todo: consolidate the default_value and value checks
                    if( (
                        //If it has a default value
                        dim.default_value && (dim.default_value.length  ||
                        //Or is a date/month range with some values set
                        ((dim.field_type == 'date_range'|| dim.field_type == 'month_range') && (dim.default_value.end_date != null || dim.default_value.start_date != null))))
                        //Or it has a value
                        || (dim.value && (dim.value.length ||
                            //Or is a date/month range with some values set
                            ((dim.field_type == 'date_range'|| dim.field_type == 'month_range') && (dim.value.end_date != null || dim.value.start_date != null)) 
                        ))){
                            if ((dim.default_value && (dim.default_value.length != 1 || dim.default_value[0] != 'Donation')) || 
                            (dim.value && (dim.value.length != 1 || dim.value[0] != 'Donation'))){ // hide is_donation 
                                applied_filters_list.push({
                                    filter_id: dim.id,
                                    filter_label: dim.label,
                                    filter_value: (dim.value != null ? dim.value : dim.default_value),
                                    //If it is year_type, that needs to come from the client, not the dim
                                    filter_default_value: ((typeof dim.column === 'string' || dim.column instanceof String) && dim.column.indexOf('time_period.year_type') !== -1? this.page.client.default_reporting_period : dim.default_value),
                                    filter_column_name: dim.column,
                                    filter_type: dim.field_type,
                                    is_required: dim.is_required
                                })                                    
                            }
                        }
                    }
                return applied_filters_list;
            },
        },
        watch:{
            section_id: {
                handler: function(newVal, oldVal) {
                    if (this.section_id>0){
                        var index = this.local_page.sections.findIndex(section => section.id == this.section_id);
                        if (index >= 0){
                            this.setActiveTab(index);  
                        }else {
                            this.setActiveTab(0);  
                        }
                    }
                },
                // deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
        },
        methods: {
            dateRangeDisplay(value){
                if(value.start_date && value.end_date){
                    return this.$options.filters.date(value.start_date) + ' - ' + this.$options.filters.date(value.end_date);
                } else if(value.start_date){
                    //Apply the date filter to it
                    return 'On or After ' + this.$options.filters.date(value.start_date);
                } else if(value.end_date){
                    return 'Prior to ' + this.$options.filters.date(value.end_date);
                }
            },
            monthRangeDisplay(value){
                if(value.start_date && value.end_date){
                    return this.$options.filters.month(value.start_date) + ' - ' + this.$options.filters.month(value.end_date);
                } else if(value.start_date){
                    //Apply the date filter to it

                    return 'On or After ' + this.$options.filters.month(value.start_date);
                } else if(value.end_date){
                    return 'Prior to ' + this.$options.filters.month(value.end_date);
                }
            },
            setDefaultFilterValues() {
                for (var i = 0; i <this.local_page.dimensions.length; i++){
                    let dim = this.local_page.dimensions[i];

                    //If there is value set...
                    if((dim.field_type != 'date_range' && dim.field_type != 'month_range' && dim.value && dim.value.length != 0) ||
                     ((dim.field_type == 'date_range' || dim.field_type == 'month_range') && dim.value && (dim.value.start_date || dim.value.end_date))) {
                        //Do nothing

                    }
                    //If there is a default value option, set it to that
                    else if((dim.field_type != 'date_range' && dim.field_type != 'month_range' && dim.default_value && dim.default_value.length != 0) || 
                    ((dim.field_type == 'date_range' || dim.field_type == 'month_range') && dim.default_value && (!dim.value || (dim.default_value.start_date && !dim.value.start_date) || (dim.default_value.end_date && !dim.value.end_date))) ) {
                        this.local_page.dimensions[i].value = dim.default_value
                    }
                    //No value or default value, so set it to something blank
                    else {
                        if(dim.field_type == "multi_select")
                            this.local_page.dimensions[i].value =  [];
                        else if(dim.field_type == "single_select")
                            this.local_page.dimensions[i].value = "";
                        else if(dim.field_type == "date_range" || dim.field_type == "month_range")
                            this.local_page.dimensions[i].value = {
                                'start_date': null,
                                'end_date': null
                            }
                    }

                }
            },
            loadMetaData() {
                var data = {
                  client: this.client,
                  blend_type: this.page.data_blend_type
                };

                window.axios.post('/api/bigquery/get_metadata', data)
                  .then(response => {
                   this.meta_data = response.data.metadata;
                });
            },
            loadSectionTab(){
                for(var i=0; i<this.local_page.sections.length; i++){
                    if(i == 0){
                        this.local_page.sections[i].is_section_tab_active = true;
                    } else {
                        this.local_page.sections[i].is_section_tab_active = false;
                    }
                }
            },
            setActiveTab(index){
                for(var i=0; i<this.local_page.sections.length; i++){
                    if(i == index){
                        this.local_page.sections[i].is_section_tab_active = true;
                    } else {
                        this.local_page.sections[i].is_section_tab_active = false;
                    }
                }
                this.active_tab_index = index;
                [...document.querySelectorAll('.chart-template-tooltip')].forEach(t => t.remove()); // remove tooltips from the page before switching tabs. fixes a bug where other tab tooltips could show on another tab.
                
                //Get the current query parameters
                this.params = "";
                if(window.location.search != null)
                    this.params = window.location.search;

                var newURL = '/insights/'+this.client.url +'/' + this.page.url+'/'+this.local_page.sections[index].id + this.params;
                window.history.replaceState({}, {}, newURL);

                //Also append the params to the export buttons
                let export_btn = document.getElementById('export-button');
                if(export_btn)
                    export_btn.href = '/export/'+this.client.url +'/' + this.page.url+'/'+this.local_page.sections[index].id + this.params;

                //Save what section they were looking at
                var data = {
                  client_id: this.client.id,
                  report_page_id: this.page.id,
                  report_page_section_id: this.local_page.sections[this.active_tab_index].id
                };
                window.axios.post('/api/insights/history', data);
       
                this.$forceUpdate();
            },
            toggleFilterPanel(){
                this.is_filter_panel_expanded = !this.is_filter_panel_expanded
            },
            refreshing(if_refreshing){
                this.if_refreshing = if_refreshing;
            },
            getFilterCount(){
                this.filter_count = 0;
                for (var i = 0; i <this.local_page.dimensions.length; i++){
                    let dim = this.local_page.dimensions[i];
                    let value = dim.default_value;

                    if(dim.field_type != 'date_range' && dim.field_type != 'month_range' && dim.value && dim.value.length != 0)
                        value = dim.value;

                    else if((dim.field_type == 'date_range' || dim.field_type == 'month_range') && dim.value)
                        value = dim.value;

                    if((dim.field_type == 'date_range' || dim.field_type == 'month_range') && (value && (value.start_date || value.end_date)))
                        this.filter_count++;
                    else if(dim.field_type != 'date_range' && dim.field_type != 'month_range' && value && value.length != 0 && value[0] != 'Donation'){ // Is_donation filter field will be hidden, exclude it from the count
                        this.filter_count++;
                    }
                }
            },
            removeChartFilter(){
                this.applied_chart_filter = {
                    if_chart_filter_applied: false,
                    section_index: null,
                    section_chart_id:null,
                    chart_selected: {},
                    chart_filters:[]
                }
            },
            updatePage(new_page){
                this.local_page = new_page;
                this.local_page.id = this.page.id;  
                this.getFilterCount();
                // this.loadSectionTab();
                this.setActiveTab(this.active_tab_index);

                if(this.applied_chart_filter.if_chart_filter_applied){
                    var selected_chart_index = this.local_page.sections[this.applied_chart_filter.section_index].charts.findIndex(chart => chart.chart.id == this.applied_chart_filter.chart_selected.id);
                    this.local_page.sections[this.applied_chart_filter.section_index].charts[selected_chart_index].chart = this.applied_chart_filter.chart_selected;
                }

                Swal.fire({
                  html: '<h4 class="mb-0 text-white">Data Refreshed!</h4>', 
                  target: '#view-report-page',
                  icon: 'success',
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 3000,
                  iconColor: 'white',
                  customClass: {
                    popup: 'bg-primary'
                  },
                });
            },
            exploreChartInSection(details){
                if(details.chart_filters.length == 0){
                    // First, we will remove all the filters in dimension list with id == -1
                    this.local_page.dimensions = [...this.local_page.dimensions.filter(d => d.id != -1)];

                    // Then, if the filter is one of the user-defined filter, we will reset the dimensional filter
                    var index_array = []
                    this.applied_chart_filter.chart_selected.group_by.forEach((dimension) =>{
                        var index = this.local_page.dimensions.findIndex(d => d.column == dimension.value);
                        if (index>=0)
                            index_array.push(index);
                    });

                    index_array.forEach((index) => {
                        if(this.local_page.dimensions[index].field_type == 'single_select'){
                            this.local_page.dimensions[index].value = "";
                        }
                        if(this.local_page.dimensions[index].field_type == 'multi_select'){
                            this.local_page.dimensions[index].value = [];   
                        } 
                    })

                    this.applied_chart_filter = {
                        if_chart_filter_applied: false,
                        section_index: null,
                        section_chart_id: null,
                        chart_selected: {},
                        chart_filters:[]
                    }
                } else{
                    var section_chart = this.local_page.sections[details.section_index].charts.find(c=> c.chart.id == details.chart_selected.id);
                    this.applied_chart_filter = {
                        if_chart_filter_applied: true,
                        section_index: details.section_index,
                        section_chart_id: section_chart.id,
                        chart_selected: details.chart_selected,
                        chart_filters: details.chart_filters,
                    }             
                } 
                this.applyChartFilter()
            },
            applyChartFilter(){
                this.if_refreshing = true;

                // Add chart filter
                if(this.applied_chart_filter.if_chart_filter_applied){
                    // check if this is an existing user-defined filter option
                    this.applied_chart_filter.chart_filters.forEach((chart_filter) => {
                        var index_in_dimension = this.local_page.dimensions.findIndex(d => d.column == chart_filter.column);

                        // Case 1: if the filter was not in the dimension list
                        if (index_in_dimension < 0){ // not found, add as a new user-defined filter
                            this.local_page.dimensions.push({
                                id: -1,
                                column: chart_filter.column,
                                default_value: null,
                                field_type:  "single_select",
                                filter_group: null,
                                is_required: 0,
                                label: 'Chart Filter',
                                order: 1,
                                report_page_id: this.local_page.id,
                                value: chart_filter.value
                            })
                        } else {
                            // Case 2: if the filter is one of the user-defined filter        
                            for (var i = 0; i <this.local_page.dimensions.length; i++){
                                if (i == index_in_dimension){
                                    if(this.local_page.dimensions[i].field_type == 'single_select'){
                                        this.local_page.dimensions[i].value = chart_filter.value;
                                    }
                                    if(this.local_page.dimensions[i].field_type == 'multi_select'){
                                        if (this.local_page.dimensions[i].value && this.local_page.dimensions[i].value.length !=0){
                                            if (this.local_page.dimensions[i].value.includes(chart_filter.value)){
                                                this.local_page.dimensions[i].value = [chart_filter.value];
                                            } else {
                                                this.local_page.dimensions[i].value.push(chart_filter.value);
                                            }
                                        } else{
                                            this.local_page.dimensions[i].value = [chart_filter.value];
                                        }
                                    }    
                                }
                            }                             
                        }
                    });
                } 
               
                Swal.fire({
                    html: '<h4 class="mb-0 text-white">Data Refreshing...</h4>', 
                    target: '#view-report-page',
                    icon: 'warning',
                    toast: true,
                    position: 'bottom-right',
                    showConfirmButton: false,
                    timer: 5000,
                    iconColor: 'white',
                    customClass: {
                        popup: 'bg-primary'
                    },
                });
                window.axios.post('/api/insights/page/' + this.local_page.id, this.local_page)
                    .then(response => {
                        var page_temp = this.local_page;
                        // this.local_page = response.data.page;
                        this.local_page.dimensions = page_temp.dimensions
                        this.if_refreshing = false;
                        this.updatePage(response.data.page);
                    }).catch(error=> {

                    });
            },
            setStartPosition() {
              let btn = document.querySelector("#chart-meta-data .btn");
              // Set position to bottom-right corner
              this.x = window.innerWidth - btn.offsetWidth - 40;
              this.y = window.innerHeight - btn.offsetHeight - 60;
            },
            startDrag(event) {
              // Start dragging
              this.isDragging = true;
              const offsetX = event.clientX - this.x;
              const offsetY = event.clientY - this.y;

              const onMouseMove = (moveEvent) => {
                if (this.isDragging) {

                  let btn = document.querySelector("#chart-meta-data .btn");
                  // Calculate potential new positions
                  let newX = moveEvent.clientX - offsetX;
                  let newY = moveEvent.clientY - offsetY;

                  // Ensure the draggable element stays within the viewport or container bounds
                  const circleWidth = btn.offsetWidth;
                  const circleHeight = btn.offsetHeight;

                  // Get container dimensions; here we use window, but it can be any container
                  const containerWidth = window.innerWidth;
                  const containerHeight = window.innerHeight;

                  // Apply horizontal boundaries
                  //72 is width of the side nav
                  if (newX < 72 && containerWidth > 575) {
                    newX = 72;
                  }
                  else if (newX < 0 && containerWidth < 575) {
                    newX = 0;
                  } else if (newX + circleWidth > containerWidth) {
                    newX = containerWidth - circleWidth;
                  }

                  // Apply vertical boundaries
                  if (newY < 0) {
                    newY = 0;
                  } else if (newY + circleHeight > containerHeight) {
                    newY = containerHeight - circleHeight;
                  }

                  // Update positions
                  this.x = newX;
                  this.y = newY;
                }
              };

              const onMouseUp = () => {
                // Stop dragging
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
                this.isDragging = false;
              };

              window.addEventListener('mousemove', onMouseMove);
              window.addEventListener('mouseup', onMouseUp);
            },
            adjustTooltipPosition() {
                const tooltip = document.querySelector("#chart-meta-data .meta-details");
                const btn =  document.querySelector("#chart-meta-data .btn");
                const btnRect = btn.getBoundingClientRect();

                // Calculate space available on the left and right of the button
                const spaceOnLeft = btnRect.left;
                const spaceOnRight = window.innerWidth - btnRect.right;

                // Check if there's enough space to center the tooltip
                if (spaceOnRight >= tooltip.offsetWidth / 2 && spaceOnLeft >= tooltip.offsetWidth / 2) {
                    // Center the tooltip
                    tooltip.style.left = '50%';
                    tooltip.style.right = 'auto';
                    tooltip.style.transform = 'translateX(-50%)';
                } else if (spaceOnRight < tooltip.offsetWidth / 2) {
                    // Not enough space on the right, align the tooltip to the right
                    tooltip.style.right = '0';
                    tooltip.style.left = 'auto';
                    tooltip.style.transform = 'translateX(0)';
                } else {
                    // Not enough space on the left, align the tooltip to the left
                    tooltip.style.left = '0';
                    tooltip.style.right = 'auto';
                    tooltip.style.transform = 'translateX(0)';
                }
            }


        }
    }
</script>
