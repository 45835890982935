<template>
    <div class="insights-dashboard pt-2 mb-4">
        <div class="row mx-2 mb-3">
            <div class="col-6 p-0">
                <h1>Welcome, {{ user.first_name}}!</h1>
                <p>Here are your top Insights for the day.</p>
            </div>
            <div class="col-6 p-0">
                <a :href="'/insights/'+client.url+'/generate_ppt'" class="btn btn-primary float-end mb-2" id="export-button" >
                    <img class="icon pe-0" src="/img/icons/dialexa-icons/presention-chart.svg">
                    <span class="ps-1">Generate Presentation</span>
                </a>    
            </div>
        </div>

        <div class="row insight-dashboard-info">
            <div class="col-sm-4 mb-3 ">
                <div class="card box-content p-3 d-flex flex-row">
                    <div class="flex-shrink-1 insights-dashboard-group-icon mt-2 me-2">
                        <img class="circle-icon bg-tertiary" src="/img/icons/dialexa-icons/clock-tertiary.svg">
                    </div>
                    <div class=" flex-fill  p-1">
                        <h5>Time Period Data</h5>
                        <ul class="mb-0">
                            <li>Time Period: <em>{{client.default_reporting_period}}</em></li>
                            <li >Last Transaction Date: <em><span v-if="meta_data.constituent != null">
                                <span v-if="meta_data.constituent._avid_max_display_date != null && meta_data.constituent._avid_max_display_date.date != null">{{meta_data.constituent._avid_max_display_date.date | date }} </span>
                                <span v-else-if="meta_data.constituent._avid_max_display_date != null">
                                    {{meta_data.constituent._avid_max_display_date | date }} </span>
                                <span v-else-if="meta_data.constituent._max_overall_donation_date != null">
                                    {{meta_data.constituent._max_overall_donation_date.date | date }} </span></span>
                                <span v-else><div class="d-inline-block spinner-border spinner-border-sm ms-2" role="status"><span class="visually-hidden"></span></div> Loading</span></em>

                            </li>
                            <li v-if="meta_data.subscriber != null">Last Email Date: <em>{{ meta_data.subscriber._max_overall_record_date | date }}</em></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 mb-4 ">
                <div class="card box-content p-3 d-flex flex-row">
                    <div class="flex-shrink-1 insights-dashboard-group-icon mt-2 me-2">
                        <img class="circle-icon bg-secondary" src="/img/icons/dialexa-icons/chart-infographic-secondary.svg">
                    </div>
                    <div class=" flex-fill p-1" v-if="history != null && history.length > 0">
                        <h5>Your Most Visited Reports</h5>
                        <ul class="mb-0">
                            <li v-for="h in history">
                                <a :href="'/insights/'+client.url+'/'+ h.url + '/' + h.report_page_section_id">{{ h.name }}: <em>{{ h.title }}</em></a>
                            </li>
                        </ul>
                    </div>
                    <div class=" flex-fill p-1" v-else>
                        <h5>Your Suggested Reports</h5>
                        <ul class="mb-0">
                            <li  v-for="page in report_pages.slice(0, 3)">
                                <a :href="'/insights/'+client.url+'/'+ page.url ">{{ page.name }}</em></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
 
    <!--
        <div class="row mx-2">
            <div class="col-12 p-0">
                <div class="btn-group mb-3 float-end" role="group">
                    <button type="button" class="btn" v-for="t in time_period" @click="current_time_period = t"
                        :class="current_time_period == t ? 'btn-primary' : 'btn-white'">{{t | propercase}}
                    </button>
                </div>                       
            </div>
        </div>
    -->
        <div class="row mx-2 mb-5 top-5-metrics box-content">
            <div class="col-2 p-0">
                <nav class="navbar bg-secondary nav-tab-side flex-column h-100 p-0">
                    <ul class="navbar-nav w-100 flex-column h-100">
                        <li class="nav-item flex-fill d-flex align-items-center justify-content-center" v-for="(metric, index) in insight_metrics" :class="{'active-tab': metric.is_tab_active}">
                            <a class="nav-link w-100 h-100 " :class="(metric.percentage_changed != null? '' : 'd-flex flex-column justify-content-center align-items-center')"  href="#" role="button" aria-expanded="false" v-on:click.prevent="setActiveTab(index)">
                                <h2 v-if="metric.percentage_changed != null">{{ formatValue(metric) }} </h2>
                                <h5 class="border-bottom-1 text-center" v-else >{{ metric.metric_name }} </h5>
                                <div class="d-flex  w-100 mt-1" v-if="metric.percentage_changed != null">
                                    <div class="flex-grow-1 ">{{ metric.metric_name }}</div>
                                    <div ><span class="badge me-0 ms-1" :class="{ 'bg-label-success':( metric.percentage_changed  > 0), 'bg-label-danger': (metric.percentage_changed < 0)}">{{ metric.percentage_changed | percentage_na}}</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>


            <div class="col-10 p-0">
                <div class="card" v-for="(metric, index) in insight_metrics" v-if="metric.is_tab_active" :key="metric.section_id">
                    <div class="card-body" v-if="metric.chart != null">
                        <div class="row">
                            <div class="col-8">
                                <h4><em>{{ metric.section_title }}:</em> {{ metric.chart.title }} <sup><i class="fas fa-question-circle text-primary" data-bs-toggle="tooltip" :title="metric.chart.chart.description" v-if="metric.chart.chart.description != null"></i></sup></h4>
                                <p>{{ metric.chart.subtitle }}</p>
                            </div>
                            <div class="col-4">
                                <a :href="'/insights/'+client.url+'/'+ metric.page_url + '/' + metric.section_id" class="btn btn-secondary float-end"> <img src="/img/icons/dialexa-icons/chart.svg" class="icon me-2">View Insights Details</a>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col mb-3 max-height-800" >
                                <chart-template :chart="metric.chart.chart" :key="'chart' + metric.section_id" ></chart-template>
                            </div>
                        </div>
                        <transition name="fade-slow">
                            <div class="d-flex pink-gradient-left summary pt-3" v-if="metric.summary != null">
                                <div class="primary-chart-insight-icon mt-3 mx-3">
                                    <img class="icon" src="/img/icons/dialexa-icons/flag.svg">
                                </div>
                                <div>
                                    <h5>What does this mean?</h5>
                                    <div v-html="metric.summary"></div>
                                </div>
                            </div>
                            <div v-else class="d-flex pink-gradient-left summary pt-3" >
                                <div class="primary-chart-insight-icon m-3">
                                    <div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div>
                                </div>
                                <div>
                                    <h5>Your data is being analyzed.</h5>
                                    <p>The results will be displayed here once the analysis is complete.</p>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="card-body" v-else  >
                        <div class="asset-overlay">
                            <div class="overlay" style="border-bottom-left-radius: 0; border-top-left-radius:0"></div>
                            <div class="cta">
                                <div class="row d-flex align-items-center mx-3">
                                    <div class="col-2">
                                        <div class="spinner-border text-white" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <h5 class="text-white mt-2">Loading...</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>

        <div class="row mx-2">
            <div v-if="report_pages.length > 0" class="p-0" id="available-reports">
                <h2 class="mb-3">Available Reports</h2>

                <div class="row">
                    <div v-for="(group, index) in page_by_group" class="mb-3" :class="group.class_name">
                        <div class="card box-content report-preview-card">
                            <div class="card-body">
                                <div v-if="group.pages.length>1">
                                    <div v-if="group.icon != null" class="insights-dashboard-group-icon mt-2 me-2 float-start"> <img :src='"/img/icons/dialexa-icons/" + group.icon' class="circle-icon"></div>                                    
                                    
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="pt-2">{{group.group_name}}</h3>


                                            <ul :class="group.pages.length < 5 ? 'single-column': 'double-column'">
                                                <li v-for="(page, index) in group.pages">
                                                    <a :href="'/insights/'+client.url+'/'+ page.url">{{ page.name }}</a>
                                                </li>
                                            </ul>
                                    
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="group.icon != null" class="insights-dashboard-group-icon mt-2 me-2 float-start">
                                        <img v-if="group.class_name!='col-12'" :src='"/img/icons/dialexa-icons/" + group.icon' class="circle-icon">
                                        <div v-else-if="group.pages[0].sections[0].primary_chart != null" class="square-icon" >
                                            <chart-template class="full-width-chart-preview" :chart="group.pages[0].sections[0].primary_chart.chart" :id="'chart'+group.pages[0].sections[0].primary_chart.id"></chart-template>
                                        </div>
                                    </div>
                    
                                    <div class="row">
                                        <div class="col report-preview-card--text">
                                            <h3 class="pt-2"><a :href="'/insights/'+client.url+'/'+ group.pages[0].url" class="text-decoration-none">{{group.pages[0].name}}</a></h3>
                                            <p class="mb-3">{{ group.pages[0].description }}</p>
                                            <div class="wrapper d-flex mb-2">
                                                <div class="report-preview-card--text-preview">
                                                    <div class="border-start-0">
                                                        <span class="h5">{{ group.pages[0].sections.length }}</span>
                                                        <span class="p">{{group.pages[0].sections.length  > 1 ? 'Sections' : 'Section'}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="h5">{{ group.pages[0].charts_count }}</span>
                                                        <span class="p">{{group.pages[0].charts_count  > 1 ? 'Charts' : 'Chart'}}</span>
                                                    </div>
                                                    <div>
                                                        <span class="h5">{{ group.pages[0].insights_count }}</span>
                                                        <span class="p">{{group.pages[0].insights_count > 1 ? 'Insights' : 'Insight'}}</span>
                                                    </div>
                                                </div>
                                                <div class="float-end">
                                                    <a :href="'/insights/'+client.url+'/'+ group.pages[0].url" class="me-3">
                                                        <button class="btn btn-secondary">Explore</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="card box-content mb-3">
                    <div class="card-body mt-3 text-center">
                        <h3 class="text-center">No reports have been created for this client yet.</h3>
                    </div>
                </div>
            </div>     
        </div>
        
    </div>
</template>


<script>
import Swal from 'sweetalert2';
    
    export default {
        components: {Swal},
        props: ['prioritizations', 'report_pages', 'client', 'user', 'history'],
        data() {
            return {
                page_by_group: [],
                time_period:['monthly', 'quarterly', 'yearly', 'fiscal_year'],
                current_time_period: 'monthly',
                insight_metrics:[],
                meta_data: []

            };
        },
        beforeMount() {
            this.loadReportPageByGroupName();
            this.loadPrioritization();
            this.loadMetaData();
        },

        updated() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));


            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {

            loadMetaData() {
                var data = {
                  client: this.client,
                  blend_type: 'constituent'
                };

                window.axios.post('/api/bigquery/get_metadata', data)
                  .then(response => {
                   this.meta_data['constituent'] = response.data.metadata;
                   this.$forceUpdate();

                });

                var data = {
                  client: this.client,
                  blend_type: 'subscriber'
                };

                window.axios.post('/api/bigquery/get_metadata', data)
                  .then(response => {
                   this.meta_data['subscriber'] = response.data.metadata;
                   this.$forceUpdate();

                });
            },
            loadPrimaryChartData(prioritization_index) {
                var data = {
                  section_id: this.prioritizations[prioritization_index].section.id,
                  page_id: this.prioritizations[prioritization_index].section.page.id
                };

                window.axios.post('/api/chart/get_primary_chart', data)
                  .then(response => {
                    //I need to save the section for the takeaways
                    this.prioritizations[prioritization_index].section = response.data.section;
                    this.prioritizations[prioritization_index].section.primary_chart = response.data.chart;
                    this.insight_metrics[prioritization_index].chart = response.data.chart;
                    this.$forceUpdate();
                });
            },
            loadPrioritization() {
                for(var i = 0; i < this.prioritizations.length; i++) {
                    if(this.prioritizations[i].section.display_value_name != null){
                        var metric_name = this.prioritizations[i].section.display_value_name;
                    }
                    else if(this.prioritizations[i].section.display_value_metric != null) {
                        var metric_name = this.prioritizations[i].section.display_value_metric.name;
                        //Remove everything after ()
                        if(metric_name.includes('('))
                            metric_name = metric_name.split('(')[0];
                    }

                    let summary = null;
                    if(this.prioritizations[i].section.summary != null)
                        summary = this.prioritizations[i].section.summary;

                    else{
                        let index = i;
                        setTimeout(() => { this.fetchSummary(index, this.prioritizations[index].section); }, 500)
                    }
                    this.loadPrimaryChartData(i);

                    this.insight_metrics.push(
                    {
                        //TODO: Save this to prioritizations
                        metric_name: metric_name.trim(),
                        page_title: this.prioritizations[i].section.page.name,
                        section_title: this.prioritizations[i].section.title,
                        section_id: this.prioritizations[i].report_page_section_id,
                        page_url: this.prioritizations[i].page_url,
                        value: this.prioritizations[i].display_value,
                        format: (this.prioritizations[i].section.display_value_metric != null) ? this.prioritizations[i].section.display_value_metric.format : null,
                        percentage_changed: this.prioritizations[i].prioritization_value,
                        chart: this.prioritizations[i].section.primary_chart,
                        summary: summary,
                        is_tab_active: (i == 0) ? true : false
                    });
                }
            },

            loadReportPageByGroupName(){
                var page_group_array = [];
                var page_group_name = null;

                var page_in_group_temp = [];
                
                this.report_pages.forEach(page => {
                    var page_temp = this.loadSectionDetail(page);
                    if(page_group_name && page.group_name != page_group_name){
                        page_group_array.push({
                            group_name: page_group_name,
                            pages: page_in_group_temp,
                            icon: (page_in_group_temp[0].group != null) ? page_in_group_temp[0].group.icon : null,
                            class_name: (page_in_group_temp[0].group != null) ? page_in_group_temp[0].group.class_name : 'col-4',
                        });
                        page_group_name = page.group_name;
                        page_in_group_temp = [page];
                    } else {
                        page_group_name = page.group_name;
                        page_in_group_temp.push(page);
                    }
                });


                page_group_array.push({
                    group_name: page_group_name,
                    pages: page_in_group_temp,
                    icon: (page_in_group_temp[0].group != null) ? page_in_group_temp[0].group.icon : null,
                    class_name: (page_in_group_temp[0].group != null) ? page_in_group_temp[0].group.class_name : 'col-sm-4',
                });

                //Loop through the page_group_array
                for(let i = 0; i < page_group_array.length; i++){
                    //if there is only one page, get the chart data
                    if(page_group_array[i].pages.length == 1 && page_group_array[i].class_name=='col-12'){
                        let currentSection = page_group_array[i].pages[0].sections[0];
                        var data = {
                          section_id: currentSection.id,
                          page_id: page_group_array[i].pages[0].id
                        };
                        window.axios.post('/api/chart/get_primary_chart', data)
                          .then(response => {
                            currentSection.primary_chart = response.data.chart;
                            this.$forceUpdate();
                        });

                    }
                }

                this.page_by_group = page_group_array;
                this.loadFullWidthGroupChartPreview();
            },
            loadFullWidthGroupChartPreview(){
                var fullWidthGroup = this.page_by_group.filter(group =>
                    group.class_name == "col-12"
                );
                fullWidthGroup.forEach((group) => {
                    if(group.pages[0].sections[0].charts && group.pages[0].sections[0].charts.length > 0){
                        for(let i = 0; i < group.pages[0].sections[0].charts.length; i++)
                        {
                            if(group.pages[0].sections[0].charts[i].is_primary){
                                var primaryChartForFullWidthGroup = group.pages[0].sections[0].charts[i].chart;
                            }
                        }

                        if(primaryChartForFullWidthGroup == null)
                            return;

                        primaryChartForFullWidthGroup.data_blend= group.pages[0].data_blend;
                        primaryChartForFullWidthGroup.temp_filters = [
                            {
                                'pos': 0,
                                'main_category': 'Temp Filters',
                                'category': '',
                                'text': 'Last 5 Years',
                                'value': 'temp_filter_1',
                                'formula': 'transactions.time_period._periods_back <= 5'
                            },
                            {
                                'pos': 1,
                                'main_category': 'Temp Filters',
                                'category': '',
                                'text': 'Time Period: Closed Fiscal Year',
                                'value': 'temp_filter_2',
                                'formula': "transactions.time_period.year_type = 'Closed Fiscal Year'"
                            }
                        ];
                        window.axios.post('/api/chart/get_data', primaryChartForFullWidthGroup)
                            .then(response => {
                                primaryChartForFullWidthGroup.data = response.data.data;
                                primaryChartForFullWidthGroup.labels = response.data.labels;
                                primaryChartForFullWidthGroup.metric_formats = response.data.formats;

                                var groupIndex = this.page_by_group.findIndex(pageGroup =>
                                    group.group_name == pageGroup.group_name
                                );

                                this.page_by_group[groupIndex].pages[0].sections[0].charts[0].chart= primaryChartForFullWidthGroup;
                                this.$forceUpdate();
                        });
                    }
                });
            },
            loadSectionDetail(page){
                page.charts_count = 0;
                page.chart_preview = {};
                page.insights_count = page.sections.length+3;
                

                page.sections.forEach((section, index)=>{
                    page.charts_count += section.charts.length;

                    if (index == 0){
                        section.charts.forEach((chart) => {
                            if(chart.is_primary){
                                page.chart_preview = chart;
                            }
                        })
                    }
                })
                return page;
            },
            setActiveTab(index){
                this.insight_metrics.forEach((metric, i) =>{
                    if (i == index){
                        metric.is_tab_active = true;
                    } else {
                        metric.is_tab_active = false;
                    }
                });
                this.$forceUpdate();
            },
            formatValue(metric){
                var self = this;
                if(metric.format == 'money' && metric.value < 100000)
                    return self.$options.filters.currency(metric.value);
                if(metric.format == 'money')
                    return self.$options.filters.currency_abbr(metric.value);
                if(metric.format == 'number' && metric.value < 10000)
                    return self.$options.filters.number(metric.value);
                if(metric.format == 'number' )
                    return self.$options.filters.num_abbr(metric.value);
                if(metric.format == 'percent')
                    return self.$options.filters.percentage(metric.value);
            },
            fetchSummary(index, section){
                var form_data = {
                    'section':section
                }

                window.axios.post('/api/insights/section/'+section.id+'/takeaways', form_data)
                .then(response => {
                    //If it made a new summary or new takeaways
                    if(response.data.section.summary != null) {
                        this.insight_metrics[index].summary = response.data.section.summary;
                        this.$forceUpdate();
                    }
                    //It did neither, try again in a few seconds
                    else {
                        section = this.prioritizations[index].section;
                        setTimeout(() => {
                            section.thread_id = response.data.section.thread_id;
                            section.run_id = response.data.section.run_id;
                            this.fetchSummary(index, section);

                        }, 1000);
                    }

                });

            }

        }   
    }
</script>
