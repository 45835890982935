<template>
    <div class=" simplified-query-builder ">
        <p class="d-flex text-row mb-3" v-if="data_filter.logic_units.length > 0" >Match                              
            <select class="form-select w-auto mx-2" v-model="inverted" v-on:change.prevent="setInverted()">
                <option :value="false">All</option>
                <option :value="true">Any</option>
            </select> 
            of the following conditions:
        </p>

        <div class="row">
            <div class="col-12 " v-if="data_filter.logic_units.length > 0" :class="{inverted: inverted, white: is_white}" v-for="(unit, index) in nested_units">
                <div class="row pb-2" v-for="(el, el_index) in unit"  v-bind:class="{ 'is-invalid' : hasLogicUnitError(data_filter.logic_units[el]) && errors.logic_units[el]}">
                    <div class="col-4 pe-0">
                        <div class="d-flex" >
                            <span class="me-2 ms-3 mt-1 logic-operator-text" v-if="el_index>0">{{inverted? "AND " : "OR "}}</span>
                            <span class="me-2 mt-4 d-flex align-items-center " v-else >{{index +1 }}. </span>

                            <div class="w-100 text-center">
                                <label v-if="el_index==0" class="form-label fs-7 m-0">Column Name</label>
                                <v-select :options="db_columns" v-model="data_filter.logic_units[el].db_column"
                                    :searchable="true" class="searchable-select column-display w-100 category"
                                    :selectable="(option) => option.text != ''"
                                    @search="fetchOptions" label="text"  :filterable="false" data-bs-toggle="tooltip" 
                                    :title="getColumnTitle(data_filter.logic_units[el])">

                                    <template #selected-option="{ text, category }">
                                        {{(category != null && category != "" && text!=null && text!="") ? category + ": " : ""}}{{text}}
                                    </template>

                                    <template #open-indicator="{ attributes }">
                                        <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                                    </template>
        
                                    <template #option="{ text, category }">
                                        <div v-if="text == ''" :data-category="category" class="category-header"
                                            v-on:click="return expandCategory(category, null)"
                                            data-isexpanded="false">
                                            {{ category }} <i class="fa-solid fa-caret-right"></i>
                                        </div>
                                        <div v-else class="suboption" :data-subcategory="category"
                                            :class="category==null || category==''? 'show' : ''">
                                            {{ text }}
                                        </div>
                                    </template>

                                    <template #no-options="{ search, searching, loading }">
                                        <div class="suboption show" v-if="is_loading">
                                            <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                                        </div>
                                        <div class="suboption show" v-else>
                                            <em>No results found</em>
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </div>

                    </div> 
                    <div class="col-2 operations pe-0 text-center" >
                        <label v-if="el_index==0" class="form-label fs-7 m-0">Operator</label>
                        <v-select :options="getOperatorOptions(data_filter.logic_units[el])"
                            v-model="data_filter.logic_units[el].operator"
                            :selectable="(option) => option.text != ''" label="text"
                            @input="updateOperator(data_filter.logic_units[el]);">

                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                            </template>

                            <template #option="{ text, category }">
                                <div v-if="text == ''" :data-category="category" class="category-header"
                                v-on:click="return expandCategory(category, null)"
                                :data-isexpanded="false">
                                {{ category }} <i class="fa-solid fa-caret-down"></i>
                                </div>
                                <div v-else class="suboption "
                                    :class="{ show: shouldShowClass(el) }"  
                                    :data-subcategory="category">
                                {{ text }}
                                </div>
                            </template>

                            <template #list-footer="{ search }">
                                <li class="border-top border-primary border-1 text-center" @click.prevent="loadFullOperatorList(el)" v-if="data_filter.logic_units[el].operator_option_type != 'full'">
                                    <a class="btn btn-sm btn-link mb-0"> <i class="fa-solid fa-eye me-2"></i>View All Operators</a>
                                </li>
                            </template>
                        </v-select>
                    </div>
                    <div class="col-5 text-center pe-0 text-center" v-if="shouldShowValues(data_filter.logic_units[el].operator)">
                        <label v-if="el_index==0 && shouldShowValues(data_filter.logic_units[el].operator)" class="form-label fs-7 m-0">Value</label>
                        <v-select v-if="shouldShowSelect(data_filter.logic_units[el])" :id="'id-'+el" :options="getOptions(data_filter.logic_units[el])" v-model="data_filter.logic_units[el].value" 
                            :searchable="true" ref="valueSelect" class="searchable-select column-display vue-multi-select w-100"
                            :selectable="(option) => option.text != ''" @open="setEditingIndex(el)" @close="handleBlur(el)"
                            label="text" @search="debouncedSearchOptions"  :map-keydown="handleEnter" 
                            :filterable="false" multiple >

                            <template #list-header="{ search }">
                                <li class="border-bottom border-primary border-1" @click.prevent="addEntryToValue(el, search)" v-if="search != ''">
                                    <a class="btn btn-link"><i class="fa-solid fa-circle-plus text-primary"></i> Add Entry</a>
                                </li>
                            </template>

                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                            </template>

                            <template #option="{ text, category }">
                                <div v-if="text == ''" :data-category="category" class="category-header"
                                v-on:click="return expandCategory(category, null)"
                                data-isexpanded="false">
                                {{ category }} <i class="fa-solid fa-caret-right"></i>
                                </div>
                                <div v-else class="suboption" :data-subcategory="category"
                                :class="category==null || category==''? 'show' : ''">
                                {{ text }}
                                </div>
                            </template>
                            <template #no-options="{ search, searching, loading }">
                                <div class="suboption show" v-if="is_loading">
                                    <div class="spinner-border  spinner-border-sm text-primary float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                                </div>
                                <div class="suboption show" v-else-if="is_searching">
                                    <div class="spinner-border  spinner-border-sm text-primary float-left" role="status"> <span class="visually-hidden">Searching...</span></div>  Searching
                                </div>
                                <div class="suboption show" v-else>
                                    <em>No results found</em>
                                </div>
                            </template>
                        </v-select>   
                        <div v-else-if="shouldShowDate(data_filter.logic_units[el])" class="d-flex align-items-center input-group">
                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span v-html='typeOfSelection(data_filter.logic_units[el])'></span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a class="dropdown-item" href="#"
                                        v-on:click.prevent="data_filter.logic_units[el].is_static = false; data_filter.logic_units[el].show_options = true; data_filter.logic_units[el].value = '';"
                                        >Date Comparisons</a>
                                </li>
                                <li><a class="dropdown-item" href="#"
                                v-on:click.prevent="data_filter.logic_units[el].is_static = true; data_filter.logic_units[el].show_options = false; data_filter.logic_units[el].value = '';"
                                >Static Value</a></li>
                            </ul>
                            <!-- STATIC WITH DATE FORMATTING -->
                            <datepicker v-model="data_filter.logic_units[el].value" input-class="form-control date" placeholder='MM/DD/YYYY'
                                :bootstrap-styling="false" :use-utc="true" format="M/d/yyyy"
                                v-if="data_filter.logic_units[el].is_static  && data_filter.logic_units[el].show_options == false" />
                            
                            <!-- SELECT FROM DATE COMPARISONS -->
                            <input type="number" class="form-control date_num ps-2"
                                v-bind:value="dateNumCalc(data_filter.logic_units[el].value)"
                                v-bind:data-index="el" placeholder="###"
                                v-on:change="updateDateValue(el, data_filter.logic_units[el]);"
                                v-if="!data_filter.logic_units[el].is_static && data_filter.logic_units[el].show_options">

                            <select class="form-select date_comparison"
                                v-bind:value="dateComparisonCalc(data_filter.logic_units[el].value)"
                                v-bind:data-index="el"
                                v-on:change="updateDateValue(el, data_filter.logic_units[el]);"
                                v-if="!data_filter.logic_units[el].is_static && data_filter.logic_units[el].show_options">
                                <option value="day">Days Ago</option>
                                <option value="week">Weeks Ago</option>
                                <option value="month">Months Ago</option>
                                <option value="year">Years Ago</option>
                            </select>
                        </div>
                        <input class="form-control" v-else v-model="data_filter.logic_units[el].value" > 
                    </div>
                    <div class="col-1">
                        <div class="d-flex"> 
                            <button class="btn btn-none btn-sm p-1" :class="el_index==0? 'mt-4' : ''" v-on:click="removeLogicUnit(el)">
                                <img class="icon p-0" src="/img/icons/dialexa-icons/trash.svg"> 
                            </button>
                        </div>
                    </div>
                
                    <div class="pb-2 invalid-feedback col-12" v-if="hasLogicUnitError(data_filter.logic_units[el]) && errors.logic_units[el]">
                        All of the fields must be completed.
                    </div>
                </div>
                <div>
                    <button class="btn btn-secondary btn-sm ms-3" v-on:click="addLogicUnit(index)"><i class="fas fa-plus"></i> {{inverted? "AND" : "OR"}}</button>


                </div>
                    <!-- <button class="btn btn-secondary btn-sm shadow-sm" v-on:click="addLogicUnit(index)"><i class="fas fa-plus"></i> {{inverted? "AND" : "OR"}}</button> -->
            </div>
        </div>
    
        <div class="add-condition d-flex align-items-center mb-3 mt-3">
            <button class="btn btn-secondary me-3" v-on:click="addLogicUnit(-1)">
                <i class="fas fa-plus"></i> Add Condition
            </button>
            <div class="form-check" v-if="data_filter.logic_units.length > 0" >
                <label class="form-check-label d-flex align-items-center">
                <input class="form-check-input" type="checkbox" value="LOWER" v-model="data_filter.logic_units_modification" @change="updateModification()">
                <small class="ms-2 mt-1">Treat upper and lower case letters as the same (case-insensitive)</small>
                </label>
            </div>
        </div>
    </div>
    
    </template>
    
    <script>
        import debounce from 'lodash.debounce';
        import moment from 'moment';
        import vSelect from "vue-select";
        import Datepicker from 'vuejs-datepicker';
    
        export default {
            props: {
                value: {
                  type: Object,
                },
                client: {
                    type: Object,
                    required: true
                },
                source: {default: 'client_db' },
                data_source_id: {default: null },
                static_db_columns: {default: null },
                include_trans: {default: false },
                is_white: {default: true },
                add_blank: {default: true }
    
            },
            components: {
                vSelect,
                Datepicker
            },
            data() {
                return {
                    db_columns: [],
                    unfiltered_db_columns: [],
                    db_column_values: [],
                    unfiltered_db_column_values: [],
                    db_search: [],
                    debouncedSearchOptions: null,
                    editing_index: null,
                    errors: {
                        logic_units: [ false ]
                    },
                    data_filter: {},
                    inverted: false,
                    is_loading: false,
                    is_searching: false,
                    operator_options: []
                };
            },
            created() {
                this.debouncedSearchOptions = debounce(this.searchOptions, 500);
            },
            beforeMount() {
                if(this.value != null){
                    //KEVIN HERE
                    this.data_filter = JSON.parse(JSON.stringify(this.value));

                    if(this.data_filter.logic_units.length ==0 && this.add_blank){
                        this.data_filter.logic_units.push({
                            db_column:{ value: "", text: "", category: ""},
                            data_type:'string',
                            operator: { value: "" , text: "", category: ""},
                            is_static: true,
                            show_options: false,
                            value:[],
                            modification: this.data_filter.logic_units_modification,
                            operator_option_type: 'short'
                        });     
                        this.data_filter.filter_logic = '1';      
                    }
                    //If filter logic has been removed...
                    else if(this.data_filter.filter_logic == null || this.data_filter.filter_logic == '') {
                        this.data_filter.filter_logic = '';
                        for(var i = 0; i < this.data_filter.logic_units.length; i++)
                            this.data_filter.filter_logic += (i+1) + " AND ";
                        //Remove the last and
                        if(this.data_filter.filter_logic.length > 5)
                            this.data_filter.filter_logic = this.data_filter.filter_logic.substring(0, this.data_filter.filter_logic.length - 5);
                    }

                }   
                else
                    this.data_filter = this.getBlankDataFilter();
    
                this.data_filter.filter_ui = "simple";
                this.data_filter.filter_option = 'custom';
                this.inverted = this.isInverted(this.data_filter.filter_logic);
                this.operator_options = [
                    { category: "Common Operators", value: '', text: '' },
                    { category: "Common Operators", value: '=', text: 'Equals' },
                    { category: "Common Operators", value: '<>', text: 'Does Not Equal' },
                    { category: "Common Operators", value: 'IS NULL', text: 'Is Blank' },
                    { category: "Common Operators", value: 'IS NOT NULL', text: 'Is Not Blank' },

                    { category: "Text Operators", value: '', text: '' },
                    { category: "Text Operators", value: 'LIKE', text: 'Contains' },
                    { category: "Text Operators", value: 'NOT LIKE', text: 'Does Not Contain' },
                    { category: 'Text Operators', value:"REGEXP_CONTAINS", text: 'Contains (Regex)' },
                    { category: 'Text Operators', value:"NOT REGEXP_CONTAINS", text: 'Does Not Contain (Regex)' },

                    { category: "Number Operators", value: '', text: '' },
                    { category: "Number Operators", value: '>', text: 'Greater Than' },
                    { category: "Number Operators", value: '>=', text: 'Greater Than or Equal To' },
                    { category: "Number Operators", value: '<', text: 'Less Than' },
                    { category: "Number Operators", value: '<=', text: 'Less Than or Equal To' },

                    { category: "Date Operators", value: '', text: '' },
                    { category: "Date Operators", value: '>', text: 'Is After' },
                    { category: "Date Operators", value: '>=', text: 'Is On or After' },
                    { category: "Date Operators", value: '<', text: 'Is Before' },
                    { category: "Date Operators", value: '<=', text: 'Is On or Before' },
                ];

                //Update the operator to be an object if it already isn't one
                var nested_units = this.nested_units;
                if(nested_units != null)
                    for(var i = 0; i< nested_units.length; i++) {
                        for(var j = 0; j < nested_units[i].length; j++) {
                            if(this.data_filter.logic_units.length > 0 && this.data_filter.logic_units[nested_units[i][j]] && typeof this.data_filter.logic_units[nested_units[i][j]].operator == 'string' ){
                                var op = this.data_filter.logic_units[nested_units[i][j]].operator;
    
                                //Find the op in the operator_options array for the value
                                for(var k = 0; k < this.operator_options.length; k++) {
                                    if(this.operator_options[k].value == op) {
                                        op = this.operator_options[k];
                                        break;
                                    }
                                }
    
                                this.data_filter.logic_units[nested_units[i][j]].operator = op;
                            }
                        }
                    }
            },
            mounted() {
                this.loadDropdownColumns();
            },
            computed: {
                nested_units() {
                    //In this case, OR is the outer unit
                    var split = "AND";
                    if(this.inverted)
                        split = "OR";
    
                    var logic = this.data_filter.filter_logic;

                    if((logic == null || logic == '') &&  this.data_filter.logic_units.length == 0)
                        return null;
                    else if(logic == null)
                        logic = '1';
                    
                    logic = logic.replaceAll("(", "").replaceAll(")", "");
                    var units = logic.split(split).filter(Boolean);
                    //Write a statement to remove all ( and ) from the string

                    var arr = [];
                    for(var i = 0; i < units.length; i++) {
                        arr.push( units[i].split(this.inverted?"AND":"OR").map(function(item) {
                          return parseInt(item.trim())-1;
                        }));
                    }
                    return arr;

                }
            },
            watch: {
                value(n, o) {
                    if(n != null){
                        this.data_filter = n;
                        if(this.data_filter.logic_units.length ==0 && this.add_blank){
                            this.data_filter.logic_units.push({
                                db_column:{ value: "", text: "", category: ""},
                                data_type:'string',
                                operator: { value: "" , text: "", category: ""},
                                is_static: true,
                                show_options: false,
                                value:[],
                                modification: this.data_filter.logic_units_modification,
                                operator_option_type: 'short'
                            });                        
                        }
                    } else{
                        this.data_filter = this.getBlankDataFilter();
                    }
                        
                    this.data_filter.filter_ui = "simple";
                },
                data_filter: {
                    //Data filter watcher
                    handler(n, o) {
                        this.$emit('input', this.data_filter);
                        for(var i = 0; i < this.data_filter.logic_units.length; i++)
                            if(this.data_filter.logic_units[i].db_column != null &&
                                this.db_column_values[this.data_filter.logic_units[i].db_column.value] == null)
                                this.loadDatabaseValues(this.data_filter.logic_units[i].db_column.value);
                    },
                    deep: true
                },
                source(old_pv, new_pv) {
                    this.loadDropdownColumns();
                },
                data_source_id(old_pv, new_pv) {
                    
                    this.loadDropdownColumns();
                }
            },
            methods: {
                shouldShowClass(el) {
                    const options = this.getOperatorOptions(this.data_filter.logic_units[el]);
                    return options.length <= 5;
                },

                getOptions(unit){
                    if(unit.db_column == null || unit.db_column.value == "")
                        return [];
                    else
                        return this.db_column_values[unit.db_column.value];
                },
                getColumnTitle(col){
                    if(col == null || col.db_column == null)
                        return '';
                    if(col.db_column.category)
                        return col.db_column.category + ': ' + col.db_column.text; 
                    return col.db_column.text;
                },
                getOperatorOptions(unit){
                    if(unit == null)
                        return this.operator_options;

                    let type = unit.operator_option_type;
                    if (type == 'full'){
                        return this.operator_options;
                    } else {
                        var data_type = 'string';
                        if(unit.data_type && unit.data_type != 'string')
                            data_type = unit.data_type;

                        else if(unit == null || unit.db_column != null)
                            data_type = this.guessDataType(unit.db_column.text);
                             
                        if(data_type == 'boolean' || data_type == 'bool'){
                            return [
                                { category: "", value: '=', text: 'Equals' },
                                { category: "", value: '<>', text: 'Does Not Equal' },
                                { category: "Common Operators", value: 'IS NULL', text: 'Is Blank' },
                                { category: "Common Operators", value: 'IS NOT NULL', text: 'Is Not Blank' }
                            ];
                        } else if(data_type == 'date' || data_type.indexOf('date') > -1 || data_type.indexOf('time') > -1){
                            return [
                                { category: "", value: '=', text: 'Equals' },
                                { category: "Date Operators", value: '>', text: 'Is After' },
                                { category: "Date Operators", value: '>=', text: 'Is On or After' },
                                { category: "Date Operators", value: '<', text: 'Is Before' },
                                { category: "Date Operators", value: '<=', text: 'Is On or Before' },
                            ];
                        } else if(data_type == 'number' || data_type.indexOf('float') > -1 || data_type.indexOf('int') > -1){
                            return [
                                { category: "", value: '=', text: 'Equals' },
                                { category: "Number Operators", value: '>', text: 'Greater Than' },
                                { category: "Number Operators", value: '>=', text: 'Greater Than or Equal To' },
                                { category: "Number Operators", value: '<', text: 'Less Than' },
                                { category: "Number Operators", value: '<=', text: 'Less Than or Equal To' },
                            ];
                        } else {
                            return [
                                { category: "", value: '=', text: 'Equals' },
                                { category: "", value: '<>', text: 'Does Not Equal' },
                                { category: "Common Operators", value: 'IS NULL', text: 'Is Blank' },
                                { category: "Common Operators", value: 'IS NOT NULL', text: 'Is Not Blank' },
                            ];
                        }
                    }
                },
                guessDataType(col){
                    if(col){
                        col = col.toLowerCase();
                        //If it contains date, dte, utc, time, or timestamp, created, or updated
                        if(col.indexOf('date') > -1 || col.indexOf('dte') > -1 || col.indexOf('utc') > -1 || 
                            col.indexOf('time') > -1 || col.indexOf('timestamp') > -1 || col.indexOf('created') > -1 || 
                            col.indexOf('modified') > -1 || col.indexOf('updated') > -1)
                            return 'date';
                        //If it contains "is " or "has " or "flag"
                        else if(col.indexOf('is ') > -1 || col.indexOf('has ') > -1 || col.indexOf('flag') > -1 
                            || col.indexOf('opt in') > -1 || col.indexOf('opt out') > -1|| col.indexOf('valid') > -1
                            || col.indexOf('can be') > -1 || col.indexOf('can have') > -1)
                            return 'boolean';
                        //If it contains "count" or "sum" or "avg" or "max" or "min" or amt or amount or rev or number
                        else if(col.indexOf('count') > -1 || col.indexOf('day') > -1 || col.indexOf('month') > -1 
                            ||col.indexOf('year') > -1 ||  col.indexOf('sum') > -1 || col.indexOf('avg') > -1 || 
                            col.indexOf('max') > -1 || col.indexOf('min') > -1 || col.indexOf('amt') > -1 || 
                            col.indexOf('amount') > -1 || col.indexOf('rev') > -1 || col.indexOf('giving') > -1 || 
                            col.indexOf('total') > -1 || col.indexOf('number') > -1)
                            return 'number';
                        else
                            return 'string';                        
                    }
                    return 'string';
                },
                loadFullOperatorList(el){
                    this.data_filter.logic_units[el].operator_option_type = 'full';
                    this.$forceUpdate();
                },
                isFormValid() {
                    //Reset the error messages
                    for(var i = 0; i < this.data_filter.logic_units.length; i++)
                        this.errors.logic_units[i] = false;

                    //If there is only one logic unit, and has not column, operator, or value, just remove it.
                    if(this.data_filter.logic_units.length == 1 && 
                        (this.data_filter.logic_units[0].db_column == null || this.data_filter.logic_units[0].db_column.value == "" ||
                        this.data_filter.logic_units[0].operator == null || this.data_filter.logic_units[0].operator.value == "" ||
                        (this.data_filter.logic_units[0].value == null || this.data_filter.logic_units[0].value.length == 0)))
                        this.data_filter.logic_units = [];
    
                    var form_is_valid = true;
                    for(var i = 0; i < this.data_filter.logic_units.length; i++) {
                        this.errors.logic_units[i] = this.hasLogicUnitError(this.data_filter.logic_units[i]);
                        if(this.errors.logic_units[i])
                            form_is_valid = false;
                    }
    
                    if(!form_is_valid)
                        this.$forceUpdate();
    
                    return form_is_valid;
                },
                setInverted() {
                    // console.log(this.inverted);
                    // this.inverted = val;
    
                    //Swap the AND and OR in the logic
                    var logic = this.data_filter.filter_logic;
                    if(logic == null)
                        return;

                    this.data_filter.filter_logic = logic.replace(/\bAND\b|\bOR\b/g, function(match) {
                        return match === "AND" ? "OR" : "AND";
                    });
                    // console.log(this.data_filter.filter_logic);
    
                },
                isInverted(stmt) {
                    if(stmt == null)
                        stmt = '';
    
                    stmt = stmt.toUpperCase().replaceAll("(", " ( ").replaceAll(")", " ) ");
                    const tokens = stmt.match(/[\w()]+|[^\w\s()+-]+/g);
    
                    //If it is only one, return false
                    if(tokens == null || tokens.length == 1)
                        return false;
    
                    //If it starts with a number, then I care about the second one
                    if(/^\d+$/.test(tokens[0]))
                        return tokens[1] === "OR";
    
                    //It starts with a parenthesis, I need to loop through the tokens to find the first logic that isn't within a parenthesis
                    var parensCount = 0;
                    for (let i = 0; i < tokens.length; i++) {
                        const token = tokens[i];
    
                        if (token === "(")
                          parensCount++;
                        else if (token === ")")
                          parensCount--;
                        else if (parensCount === 0)
                            return token === "OR";
                    }
    
                    //Everything is within a paranthesis, so just return the first operator
                    return tokens[2] === "AND";
                },
                updateModification(){
                    this.data_filter.logic_units.forEach(unit => {
                        unit.modification = this.data_filter.logic_units_modification
                    });

                },
                addEntryToValue(unit_index, entry){
                    this.data_filter.logic_units[unit_index].value.push({
                        category:null,
                        text:entry,
                        value:entry
                    })
                    //Clear all search text
                    for(var i = 0; i < this.$refs.valueSelect.length; i++)
                        this.$refs.valueSelect[i].search = '';
                },
                handleEnter: function(map, vm) {
                   var self = this;
                    return {
                        ...map,
                        13: function(e) { //Enter Key
                            
                            e.preventDefault();
                            if (vm.search && vm.search.length > 0) {
                                //Split the ID by - and get the last element
                                let id = vm.$el.id.split('-').pop();
                                self.addEntryToValue(Number(id), vm.search);
                            }
                        }
                    };
                },handleBlur(el) {
                    // Find the v-select component with the corresponding ID
                    const select = this.$refs.valueSelect.find(select => select.$el.id === `id-${el}`);
                    
                    // Check if the select exists and has search text
                    if (select && select.search && select.search.trim().length > 0) {
                        // Add the entry using the existing method
                        this.addEntryToValue(Number(el), select.search.trim());
                    }
                },

                getBlankDataFilter() {
                    var data = {
                        filter_option: "custom",
                        filter_logic: '1',
                        filter_ui: 'simple',
                        logic_units_modification: false,
                        logic_error_msg: "",
                        logic_units: []
                    }
    
                    if(this.add_blank)
                        data.logic_units.push(
                            {
                                db_column:{ value: "", text: "", category: ""},
                                data_type:'string',
                                operator: { value: "" , text: "", category: ""},
                                is_static: true,
                                show_options: false,
                                value:[],
                                modification: this.data_filter.logic_units_modification,
                                operator_option_type: 'short'
                            });
                    return data;
                },
                dateNumCalc(value) {
                    if(value == undefined || moment.isDate(value) || value.indexOf("DATE_SUB") == -1)
                        return value;
                    value = value.replace("DATE_SUB(CURRENT_DATE(), INTERVAL ", "");
    
                    var parts = value.split(" ");
                    return parts[0];
    
                    //num + " " + comparison + ")
                },
                dateComparisonCalc(value) {
                    if(value == undefined || moment.isDate(value) || value.indexOf("DATE_SUB") == -1)
                        return value;
                    value = value.replace("DATE_SUB(CURRENT_DATE(), INTERVAL ", "");
    
                    var parts = value.split(" ");
                    parts[1] = parts[1].replace(")", "");
                    return parts[1];
    
                    //num + " " + comparison + ")
                },
                isDate(data_type) {
                    if(data_type == undefined)
                        return false;
    
                    return data_type.indexOf('date') > -1 || data_type.indexOf('time') > -1;
    
                },
                typeOfSelection(unit) {
                    if(unit.is_static)
                        return '<i class="fa fa-font" ></i>';
                    else if(unit.show_options)
                        return '<i class="fa-solid fa-calendar"></i>';
                    return '';
                },
    
                loadDropdownColumns() {
                    if(this.static_db_columns == null)
                        this.loadDatabaseColumns();
                    else {
                        this.db_columns = this.getFormattedColumns();
                        this.unfiltered_db_columns = JSON.parse(JSON.stringify(this.db_columns));
                    }
                },
                getFormattedColumns() {
                    var columns = [];
                    //Sort the this.src_columns
                    var keys = Object.keys(this.static_db_columns);
                    //sort keys but, if it has a period in the name, it should be lower than works without a period
                    keys.sort(function(a, b) {
                        if(a.indexOf(".") == -1 && b.indexOf(".") != -1)
                            return -1;
                        else if(a.indexOf(".") != -1 && b.indexOf(".") == -1)
                            return 1;
                        else
                            return a.localeCompare(b);
                    });
    
                    var old_category = '';
                    for(var i in keys) {
                        let key = keys[i];
                        let text = this.static_db_columns[key];
                        let category = null;
    
                        //If the key contains a .
                        if(key.indexOf(".") != -1) {
                            //split string by . , separate any structs that are 3+ layers deep with a > in between
                            var split = key.split(".");
                            category = this.$options.filters.propercase(split[0]);
                            text = this.$options.filters.propercase(split.slice(1).join(' > '));
    
                            if(category != old_category)
                                columns.push({
                                    value: "DIVIDER-"+ category,
                                    category: category,
                                    text: ""
                                });
                        }
                        old_category = category;
    
                        columns.push({
                            value: key,
                            text: text,
                            category: category
                        });
                    }
                    return columns;
                },
                loadDatabaseColumns() {
    
                    var _self = this;
                    var data = {
                      client: this.client,
                      include_trans: this.include_trans,
                      source: this.source,
                      data_source_id: this.data_source_id
                    };
                    this.is_loading = true;
                    window.axios.post('/api/bigquery/get_db_columns', data)
                      .then(response => {
                        var cols = response.data.columns.filter((col) => !col.value.startsWith('_')); // filter out all hidden fields start with _
                        var headers = "";
                        for(var i = 0; i < cols.length; i++)
                            if(headers != cols[i].category && cols[i].category != null) {
                                headers = cols[i].category;
                                cols.splice(i, 0, {
                                    value: "DIVIDER-"+ headers,
                                    category: headers,
                                    text: ""
                                });
                            }
                        _self.db_columns = cols;
                        _self.unfiltered_db_columns = JSON.parse(JSON.stringify(cols));
                        _self.is_loading = false;
    
                      });
                },
                shouldShowValues(operator) {
                    //If the logic operator is an object
                    if(operator != null && typeof operator == "object")
                        operator = operator.value;
    
                    return operator != 'IS NULL' && operator != 'IS NOT NULL';
    
                },
                shouldShowSelect(unit) {
                    if(unit == null || unit.operator == null)
                        return false;
                    let should_show = (unit.operator.value=='=' || unit.operator.value=='<>')  && (unit.data_type == 'string' || unit.data_type == 'boolean' || unit.data_type == 'bool');
                    //Make it an array if it was an individually entered item in the textfield
                    let val = unit.value;
                    if (should_show && !Array.isArray(val) && val != null && (typeof val !== "string" || val.trim() != "")) 
                        unit.value = [val];
                    return should_show;
                },
                shouldShowDate(unit) {
                    if(unit == null || unit.data_type == null || unit.operator == null)
                        return false;
                    
                    let is_date = false;
                    if(unit.data_type == 'string')
                        is_date= this.guessDataType(unit.db_column.text) == 'date';
                    else
                        is_date = unit.data_type.indexOf('date') > -1 || unit.data_type.indexOf('time') > -1;

                    //If it is a date and the unit.value  isn't in a date format, set it to a blank string
                    if(is_date && unit.value != null && Array.isArray(unit.value))
                        unit.value = "";

                    return is_date;

                },
                hasLogicUnitError(logic) {
                    //If the logic operator is an object
                    var operator ;
                    if(logic){
                        if(logic.operator != null && typeof logic.operator == "object")
                            operator = logic.operator.value;
        
                        if(logic.db_column == null || operator == null || logic.db_column.value == "" || operator == "" ||
                            ( logic.value == "" && operator.indexOf("NULL") == -1)){
                                return true;
                        }
                            
                        //If the database column is no longer available in the source table
                        if(this.unfiltered_db_columns != null && this.unfiltered_db_columns.length > 0 && !this.unfiltered_db_columns.find(obj => obj.value === logic.db_column.value) ) {
                            logic.db_column.value = "";
                            logic.db_column.text = "";
                            return true;
                        }
                    }

                    return false;
                },
                loadDatabaseValues(db_column) {
    
                    if(db_column == undefined || db_column == null || db_column == "")
                        return;
    
                    var data = {
                      client: this.client,
                      source: this.source,
                      column: db_column,
                      limit: 1000,
                      data_source_id: this.data_source_id,
                      staging_table: (this.static_db_columns != null)
                    };
    
                    var _self = this;
    
                    this.db_column_values[db_column] = [];
                    this.is_loading = true;

                    window.axios.post('/api/bigquery/get_samples', data)
                      .then(response => {
                        //Remove the null value Kevin
                        for(var i = response.data.samples.length - 1; i >= 0; i--)
                            if(response.data.samples[i].name == null)
                                response.data.samples.splice(i, 1);
    
                        response.data.samples.sort((a, b) => (a.name > b.name ) ? 1 : -1);
    
                        if(response.data.samples.length > 0){
                            let value_list = [];
                            response.data.samples.forEach((value) =>{
                                if (value.name && value.name.trim() != ''){
                                    value_list.push({
                                        category: '',
                                        text: value.name, 
                                        value: value.name
                                    })                                    
                                }

                            })
                            _self.db_search[db_column] = (value_list.length >= 999);
                            _self.db_column_values[db_column] = value_list;
                            _self.unfiltered_db_column_values[db_column] = value_list;
                        } else{
                            _self.db_search[db_column] = false
                            _self.db_column_values[db_column] = [
                                {name: '** All values are blank **'}
                            ];
                            _self.unfiltered_db_column_values[db_column] = [
                                {name: '** All values are blank **'}
                            ];                            
                        }

                        _self.is_loading = false;
                        _self.$forceUpdate();
                    });
                },
                addLogicUnit(index) {
                    this.data_filter.logic_units.push(
                        {
                            db_column: { value: "" , text: "", category: ""},
                            data_type:'string',
                            operator: { value: "" , text: "", category: ""},
                            value:[],
                            is_static: true,
                            show_options: false,
                            modification: this.data_filter.logic_units_modification,
                        });

                    this.errors.logic_units.push(false);

                    //Now update the logic statement

                    //Get the nested units;
                    //Loop through and reassemble the logic statement
                    var units = this.nested_units;
                    var stmt = "";

                    if(units != null)
                        for(var i =0; i < units.length; i++){
                            var logic = "";
                            for(var j = 0; j < units[i].length; j++){
                                if(j > 0)
                                    logic += ((this.inverted)? " AND " : " OR ");
                                logic += (units[i][j]+1);
                            }
                            if(index == i)
                                logic += ((this.inverted)? " AND " : " OR ") + this.data_filter.logic_units.length;

                            stmt += "(" + logic + ")"
                            if(i < units.length-1)
                                stmt += ((this.inverted)? " OR " : " AND ");
                        }

                    //If it is the "Add Condition" it will just append to the end
                    if(index == -1)
                        //If this is the first unit
                        if(this.data_filter.logic_units.length == 1)
                            stmt = "1";
                        else
                            stmt += ((this.inverted)? " OR " : " AND ") + this.data_filter.logic_units.length;

                    this.data_filter.filter_logic = stmt;

                },
                removeLogicUnit(index) {
                    var units = this.nested_units;
                    for(var i =0; i < units.length; i++)
                        for(var j = 0; j < units[i].length; j++)
                            if(units[i][j] == index) {
                                units[i].splice(j,1);
    
                                //Remove empty arrays
                                if(units[i].length == 0)
                                    units.splice(i, 1);
    
                                i = units.length;
                                break;
                            }
    
                    this.data_filter.logic_units.splice(index,1);
                    this.errors.logic_units.splice(index,1);
    
                    //If it is empty, just save the logic statement and be done
                    if(this.data_filter.logic_units.length == 0) {
                        this.data_filter.filter_logic = "";
                        return;
                    }
    
                    //Remake the logic
    
                    var stmt = "";
                    for(var i =0; i < units.length; i++){
                        var logic = "";
                        for(var j = 0; j < units[i].length; j++){
                            if(j > 0)
                                logic += ((this.inverted)? " AND " : " OR ");
                            //Need to subtract one from all elements above the removed one to reset the index
                            logic += (units[i][j]+(index > units[i][j] ? 1 : 0));
                        }
    
                        stmt += "(" + logic + ")"
                        if(i < units.length-1)
                            stmt += ((this.inverted)? " OR " : " AND ");
                    }
    
                    this.data_filter.filter_logic = stmt;
    
                    //The showCustomError method will handle the rest
    
                },
                updateOperator(unit) {
    
                    if(unit.operator == null) return;

                    let type = 'string';
                    if(unit.db_column)
                        type = this.guessDataType(unit.db_column.text);
    
                    if(unit.operator.category.indexOf("Number") > -1 || type == 'number'){
                        unit.data_type = 'float';
                        unit.db_column.type = 'float';
                    }
                    else if(unit.operator.category.indexOf("Date") > -1 || type == 'date'){
                        unit.data_type = 'datetime';
                        unit.db_column.type = 'datetime';
                    }
                    else if(unit.db_column.type == 'bool' || type == 'boolean' ){
                        unit.data_type = 'bool';
                        unit.db_column.type = 'bool';
                    }
                    else {
                        unit.data_type = 'string';
                        unit.db_column.type = 'string';
                    }
    
                    if(unit.operator != undefined &&
                        (unit.operator.value == 'REGEXP_CONTAINS' || unit.operator.value.indexOf("IN") >= 0
                            || unit.operator.value == "LIKE" || unit.operator.value.indexOf('NULL') >= 0 )){
                        unit.is_static = true;
                        unit.show_options = false;
    
                        if(typeof unit.value === 'object' && unit.value !== null)
                            unit.value = unit.value.text;

                        if(unit.value === undefined){
                            unit.value  = null;
                        }
                    }
                },
    
                updateDateValue(index, unit) {
                    var num = $(".date_num[data-index='" + index + "']").val();
                    var comparison = $(".date_comparison[data-index='" + index + "']").val();
    
                    unit.value = "DATE_SUB(CURRENT_DATE(), INTERVAL " + num + " " + comparison + ")" ;
                },
                expandCategory(cat, expand) {
                    var headline = document.querySelector("div[data-category='"+cat+"']");
                    var lines = document.querySelectorAll("div[data-subcategory='"+cat+"']");
    
                    if(headline == undefined || lines == undefined)
                        return false;
    
                    if((headline.dataset.isexpanded == "false" || !headline.dataset.isexpanded ) || expand === true) {
                        for(var i = 0; i < lines.length; i++)
                            lines[i].style.display="block";
                        var divs = headline.getElementsByClassName("fa-caret-right");
                        for(var i = 0; i < divs.length; i++){
                            divs[i].classList.add("fa-caret-down");
                            divs[i].classList.remove("fa-caret-right");
                        }
                        headline.dataset.isexpanded = true;
                    }
                    else {
                        for(var i = 0; i < lines.length; i++)
                            lines[i].style.display="none";
                        var divs = headline.getElementsByClassName("fa-caret-down");
                        for(var i = 0; i < divs.length; i++){
                            divs[i].classList.add("fa-caret-right");
                            divs[i].classList.remove("fa-caret-down");
                        }
                        headline.dataset.isexpanded = false;
                    }
    
                    return false;
                },
                setEditingIndex(index){
                    this.editing_index = index;
                },
                searchOptions(search, loading){ 
                    let column_name = this.data_filter.logic_units[this.editing_index].db_column.value;
                    
                    if(!this.db_search[column_name]){
                        this.db_column_values[column_name] = this.unfiltered_db_column_values[column_name].filter((value) => value.text.toLowerCase().includes(search.toLowerCase()));
                        this.is_loading = false;
                        this.$forceUpdate();
                    } else{
                        //Set the "Searching" notification
                        this.is_searching = true;
                        this.db_column_values[column_name] = [];
                        this.$forceUpdate();
                        
                        var data = {
                            client: this.client,
                            source: this.source,
                            column: column_name,
                            limit: 1000,
                            data_source_id: this.data_source_id,
                            staging_table: (this.static_db_columns != null),
                            search: search
                        };

                        window.axios.post('/api/bigquery/get_samples', data)
                        .then(response => {
                            var db_values = [];
                            //Remove the null value
                            for(var i = response.data.samples.length - 1; i >= 0; i--){
                                if(response.data.samples[i].name == null)
                                    response.data.samples.splice(i, 1);
                                else {
                                    db_values.push(response.data.samples[i].name);
                                }
                            }
                            db_values.sort((a, b) => (a.name > b.name ) ? 1 : -1);
                            this.is_searching = false;
                            this.db_column_values[column_name] = db_values;
                            this.$forceUpdate();
                        });
                    }
                },
                fetchOptions(search, loading) {
                    //Reset the array
                    this.db_columns = JSON.parse(JSON.stringify(this.unfiltered_db_columns));
    
                    if(search == "") return;
                    this.is_loading = true;
    
                    //Look at each column
                    for(var i = this.db_columns.length-1; i >= 0 ; i--) {
                        //If the search string isn't in the text or the category
                        if(this.db_columns[i].text.toLowerCase().indexOf(search.toLowerCase()) == -1
                            && (this.db_columns[i].category == null
                            || this.db_columns[i].category.toLowerCase().indexOf(search.toLowerCase()) == -1)
                            && this.db_columns[i].text != "" ) //And not a category divider
    
                            this.db_columns.splice(i, 1);
                    }
    
                    //Get the remaining categories
                    var cats = [];
                    for(var i = 0; i < this.db_columns.length; i++)
                        if(this.db_columns[i].category != null && !cats.includes(this.db_columns[i].category) && this.db_columns[i].text != "")
                            cats.push(this.db_columns[i].category);
    
                    //Expand the categories
                    for(var i = 0; i < cats.length; i++)
                        this.expandCategory(cats[i], true);
    
                    //Remove a category if it isn't in the array of categories
                    for(var i = this.db_columns.length-1; i >= 0 ; i--)
                        if(this.db_columns[i].text == "" && this.db_columns[i].category != null && !cats.includes(this.db_columns[i].category))
                            this.db_columns.splice(i, 1);
    
                    this.is_loading = false;
                    return true;
                }
            },

    
        }
    </script>
    