<template>
    <div class="modal fade" id="file-modal" aria-labelledby="file-modal" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg">
           <div class="modal-content mx-2">
                <div class="modal-header border-bottom-0">
                    <div class="w-100 d-flex flex-column gap-1">
                        <img class="modal-icon" src="/img/icons/dialexa-icons/file-text.svg">
                    </div>
                    <button type="button" class="btn btn-none close-modal" 
                        data-bs-dismiss="file-modal" @click="closeModal()">
                        <i class="fa fa-close me-0"></i>
                    </button>
                </div>
                <div class="modal-body pt-0" v-if="!add_file_option">
                    <h3>Add Data Files</h3>
                    <p>How would you like to add data files?</p>

                    <div class="row mt-3">
                        <div class="col-4">
                            <div class="card option-card p-3 h-100" @click="selectAddFileOption('upload_file')">
                                <img class="modal-icon mb-2" src="/img/icons/dialexa-icons/file-upload.svg">
                                <h5>Upload</h5>
                                <p>Upload files from your computer or enable a public upload link.</p>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="card option-card p-3 h-100" @click="selectAddFileOption('sftp_3rd_party')">
                                <img class="modal-icon mb-2" src="/img/icons/dialexa-icons/folder-connection.svg">
                                <h5>SFTP</h5>
                                <h5>(3rd Party Hosting)</h5>
                                <p>Pull your data from your own SFTP server.</p>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="card option-card p-3 h-100" @click="selectAddFileOption('sftp_avid')">
                                <img class="modal-icon mb-2" src="/img/icons/dialexa-icons/folder-connection.svg">
                                <h5>SFTP</h5>
                                <h5>(Avid AI Hosted)</h5>
                                <p>Create your own secure SFTP site.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 mx-1">
                        <button class="btn btn-secondary" @click="closeModal()">Cancel</button>
                    </div>
                </div>
                <div class="modal-body pt-0" v-if="add_file_option=='upload_file'">
                    <h3>Add Data Files</h3>

                    <div class="row mb-4">
                        <nav class="navbar navbar-expand primary-tab-bar pb-0">
                            <div class="container-fluid">
                                <div class="collapse navbar-collapse">
                                    <div class="w-100 d-flex toggle-btn-group btn-group" role="group">
                                        <button type="button" class="btn btn-lg col-6"  @click.prevent="upload_option='upload'" :class="upload_option=='upload' ? 'btn-primary' : 'btn-white'">
                                            Upload
                                        </button>
                                        <button type="button" class="btn btn-lg col-6"  @click.prevent="upload_option='public_link'" :class="upload_option=='public_link' ? 'btn-primary' : 'btn-white'">
                                            Public Link
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>


                    <div v-if="upload_option=='upload'">
                        <div class="row">
                            <p>Accepted File Types: .csv, .txt, and similar delimited text files.</p>
                            <p>Once the file is dropped on the box area, the uploading process will start automatically.</p>
                            <div class="file-dropzone my-2">
                                <vue-dropzone ref="myVueDropzone" id="dropzone" class="dropzone"
                                    :options="dropzoneOptions"
                                    v-on:vdropzone-processing="onProcessing"
                                    v-on:vdropzone-success="onSuccess"
                                    v-on:vdropzone-sending="onSending"></vue-dropzone>
                            </div>
                        </div>
                        <div v-if="if_uploaded" class="alert alert-primary" role="alert">
                            This file has been uploaded and processing. You can click on the <a :href="'/settings/v1/' + client.url + '/files'">File tab</a> to see the file list and the processing status.
                        </div>

                        <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-3">        
                            <button class="btn btn-secondary w-100" @click.prevent="add_file_option = null">
                                Back to Upload Options
                            </button>
                        </div>                        
                    </div>

                    <div v-if="upload_option=='public_link'">
                        <div class="row">
                            <h5>Public Upload Link</h5>
                            <p>Enabling a public link will allow you, or other users, to upload data files without having to login. You’ll be given a secure link and users will only be able to add new files; they cannot view or edit existing ones. </p>
                            
                            <div class="col-12 my-2" id="public-links" >
                                <div class="input-group">
                                    <button class="btn btn-secondary" @click="toggleLink()">
                                        <span v-if="this.local_conn && this.local_conn.connection_criteria.public_link == true">Disable Public Upload Link</span>
                                        <span v-else>Enable Public Upload Link</span>
                                    </button>
                                    <transition name="slide-slow" :class="upload_option=='public_link'? '':'hidden'">
                                        <input type="text" class="form-control border-primary bg-white ps-2 text-muted "
                                            v-if="this.local_conn && this.local_conn.connection_criteria.public_link == true"
                                            v-model="file_url + client.url"  @click="copyToClipboard()"
                                            ref="clone" readonly v-on:focus="$event.target.select()">
                                    </transition>
                                </div>
                            </div>

                            <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-3">        
                                <button class="btn btn-secondary w-100" @click.prevent="add_file_option = null">
                                    Back to Upload Options
                                </button>
                            </div>  
                        </div>
                      
                    </div>
                </div>

                <div class="modal-body pt-0" v-if="add_file_option == 'sftp_3rd_party' || add_file_option=='sftp_avid'">
                    <h3>{{ add_file_option == 'sftp_3rd_party' ? 'SFTP (3rd Party Hosting)':'SFTP (Avid AI Hosted)' }}</h3>
                    <div v-if="form.loading">
                        <div class="d-flex flex-column gap-2 align-items-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <h5 class="text-center">Loading SFTP Information</h5>
                        </div>
                    </div>
                    <div v-else-if="selected_integration_info.type=='credentials'">

                        <div v-html="selected_integration_info.instructions"></div>
                        <div class="system-connection-fields row mt-2">
                            <div v-for="(field, field_index) in selected_integration_info.field" class="mb-1">
                                <label v-if="field.type != 'checkbox' && field.type != 'hidden'" class="form-label">
                                    <span  v-html="field.label"></span>
                                    <i v-if="field.tooltip && field.tooltip.length > 0" class="fas fa-info-circle" data-bs-toggle="tooltip" :title="field.tooltip"></i>
                                </label>

                                <input v-if="field.type == 'text'" type="text" class="form-control" v-on:change="field.error = false;"
                                    :name="field.id" v-model="field.value"
                                    :class="((field.error)? 'is-invalid':'')">

                                <input v-if="field.type == 'label'" type="text" class="form-control"
                                    disabled="disabled" :name="field.id" v-model="field.value">

                                <textarea v-if="field.type == 'textarea'"  :name="field.id" class="form-control full-height" v-model="field.value"  v-on:change="field.error = false;"></textarea>

                                <div v-if="field.type == 'password' || field.type == 'password-text'" class="input-group" >
                                    <input aria-label="Password" aria-describedby="password-addon" ref="password" autocomplete="off" :name="field.id" :type="field.type == 'password'? 'password' : 'text'"  :class="((field.error)? 'is-invalid':'')"
                                    class="form-control password-input input_v1" name="password"  v-model="field.value"  v-on:change="field.error = false;">

                                    <span :title="!field.visible ? 'Show password' : 'Hide password'" @click="toggleVisibility(field)"
                                        class="input-group-text" id="password-addon"
                                        style="border-left: none; background-color: white">
                                        <i class="fa" :class="[!field.visible ? 'fa-eye' : 'fa-eye-slash']"></i>
                                    </span>
                                </div>

                                <div v-if="field.type == 'select'">
                                    <select class="form-select" :name="field.id" v-model="field.value" :class="((field.error)? 'is-invalid':'')"  v-on:change="field.error = false;">
                                        <option v-for="option in Object.keys(field.options)" :value="option">{{field.options[option]}}</option>
                                    </select>
                                </div>
                                <div v-if="field.type == 'warning'">
                                    <p style="margin-top:-8px;"><small class="text-muted">{{ field.text }}</small></p>
                                    <div class="alert alert-warning " role="alert">
                                        <code v-html="field.value"></code>
                                    </div>
                                </div>

                                <div class="text-danger invalid-feedback" v-if="field.error">
                                     {{field.error_msg}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="alert alert-danger mt-3" v-if="form.error ">
                        <span v-html="form.msg"></span>
                    </div>

                    <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-3">        

                        <button class="btn btn-primary w-50" @click="addIntegration()" :disabled="form.saving">
                            <span v-if="form.saving"><div class="spinner-border  spinner-border-sm text-white float-left" role="status"> <span class="visually-hidden">Loading...</span></div> Saving</span><span v-else>Save</span>
                        </button>
                        <button class="btn btn-secondary w-50" @click.prevent="add_file_option = null" :disabled="form.saving">
                            Back to Upload Options
                        </button>
                    </div>  
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal, vueDropzone: vue2Dropzone,
        },
        props: ['client', 'connection'],
        data() {
            return {
                add_file_option: null,
                upload_option: 'upload',
                if_uploaded:false, // for file upload only
                local_conn: null,
                // sftp
                // file_fields:{},
                selected_integration_info:{},

                form: {
                    saving: false,
                    error: false,
                    msg: "",
                    loading: false,
                },
                // file upload
                dropzoneOptions: {
                    url: "#",
                    method: 'put',
                    timeout: 0,
                    parallelUploads: 2,
                    dictDefaultMessage: "Click or Drag/Drop a File Here",
                    headers: {
                        'charset': "utf-8",
                        "Cache-Control": "",
                    },
                    withCredentials: false,
                    accept: function(file, done) {

                        var url_parts = window.location.pathname.split('/');
                        var client_url = url_parts[3];
                        this.options.headers['Content-Type'] = file.type;
                        window.axios.post( "/api/file_upload/get_signed_url", { url: client_url, file_name: Date.now() + "_" +file.name }).then(function(data) {

                           //I can't access the variable in the "data" property so I'll just save it to the html
                          if($("#dropzone").data("file_urls") == undefined)
                            $("#dropzone").data("file_urls", []);

                            //Save the URL for when it is actually sent in a second
                            var arr = $("#dropzone").data("file_urls");
                            arr[file.upload.uuid] = data.data.url;

                            $("#dropzone").data("file_urls", arr);
                            done();
                        });

                    },
                    init: function() {
                        const dz = this;

                        dz.on("processing", (file) => {
                            dz.options.headers["Content-Type"] = file.type;
                        });
                    },
                    maxFilesize: null,
                },
            };
        },
        beforeMount() { 
            this.local_conn = this.connection;
            this.local_conn.connection_criteria.public_link = false;
        },
        computed: {
            file_url() {
                return window.location.protocol + '//'+window.location.host+'/f/';
            }
        },
        methods: {
            selectAddFileOption(option){
                this.add_file_option = option;
                
                if(option != 'upload_file'){
                    var integration_id;
                    if(option == 'sftp_3rd_party'){
                        this.local_conn =  null;
                        this.getIntegrationFields('397b5b86-e954-495f-8fdb-513885f53251');
                    } else if(option == 'sftp_avid'){
                        this.form.loading = true;
                        window.axios.post('/api/integrations/sftp/setup',
                            {client_id: this.client.id})
                            .then(response => {
                                this.local_conn = response.data.connection;
                                this.getIntegrationFields('7dbce608-4906-4606-977c-32ab1259b2e6');
                            }).catch(error => {
                                this.form.loading = false;
                                this.form.error = true;
                                this.form.msg = "There was an error creating your SFTP drive. Please try again later.";
                            });
                    }
                }
                else
                    this.local_conn = this.connection;
            },
            getIntegrationFields(integration_id) {
                this.form.loading = true;
                let url = '/api/integration/' + integration_id;
                if(this.local_conn != null)
                    url += '/' + this.local_conn.id;

                window.axios.get(url)
                    .then(response => {
                        this.selected_integration_info = response.data;

                        var fields = response.data.field;
                        for (var i = 0; i< fields.length; i++){
                            if (fields[i].type == 'password')
                                fields[i].visible = false;

                            if (fields[i].type == 'select')
                                fields[i].value = Object.keys(fields[i].options)[0];

                            fields[i].error = false;
                        }
                        this.form.loading = false;
                    }).catch(error => {
                        this.form.loading = false;
                        this.form.error = true;
                        this.form.msg = error.response.data.message;
                    });
            },
            toggleVisibility(field){
                // const input = this.$refs.password;
                if (field.type === 'password') {
                    field.type = 'password-text';
                    field.visible = true;
                } else if (field.type === 'password-text') {
                    field.type = 'password';
                    field.visible = false;
                }
            },
            toggleLink() {
                if(this.local_conn.connection_criteria.public_link)
                    this.local_conn.connection_criteria.public_link = false;
                else
                    this.local_conn.connection_criteria.public_link = true;

                //Save it to the DB
                window.axios.post('/api/connection/'+this.local_conn.id, this.local_conn);
            },
            copyToClipboard() {
                this.$refs.clone.focus();
                document.execCommand('copy');
                Swal.fire({
                  html: '<h5 class="mb-0 mt-1 white-text">Copied</h5>',
                  target: '#file-modal',
                  icon: 'success',
                  toast: true,
                  position: 'bottom-right',
                  showConfirmButton: false,
                  timer: 3000,
                  iconColor: 'white',
                  customClass: {
                    popup: 'bg-primary'
                  },
                });
            },
            onProcessing(file) {
                this.if_uploaded = false;  
                //Set the upload URL for this specific file
                var file_urls = $("#dropzone").data('file_urls');
                document.getElementById("dropzone").dropzone.options.url = file_urls[file.upload.uuid];

            },
            onSuccess( file, res ) {
                var data = new FormData();
                data.append("file_name", file.name);
                data.append("gcs_path", file.xhr.responseURL);
                data.append("client_url", this.client.url);

                //Remove it from the dropzone UI
                var fadeTarget = document.getElementsByClassName("dz-success")[0];
                var fadeEffect = setInterval(function () {
                    if (!fadeTarget.style.opacity) {
                        fadeTarget.style.opacity = 1;
                    }
                    if (fadeTarget.style.opacity > 0) {
                        fadeTarget.style.opacity -= 0.1;
                    } else {
                        clearInterval(fadeEffect);
                        document.getElementById("dropzone").dropzone.removeFile(file);
                    }
                }, 50);

                if(document.getElementsByClassName("dz-file-preview").length == document.getElementsByClassName("dz-success").length){ 
                     //only appear once all of the uploads on the page have finished
                    this.if_uploaded = true;                    
                }

                // Swal.fire({
                //     title: "File Uploaded!",
                //     text: "This file has been uploaded and processing. You can click on the File tab to see the file list and the processing status.",
                //     icon: "success",
                //     iconColor:  "#46705F",
                //     confirmButtonColor: "#46705F",
                //     confirmButtonText: "Confirm",
                // }).then((result) => {
                //   if (result.isConfirmed) {
                //     this.closeModal();
                //     window.location = "/settings/v1/" + this.client.url + "/files"; 
                //   }
                // });
                window.axios.post('/api/file_upload/new_file', data);
            },
            onSending( file, xhr, formData ) {
                var _send = xhr.send;
                xhr.send = function() {
                  _send.call(xhr, file);
                };
            },
            isFormValid() {
                if(this.selected_integration_info == null || this.selected_integration_info.field == null)
                    return true;

                let is_valid = true;
                for (var i = 0; i < this.selected_integration_info.field.length; i++) {
                    if(this.selected_integration_info.field[i].required && (this.selected_integration_info.field[i].value == null ||
                        this.selected_integration_info.field[i].value == '')){
                        this.selected_integration_info.field[i].error = true;
                        this.selected_integration_info.field[i].error_msg = 'This field is required';

                        is_valid = false;
                    }
                    else
                        this.selected_integration_info.field[i].error = false;
                }

                return is_valid;
            },
            addIntegration(){

                if(!this.isFormValid()){
                    this.form.msg = "There was an error saving your information.";
                    this.form.error = true;
                    return;
                }

                this.form.msg = "";
                this.form.error = false;
                this.form.saving = true;
                //Copy the value of the field data
                let data = {
                    client_id: this.client.id
                }
                //if updating an existing connection
                if(this.local_conn != null)
                    data.conn_id = this.local_conn.id;

                //Loop through the field data and get the key as the data id
                this.selected_integration_info.field.forEach(field => {
                    data[field.id] = field.value;
                });

                window.axios.post('/api' + this.selected_integration_info.url, data)
                    .then(response => {
                        this.closeModal();
                        this.$emit('connectionAdded', response.data.connection);

                        this.form.saving = false;
                    }).catch(error => {
                        if(error.response.data.message != null && error.response.data.message != ""){
                            this.form.msg = error.response.data.message;
                        }
                        else{
                            this.form.msg = "There was an error saving your information. Please try again shortly";
                        }

                        this.form.saving = false;
                        this.form.error = true;
                    });
            },
            closeModal() {
                var myModalEl = document.getElementById('file-modal')
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();

                // Ensure backdrop removal
                myModalEl.addEventListener('hidden.bs.modal', () => {
                    const backdrop = document.querySelector('.modal-backdrop');
                    if (backdrop) {
                      backdrop.parentNode.removeChild(backdrop);
                    }
                }, { once: true });

                this.form.saving = false;
                this.form.loading = false;
                this.form.error = false;
                this.add_file_option= null;
                this.upload_option= 'upload';
                this.selected_integration = {};
                this.selected_integration_info = {};
            },
        }
    }
</script>
