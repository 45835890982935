<template>
    <nav class="top-nav navbar navbar-expand-md">
        <div class="container-fluid">
            <!-- Left Side Of Navbar -->
            <ul class="navbar-nav flex-row">
                <li class="nav-item dropdown text-center mx-1 client-select" v-if="clients.length>1">
                    <a id="navbarDropdown" class="form-select" href="#" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="client != null && client.id > 0">{{ client.name }}</span>
                        <span v-else>Select a Client</span>
                    </a>

                    <div class="dropdown-menu dropdown-menu-start position-absolute" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" v-for="c in clients"
                            :href="'/switch/' + c.url">{{ c.name }}</a>
                    </div>
                </li>
            </ul>
            <!-- Right Side Of Navbar -->
            <ul class="navbar-nav ms-auto flex-row">


                <li class="nav-item text-center mx-2 mt-2 icon-nav">
                    <a class="nav-link" href="#" type="button"
                        data-bs-toggle="modal" data-bs-target="#support-modal">
                        <img class="icon" src="/img/icons/dialexa-icons/message-question.svg" style="width:24px;">
                    </a>
                </li>
                <li class="nav-item dropdown text-center mx-2 mt-2 alert-dropdown icon-nav">
                    <a id="alert-dropdown" class="nav-link dropdown-toggle no-toggle" href="#" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  data-bs-auto-close="outside">
                        <img class="icon" src="/img/icons/dialexa-icons/bell.svg"  style="height:24px;">
                        <span class="position-absolute translate-middle badge rounded-pill bg-danger" v-if="notif != null && notif.length > 0">
                            {{ num_notifications }}
                            <span class="visually-hidden">unread messages</span>
                        </span>
                    </a>

                    <div class="dropdown-menu dropdown-menu-end position-absolute pb-3" aria-labelledby="alert-dropdown"  :class="(notif.length <= 3 ? 'pt-3' : ' pt-0')">
                        <div class="dropdown-item bg-primary text-center p-2" v-if="notif.length > 3">
                            <a href="#" v-on:click.prevent="dismissAll()" class="text-white text-d">Dismiss All Notifications</a>
                        </div>
                        <transition-group name="fade-slow">
                            <div class="dropdown-item" v-for="n in notif" :key="n.id">
                                <span class="float-end lh-1">{{n.created_at | date}}</span>
                                <h5 class="mb-2">{{ n.data['client_name'] }}</h5>
                                <p>{{ n.data['error_msg'] }}</p>
                                <p class='mb-0'><button  v-if="n.data['cta'] != null " class="btn btn-sm btn-primary me-2" v-on:click="markRead(n)">{{ n.data['cta'] }}</button> <button class="btn btn-secondary btn-sm" v-on:click="dismiss(n)">Dismiss</button></p>
                            </div>
                        </transition-group>

                        <div class="dropdown-item w-auto" v-if="notif == null || notif.length == 0">No Notifications</div>
                    </div>
                </li>
                <li class="nav-item dropdown text-center mx-2 mt-2 text-nav">
                    <a id="profile-avatar" class="nav-link dropdown-toggle" href="#"
                        type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <span>My Account</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end position-absolute" aria-labelledby="profile-avatar">
                        <span v-if="user.user_type == 'admin' || user.user_type == 'agency_admin'">
                            <a href="/admin/agency/profile" class="dropdown-item new-feature" v-if="user.user_type == 'agency_admin'" >
                                <i class="fas fa-building"></i> Agency Settings</a>
                            <!-- Add additional menu options here -->
                            <a href="/admin/manage_client" class="dropdown-item" v-if="user.user_type == 'admin'" >
                                <i class="fas fa-building"></i> Agency Clients</a>
                            <a href="/admin/manage_user" class="dropdown-item" v-if="user.user_type == 'admin'">
                                <i class="fas fa-user-shield"></i> Agency Users</a>

                            <a href="/campaigns/admin/list" class="dropdown-item" v-if="user.user_type == 'admin'">
                                <i class="fa-solid fa-bullseye"></i> Campaign Editor</a>
                            <a href="/admin/initiative_editor" class="dropdown-item">
                                <i class="fas fa-wrench"></i> Initiative Editor</a>
                           
                        </span>
                        <span v-if="user.user_type == 'admin'">
                            <div class="dropdown-divider"></div>
                            <a href="/insights/metrics_filters" class="dropdown-item">
                                <i class="fa-solid fa-filter"></i> Calculations / Filters</a>
                            <a href="/insights/charts" class="dropdown-item">
                                <i class="fa-solid fa-chart-simple"></i> Chart Builder</a>
                            <a href="/insights/report_builder" class="dropdown-item">
                                <i class="fa-solid fa-book"></i> Report Builder</a>
                            <div class="dropdown-divider"></div>
                        </span>
                        <a :href="'/user/' + client.url + '/profile'" class="dropdown-item" v-if="client != null"><i class="fas fa-user"></i> Edit Profile</a>
                        
                        <div class="dropdown-divider" v-if="client != null"></div>

                        <a class="dropdown-item" href="/logout"
                            onclick="event.preventDefault(); document.getElementById('logout-form').submit();">
                            <i class="fas fa-sign-out-alt"></i> Logout
                        </a>
                    </div>
                </li>

            </ul>
        </div>

        <support-modal :user="user" :client="client" :selected_ticket_type="''"></support-modal>
    </nav>
</template>
<script>
    import html2canvas from 'html2canvas';
    export default {

        props: ['user', 'client', 'clients', 'notifications'],
        data() {
            return {
                notif: null,
                form: {
                    category: '',
                    urgency: '',
                    title: '',
                    description: ''
                },
                errors: {
                    category: '',
                    title: '',
                    description: '',
                    urgency: ''
                },
                working: false

            };
        },
        beforeMount() {
            this.notif = this.notifications;
        },
        computed: {
            num_notifications() {
                return this.notif.length;
            }
        },
        methods: {
            markRead(n) {
                this.dismiss(n);
                document.location = n.data['url'];
            },
            dismiss(n) {
                window.axios.get('/api/notification/read/' + n.id);

                //Remove the notification from the list
                for(var i = 0; i < this.notif.length; i++) {
                    if(this.notif[i].id == n.id) {
                        this.notif.splice(i, 1);
                    }
                }
            },
            dismissAll() {
                window.axios.get('/api/notification/read_all');
                //Remove all the notification from the list
                this.notif = [];
            },
            emitInput() {
                this.$emit('input', this.data_filter);
            },
        }

    }
</script>
