<template>
    <div id="org_settings_connections-view-dataset" class="my-2">
        <div id="org_settings_connections-view-dataset__text">
            <div class="d-flex flex-column gap-1 flex-grow-1">
                <div class="card-body">
                    <div class="system-connection-card__header row">
                        <div class="col-12 d-flex" v-if="local_connection">
                            <div class="system-connection-card__header__icon">
                                <img :src="local_connection.integration.logo_url" :alt="local_connection.integration.name" class="avatar rounded-circle avatar-sm me-2 bg-white">     
                            </div>
                            <h4 class="mt-1">{{ local_connection.integration.display_name }}</h4>
                            
                            <div class="system-connection-card__header__status ms-2">
                                <span class="badge badge-secondary d-inline-block" v-if="!local_connection.sync_error_msg && !local_connection.last_synced_at && !local_connection.initial_sync_at">Initializing</span>
                                <!-- <span class="badge badge-primary d-inline-block" v-else-if="local_connection.activeJobs.length >0">Syncing</span> -->
                                <span class="badge badge-error d-inline-block" v-else-if="local_connection.sync_error_msg" data-bs-toggle="tooltip" :title="local_connection.sync_error_msg">Error</span>
                                <span class="badge badge-success-dark d-inline-block" v-else-if="local_connection.active_jobs.length > 0">Syncing</span>
                                <span class="badge badge-success d-inline-block" v-else >Active</span>
                            </div>

                            <div class="system-connection-card__header__actions" v-if="canSync() || canDelete()">
                                <button class="btn btn-none p-1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img class="icon pe-0" src="/img/icons/dialexa-icons/vertical-dots.svg">
                                </button>

                                <div class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" @click.prevent="updateConnection()" data-bs-toggle="modal" data-bs-target="#system-modal">
                                        <i class="fa fa-btn fa-key"></i>Update Credentials
                                    </a></li>
        
                                    <li v-if="canSync()"><a class="dropdown-item" href="#" @click.prevent="syncConnection()">
                                        <i class="fa fa-btn fa-sync me-2"></i>Sync Now</a>
                                    </li>
                    
                                    <li v-if="canDelete()"><a href="#" class="dropdown-item" @click.prevent="deleteConnection()">
                                        <i class="fa fa-btn fa-trash me-2"></i>Delete</a>
                                    </li>
                                </div>
                            </div>


                        </div>

                        <div class="col-12 d-flex" v-if="local_file">
                            <h4 class="mt-1">{{ local_file.name }}</h4>
                            
                            <div class="system-connection-card__header__status ms-2">
                                <span class="badge badge-success d-inline-block" >{{local_file.status | propercase}}</span>
                            </div>

                            <div class="system-connection-card__header__actions">
                                <button class="btn btn-none p-1" data-bs-toggle="dropdown"  data-bs-auto-close="outside" aria-expanded="false">
                                    <img class="icon pe-0" src="/img/icons/dialexa-icons/vertical-dots.svg">
                                </button>
                                <div class="dropdown-menu">
                                    <li v-if="isDataFile(local_file)"><a class="dropdown-item" href="#" @click="previewTable(local_file)" data-bs-toggle="modal" data-bs-target="#preview-modal">
                                        <img class="icon" src="/img/icons/dialexa-icons/eye.svg"> Preview File
                                    </a></li>

                                    <li v-if="withinThirtyDays(local_file)"><a class="dropdown-item" :href="'/integrations/'+ client.url +'/files/download/'+ local_file.id" target="_blank">
                                        <img class="icon" src="/img/icons/dialexa-icons/download.svg"> Download File
                                    </a></li>

                                    <li ><a class="dropdown-item" href="#" target="_blank"  @click="removeFile(local_file)">
                                        <img class="icon" src="/img/icons/dialexa-icons/trash.svg"> Delete File
                                    </a></li>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div class="system-connection-card__content">
                        <div class="d-flex flex-row align-items-center">
                            <div class="d-flex-flex-column">
                                <small v-if="local_connection">{{ local_connection.name }} &nbsp | &nbsp Last Synced: {{ local_connection.last_synced_at | date }}  &nbsp | &nbsp  Created on: {{ local_connection.created_at | date }}</small>
                                <small v-else>{{ local_file.source }} &nbsp | &nbsp Uploaded date: {{ local_file.created_at | date }} </small>
                                <!---<p><small v-if="local_connection.sync_error_msg">Error Message: {{ local_connection.sync_error_msg }}</small></p>-->
                            </div>
                        </div>                   

                        <div class="alert border-danger border-primary mt-4 mb-4" v-if="local_connection && local_connection.sync_error_msg">
                            <div class="row"> 
                                <div class="col-12">
                                    <h5>Integration Error</h5>
                                    <p>Avid encountered an error with your {{local_connection.integration.display_name }} connection. To reactivate the integration, you simply need to click the button below and follow the steps listed.</p>
                                </div>
                                <div class="col-12 mt-3">
                                    <button class="btn btn-error"  @click.prevent="updateConnection()" data-bs-toggle="modal" data-bs-target="#system-modal">
                                        <i class="fa fa-btn fa-key text-danger"></i> Reconnect {{local_connection.integration.display_name }}
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                        <div class="alert bg-secondary border-primary mt-3 mb-4" v-else>
                            <div class="row"> 
                                <div class="col-12">
                                    <h5>Can We Help?</h5>
                                    <p>Avid AI is built to be as flexible as you need it to be with the ability to customize how channels, appeals, and other critical pieces of information are defined. Unfortunately, with that flexibility comes complexity but we're here to help. Just let us know and we can help you get your data correctly mapped so Avid provides the maximum value.</p>
                                </div>
                                <div class="col-12 mt-3">
                                    <button class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#support-modal-'+selected_ticket_type" @click="openSupportModal()">
                                        <img class="icon pe-2" src="/img/icons/dialexa-icons/glasses.svg"> Help Me Set Up My Data (* this is a free service)
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                        
                        <div class="mt-3" v-if="local_datasets && local_datasets.length > 0">
                            <p>A "data set" is a view of the data extracted from your integrated systems. Below you can add filters to these views to limit the data available for analysis and syncronization to third party systems.</p>
                            <nav v-if="local_datasets.length <=3" class="navbar navbar-expand secondary-tab-bar p-0 mb-2">
                                <div class="container-fluid px-0 mb-3">
                                    <div class="collapse navbar-collapse" id="dataset-navbar">
                                        <ul class="navbar-nav mb-2 mb-lg-0">
                                            <li class="nav-item" v-for="(dataset, index) in local_datasets">
                                                <a class="nav-link d-flex flex-nowrap secondary-tab" :class="{'secondary-tab-active': index == active_tab_index}" 
                                                href="#" role="button" aria-expanded="false" @click.prevent="setActiveTab(index)">
                                                    <h6>{{ dataset.name | propercase }}</h6>
                                                    <!-- <small>Last Synced: {{ dataset.updated_at | date }}</small> -->
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                            
                            <div class="col-12 mb-3 mt-3" v-else>
                                <label class="form-label">Select a Data Set to Edit</label>
                                <select class="form-select" name="dataset-selection" v-model="active_tab_index" @change="changeDatasetSelection()">
                                    <option :value="index" v-for="(dataset, index) in local_datasets">{{dataset.name}}</option>
                                </select>
                                 
                            </div>

                            <div v-for="(dataset, index) in local_datasets" :class="(index == active_tab_index ? '' : 'd-none')" class="row ">
                                <label class="form-label mb-0">Data Set Name</label>
                                <div class="col-9 mb-2">
                                    <h5 v-if="!if_editing_dataset_name">{{ dataset.name }}
                                        <button class="btn btn-none" @click.prevent="toggleEditName()"  data-bs-toggle="tooltip" title="Edit Dataset Name">
                                            <img class="icon" src="/img/icons/dialexa-icons/pencil.svg">
                                        </button> 
                                    </h5> 

                                    <div v-else>
                                        <div class="d-flex flex-row gap-1">
                                            <input type="text" class="form-control" v-model="local_datasets[index].name" :class="((errors.name != '' )? 'is-invalid':'')">
                                            <button class="btn btn-primary reg-btn" @click.prevent="saveDataSetName(index)">
                                                <i class="fa fa-save m-0" data-bs-toggle="tooltip" title="Save Data Set Name"></i>
                                            </button> 
                                            <button class="btn btn-secondary" @click.prevent="cancelEditName(index)">
                                                <i class="fa fa-close m-0" data-bs-toggle="tooltip" title="Cancel Editing"></i>
                                            </button> 
                                        </div>
                                        <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                            {{errors.name}}
                                        </div>

                                    </div>
                                </div>
                                <div class="col-3" v-if="dataset.source_type != 'other' || dataset.source_subtype != null ">
                                    <!-- <div class="float-end"> -->
                                        <button v-if="dataset.is_primary==0" class="btn btn-secondary float-end mb-2" @click.prevent="updateIsPrimary(dataset)"  >
                                            <img class="icon" src="/img/icons/dialexa-icons/1st-medal.svg"> Set As Primary Data Set <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="Certain analyses require a single source to be used in the analysis. Setting the primary flag identifies which source to use."></i>
                                        </button>
                                        <span v-else class="float-end mt-2">
                                            <i class="fa fa-check fa-xl green"></i> Primary Data Set
                                            <i class="fas fa-info-circle ms-1" data-bs-toggle="tooltip" title="Certain analyses require a single source to be used in the analysis. Setting the primary flag identifies which source to use."></i>
                                        </span>                                        
                                    <!-- </div> -->
                                </div>
                                
                                <div class="col-12">
                                    <transition-group tag="div" class="d-flex flex-column justify-content-start gap-3">
                                        <div class="accordion" key="dataset-file-information" v-if="file_or_connection == 'file' || isFileConnection()">
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <div class="accordion-button collapsed" type="button"  
                                                        :data-bs-target="'#dataset-file-information'" data-bs-toggle="collapse"
                                                        aria-expanded="false" :aria-controls="'#dataset-file-information'" >
                                                        <h5 class="mb-0">Information</h5>
                                                    </div>                                        
                                                </div>
                                                <div class="accordion-body accordion-collapse pt-0" id="dataset-file-information" :class="if_expand_file_information ? '': 'collapse'">
                                                    <div class="row">
                                                        <div class="col">
                                                            <div v-if="dataset.source != 'Airbyte' && dataset.source != 'Table'">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Refresh Type</label>
                                                                    <select class="form-select"  v-model="dataset.settings.refresh_type" @change="columnsValid()" >
                                                                        <option value="incremental" >Append: New files are merged with prior uploads</option>
                                                                        <option value="full" >Replace: New files replace prior uploads</option>
                                                                    </select>
                                                                    <em class="small" v-if="dataset.settings.refresh_type == 'incremental'">When appending files, the "Updated At" field will ensure the most recent record is used (if it is set on the Column Mapping screen).</em>

                                                                </div>
                                                                <div class="mb-3" v-if="dataset.settings.refresh_type == 'incremental'">
                                                                    <label class="form-label" data-bs-toggle="tooltip" title="When appending files, you can select one or more columns to be used as a unique ID. Records with the same ID as a record in a prior upload will be used as a replacement rather than a new record. If no columns are selected, records will always be appended.">Column(s) to Identify Unique Records <i class="fas fa-info-circle"></i></label>
                                                                    <div class="dropdown">
                                                                    <button class="btn form-select btn-field" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside">
                                                                        <span v-if="(dataset.settings.id_columns != null && dataset.settings.id_columns.length > 0 )">{{ id_columns }}</span><span v-else><em>None Selected</em></span>
                                                                    </button>
                                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
                                                                        <label class="dropdown-item" v-for="(item, key) in dataset.source_columns" :key="key">
                                                                            <input class="form-check-input" type="checkbox" :value="key"  v-model="dataset.settings.id_columns" :true-value="[]">
                                                                            <span class="ms-2" >
                                                                            {{ item }}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label class="form-label" data-bs-toggle="tooltip" title="Identify what files should be included in this data set. You can use a wildcard character (%) if there are parts of the file name that will change with each upload or, for more advanced rules, use a regular expression by starting the pattern with r' and ending it with a '">File Name Pattern <i class="fas fa-info-circle"></i></label>
                                                                    <input type="text" class="form-control" :class="((errors.pattern != '' )? 'is-invalid':'')"
                                                                        name="pattern" v-model="dataset.settings.pattern" @change="refreshFiles()">
                                                                    <div class="text-danger invalid-feedback" v-if="errors.pattern != ''">
                                                                        {{errors.pattern}}
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3">
                                                                    <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" id="ignore_unused" v-model="dataset.settings.ignore_unused" @change="columnsValid()">
                                                                    <label class="form-check-label" for="ignore_unused">
                                                                        <strong>Ignore Unused Column Changes.</strong> If columns are added or removed in future uploads, the file will still process when the changes don't impact existing mappings.
                                                                    </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col"  v-if="dataset.source != 'Airbyte' && dataset.source != 'Table'">
                                                            <div class="card box-content mb-3 overflow-hidden">
                                                                <div class="card-body px-0 pb-0">
                                                                    <p class="px-3 pb-2">Based upon the pattern specified, the following files will be included in this data set:</p>
                                                                    <div class="table-responsive" style="max-height: 271px;" v-if="local_files.length > 0">
                                                                        <table class="table gy-0 m-0 mb-2">
                                                                            <thead class="table-dark">
                                                                                <tr>
                                                                                    <th class='align-middle'>File Name</th>
                                                                                    <th class="align-middle" colspan="2">Uploaded At</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <transition-group tag="tbody" name="table-row" class="table-border-bottom-0" >
                                                                                <tr v-for="(f, index) in local_files" :key="f.id" :class="(f.status_msg == 'MISMATCH' ? 'error' : '')">
                                                                                    <td class='ps-3 align-middle'>
                                                                                        {{f.name}}
                                                                                    </td>
                                                                                    <td class="align-middle text-nowrap" :colspan="(f.status_msg == 'MISMATCH'? '1' : '2')" >{{ f.created_at | datetime }}</td>
                                                                                    <td v-if="f.status_msg == 'MISMATCH'" class="text-end" ><button type="button" class="btn btn-sm btn-none red" ><i class="fa fa-trash"  @click="removeFile(f)"></i></button></td>
                                                                                </tr>
                                                                            </transition-group>
                                                                        </table>
                                                                    </div>
                                                                    <div v-else>
                                                                        <p class="p-3"><strong><em>No files were found that match the given pattern.</em></strong></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div class="row" >
                                                            <div class="col">
                                                                <div class="alert alert-danger mb-3" v-if="information_form.error">
                                                                    There is an issue with the information provided in the form above. Please check the fields and make the necessary corrections.
                                                                </div>
                                                                <!-- Save Button -->
                                                                <div class="mb-3">
                                                                    <button type="submit" class="btn btn-primary" @click="saveInformation(dataset, index)" :disabled="information_form.busy">
                                                                        <div v-if="information_form.busy" class="spinner-border  spinner-border-sm float-left ms-1" role="status"></div><i v-else class="fa fa-btn fa-save me-1"></i> Save 
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion" key="dataset-inclusion-filters">
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <div class="accordion-button collapsed" type="button"  
                                                        :data-bs-target="'#dataset-inclusion-filters'" data-bs-toggle="collapse"
                                                        aria-expanded="false" :aria-controls="'#dataset-inclusion-filters'" >
                                                        <h5 class="mb-0">Inclusion Filters</h5>
                                                    </div>                                        
                                                </div>
                                                <div class="accordion-body accordion-collapse pt-0" id="dataset-inclusion-filters" :class="if_expand_inclusion_filters ? '': 'collapse'">
                                                    <div class="row">
                                                        <div class="col mb-4 ">
                                                            <!-- <h3>Inclusion Filters</h3> -->
                                                            <p>You are able to limit the records in your data set. This is helpful if removing records like soft credits from a list of transactions or non-donors from a list of constituents.</p>
                                                            <query-builder :client="client" v-model="dataset.filter" :id="'dataset-inclusion-filters-'+dataset.id" :key="dataset.id"  
                                                                :static_db_columns="dataset.source_columns"
                                                                :include_trans="false" :source="dataset.data_source" :in_card="true" :add_blank="true"
                                                                :data_source_id="dataset.id" ref="filter"></query-builder>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="alert alert-danger mb-3" v-if="inclusion_filter_form.error">
                                                                There is an issue with the information provided in the fields above. Please check your entries and make the necessary corrections.
                                                            </div>
                    
                                                            <!-- Save Button -->
                                                            <div class="mb-3">
                                                                <button type="submit" class="btn btn-primary" @click="saveInclusionFilters(dataset, index)" :disabled="inclusion_filter_form.busy">
                                                                    <div v-if="inclusion_filter_form.busy" class="spinner-border  spinner-border-sm float-left ms-1" role="status"></div><i v-else class="fa fa-btn fa-save me-1"></i> Save 
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion" key="dataset-column-mappings">
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <div class="accordion-button collapsed" type="button"  
                                                        :data-bs-target="'#dataset-column-mappings'" data-bs-toggle="collapse"
                                                        aria-expanded="false" :aria-controls="'#dataset-column-mappings'" >
                                                        <h5 class="mb-0">Column Mappings</h5>
                                                    </div>                                        
                                                </div>
                                                <div class="accordion-body accordion-collapse pt-0" id="dataset-column-mappings" :class="if_expand_column_mappings ? '': 'collapse'">
                                                    <div class="row">
                                                        <div class="col mb-3">
                                                            <p class="mb-3">Based upon the "Data Category" selected, there are several mandatory and optional fields to be mapped. <strong>If you have additional columns you'd like to use for filters, be sure to add those columns too.</strong></p>

                                                            <column-mapping :id="'dataset-column-mappings-'+dataset.id" :key="dataset.id"  
                                                                v-if="if_dest_columns_loaded" :client="client" :dataset="dataset" :dest_columns="dataset.dest_columns"
                                                                ref="column_mapping" v-model="dataset.columns" :data_source_id="dataset.id"
                                                                :source="dataset.data_source" ></column-mapping>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="alert alert-danger mb-3" v-if="column_mapping_form.error">
                                                                There was an issue saving the data set. Check the fields above to get more information.
                                                            </div>
                                                            <!-- Save Button -->
                                                            <div class="mb-3">
                                                                <button type="submit" class="btn btn-primary" @click="saveColumnMapping(dataset, index)" :disabled="column_mapping_form.busy">
                                                                    <div v-if="column_mapping_form.busy" class="spinner-border  spinner-border-sm float-left ms-1" role="status"></div><i v-else class="fa fa-btn fa-save me-1"></i> Save 
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion" key="dataset-details-segments">
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <div class="accordion-button collapsed" type="button"  
                                                        :data-bs-target="'#dataset-details-segments'" data-bs-toggle="collapse"
                                                        aria-expanded="false" :aria-controls="'#dataset-details-segments'" >
                                                        <h5 class="mb-0">Segments ({{dataset.segments ? dataset.segments.length : 0}})</h5>
                                                    </div>                                        
                                                </div>
                                                <div class="accordion-body accordion-collapse pt-0" id="dataset-details-segments" :class="if_expand_segment_section ? '': 'collapse'">
                                                    <div class="d-flex flex-column gap-2">
                                                        <div class="w-100 card card-has-table" v-if="dataset.segments && (dataset.segments.length > 0 || (dataset.segments.length == 0 && search_key !=''))">
                                                            <div class="card-header">
                                                                <div class="d-flex flex-row justify-content-between gap-2">
                                                                    <div class="position-relative flex-grow-1">
                                                                        <input type="text" class="search-box form-control" placeholder="Search by Segment Name..." aria-label="Search by Segment Name..." v-model="search_key" @input="searchSegment(index)">
                                                                        <i class="fa fa-search search-placeholder-icon" v-if="search_key == ''"></i>
                                                                    </div>
                                                                    <div class="d-flex justify-content-start align-items-center">
                                                                        <a class="btn btn-secondary float-end" :href="'/segment/'+client.url+'/new?dataset='+dataset.id">
                                                                            <i class="fa fas fa-fw fa-btn fa-plus"></i> Add Segment
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="card-body p-0">
                                                                <table class="table table-responsive table-with-pagination m-0">
                                                                    <thead>
                                                                        <tr class="table-header-row">
                                                                            <th v-on:click="sortByKey('name', dataset)">
                                                                                <div class="d-flex flex-row">
                                                                                    Segment
                                                                                    <div class="d-flex flex-column">
                                                                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='name' && sort_order=='asc')? 'sort-key-active' :''">
                                                                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='name' && sort_order=='des')? 'sort-key-active' :''">                                                            
                                                                                    </div>
                                                                                </div>
                                                                            </th>
                                                                            <th v-on:click="sortByKey('size', dataset)">
                                                                                <div class="d-flex flex-row">
                                                                                    Contacts
                                                                                    <div class="d-flex flex-column">
                                                                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='size' && sort_order=='asc')? 'sort-key-active' :''">
                                                                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='size' && sort_order=='des')? 'sort-key-active' :''">                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </th>
                                                                            <th style="width:120px;">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="dataset.segments.length == 0 && search_key != ''" class="table-row-1 no-results-text">
                                                                            <td class="align-start">
                                                                                There is no file match the given search.
                                                                            </td>
                                                                        </tr>
                                                                        <tr v-for="(segment, i) in dataset.segments" :class="`table-row-${i+1}`"v-else>
                                                                            <td class="align-start vertical-align-center ">
                                                                                {{ segment.name }}
                                                                            </td>
                                                                            <td class="vertical-align-center ">
                                                                                {{ segment.size | number_with_zero }}
                                                                            </td>

                                                                            <td class="align-start  show-on-row-hover d-flex">
                                                                                <a class="btn btn-none"  :href="'/segment/' + client.url +'/clone/' + segment.id">
                                                                                    <img class="icon" src="/img/icons/dialexa-icons/document-copy.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Copy Segment" >
                                                                                </a>
                                                                                <a class="btn btn-none"  :href="'/segment/' + client.url +'/' + segment.id">
                                                                                    <img class="icon" src="/img/icons/dialexa-icons/pencil.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit Segment" >
                                                                                </a>              
                                                                                <button class="btn btn-none" @click.prevent="deleteSegment(segment, index, i)">
                                                                                    <img class="icon" src="/img/icons/dialexa-icons/trash.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete Segment" > 
                                                                                </button>
                                                                            </td>
                                                                        </tr>                      
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <h6>No segments have been created yet. 
                                                                <a :href="'/segment/'+client.url+'/new?dataset='+dataset.id">Click here to make a new segment.</a>
                                                            </h6> 
                                                        </div>
                                                    </div>        
                                                </div>
                                            </div>
                                        </div>
                                    </transition-group>
                                </div>
                            </div>                              
                        </div>
                        <h5 class="mt-3" v-else>There are currently no data sets created for this connection.</h5>

                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="preview-modal" aria-labelledby="preview-modal" aria-hidden="true">
            <div class="modal-dialog " :class="modal_size">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end" data-bs-dismiss="preview-modal" @click="closePreviewModal()">
                                <i class="fa fa-close"></i>
                            </button>
                            <button type="button" class="btn btn-none float-end" @click="toggleModalSize()">
                                <i class="fa" :class="(modal_size == 'modal-xl' ? 'fa-expand' : 'fa-compress')"></i>
                            </button>
                            <h4 class="mt-2">Preview File Contents</h4>
                        </div>
                    </div>
                    <div class="modal-body p-0">
                        <div v-if="typeof table_data === 'string' && table_data == ''" class="m-2 mb-4 text-center">
                            <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>
                            <span class="ms-2">Loading...</span>
                        </div>
                        <div v-else-if='Array.isArray(table_data) && table_data.length > 0'>
                            <div class="table-responsive gy-0 m-0 mt-0">
                                <table class="table gy-0 m-0">
                                    <thead class="table-dark">
                                        <tr>
                                            <th v-for="(row, index) in Object.keys(table_data[0])" class='align-middle pt-0'
                                                :class="(index==0) ? 'ps-3' : ''">{{originalHeader(row)}}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-border-bottom-0">
                                        <tr v-for="row in table_data" >
                                            <td v-for="(col, index) in row" :class="(index==0) ? 'ps-3' : ''">{{ col }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else-if='Array.isArray(table_data) && table_data.length == 0'>
                            <div class="alert alert-warning box-content m-2"  >
                                No data was returned.
                            </div>
                        </div>
                        <div v-else>
                            <div class="alert alert-danger box-content m-2" >
                                There was an error pulling the data:<br>
                                <em>{{table_data}}</em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <system-modal :client="client" :integrations="[]" :connections="[]" :connection_selected="connection_selected"></system-modal>
        <support-modal :user="user" :client="client" :selected_ticket_type="selected_ticket_type" :id="'support-modal-'+selected_ticket_type"></support-modal>
    </div>
</template>

<script>

    export default {
        props: ['user', 'client', 'selected_connection_or_file'], 
        data() {
            return {
                source_types: [],
                file_or_connection: null,
                local_connection: null,
                local_file: null, // file passed from props
                local_files: [], //files returned from search pattern

                local_datasets: [],
                active_tab_index: 0,
                if_editing_dataset_name: false,
                errors: {
                    name: ''
                },
                if_expand_segment_section: false,
                if_expand_file_information: false,
                if_expand_inclusion_filters: false,
                if_expand_column_mappings: false,

                // File preview data modal
                modal_size: 'modal-xl',
                table_data: null,
                current_file: null,

                // segment table search and sort
                search_key: '',
                sort_key: null,
                sort_order: 'asc',

                
                // For Data Set Mapping Accordion
                information_form: {
                    error: false,
                    busy: false
                },
                inclusion_filter_form:{
                    error: false,
                    busy: false
                },
                column_mapping_form:{
                    error: false,
                    busy: false
                },
                errors: {
                    name: '',
                    category: '',
                    pattern: '',
                    filter: ''
                },
                // dest_columns: [],
                if_dest_columns_loaded: false,
                // source_types: [],
                // source_types_html: [],
                connection_selected:{},

                selected_ticket_type: ''
            };
        },
        beforeMount() {

            const params = new URLSearchParams(window.location.search);
            if(params.get('connection') != null){
                this.file_or_connection = 'connection';
                this.local_connection = this.selected_connection_or_file;
                this.local_datasets = JSON.parse(JSON.stringify(this.selected_connection_or_file.datasets));
            } else {
                this.file_or_connection = 'file';
                this.local_file = this.selected_connection_or_file;
                if (this.selected_connection_or_file.dataset)
                    this.local_datasets = [JSON.parse(JSON.stringify(this.selected_connection_or_file.dataset))];
            }
            if(params.get('connection') == null || this.isFileConnection()){
                //Make a null settings into an object
                for(let i = 0; i < this.local_datasets.length; i++ ){
                    //Loop through the local datasets

                    if(this.local_datasets[i].settings == null) {
                        this.local_datasets[i].settings = {ignore_unused: true, refresh_type: 'full', id_columns: [], pattern: ''};
                    }else if(this.local_datasets[i].settings.ignore_unused == null)
                        this.local_datasets[i].settings.ignore_unused = true;
                }
                //Only get the first DS's files
                this.refreshFiles();
            }
            window.axios.get('/api/integration/data_source/data_set')
                .then(response => {
                    if(response.status == 200) {
                        // console.log(response.data);
                        this.source_types = response.data.options;
                        this.constructDestColumnArray();
                    }
                });
        },
        computed: {
            id_columns() {
                //Loop through the local_dataset.settings.id_columns array (if it isn't null)
                //and return the column names
                if(this.local_datasets[this.active_tab_index].settings.id_columns == null || this.local_datasets[this.active_tab_index].source_columns == null)
                    return '';
                else {
                    var col =this.local_datasets[this.active_tab_index].source_columns[this.local_datasets[this.active_tab_index].settings.id_columns[0]];;
                    for(var i = 1; i < this.local_datasets[this.active_tab_index].settings.id_columns.length; i++) {
                        col += ", " + this.local_datasets[this.active_tab_index].source_columns[this.local_datasets[this.active_tab_index].settings.id_columns[i]];
                    }
                    return col;
                }
            }
        },
        methods: {
            isFileConnection() {
                //SFTP, Remote SFTP, or File Upload
                return this.selected_connection_or_file.integration_id == "7dbce608-4906-4606-977c-32ab1259b2e6" || this.selected_connection_or_file.integration_id == "397b5b86-e954-495f-8fdb-513885f53251" || this.selected_connection_or_file.integration_id == "71b0c3b0-8390-49ec-9984-1c04b7384d38";
            },
            constructDestColumnArray(){
                for (var i = 0; i < this.local_datasets.length; i++){
                    //Set the fake "source type id" column and the types of columns
                    if(this.local_datasets[i].source_type != null){
                        //Foreach to loop through the dest_columns object
                        for (const key in this.source_types) {
                            //Need to match on source type and source subtype (which can be null)
                            if(this.source_types[key][0].source_type == this.local_datasets[i].source_type &&
                                (this.local_datasets[i].source_subtype == null &&
                                    this.source_types[key][0].source_subtype == null ||
                                    this.local_datasets[i].source_subtype == this.source_types[key][0].source_subtype)){

                                    this.local_datasets[i].source_type_id = key;
                                    this.local_datasets[i].dest_columns = this.source_types[key];
    
                            }
                        }
                        if(Array.isArray(this.local_datasets[i].columns) && this.local_datasets[i].columns.length == 0)
                            this.local_datasets[i].columns = null;
                    }

                    //If the source_columns isn't set...
                    if(this.local_datasets[i].source_columns == null && this.local_files.length >= 1) {
                        this.local_datasets[i].source_columns = this.local_files[0].columns;
                    }
                }  
                this.if_dest_columns_loaded = true;
                this.$forceUpdate();
            },
            searchSegment(dataset_index){
                if(this.search_key == "") {
                    if(this.file_or_connection == 'connection'){
                        this.local_datasets[dataset_index].segments = [...this.selected_connection_or_file.datasets[dataset_index].segments]
         
                    } else {
                        this.local_datasets[dataset_index].segments = [...this.selected_connection_or_file.dataset.segments]
                    }         
                } else { // for orgs with small file list
                    this.local_datasets[dataset_index].segments = this.local_datasets[dataset_index].segments.filter(option => option.name.toLowerCase().includes(this.search_key.toLowerCase())); 
                } 
            },
            sortByKey(key, dataset){
                this.sort_key = key;
                if (this.sort_key != key){
                    this.sort_order = 'asc'; // reset to ascending sorting order
                } else{
                    this.sort_order = (this.sort_order == 'asc'? 'des':'asc');
                }
                this.sortDataWithLabel(dataset);
            },
            sortDataWithLabel(dataset){
                if(this.sort_order == 'asc')
                    dataset.segments.sort((a, b) => (a[this.sort_key ] > b[this.sort_key ]) ? 1 : -1);
                else
                    dataset.segments.sort((a, b) => (a[this.sort_key ] < b[this.sort_key ]) ? 1 : -1);
            },
            refreshFiles() { // for files only
                this.errors.pattern = "";
                if(this.local_datasets[this.active_tab_index] && this.local_datasets[this.active_tab_index].settings.pattern != "")
                    window.axios.post('/api/files/' + this.client.url + '/list_by_pattern',
                        {'pattern': this.local_datasets[this.active_tab_index].settings.pattern }).then(response => {

                        this.local_files = response.data.files;
                        if(this.local_files.length > 0)
                            this.local_datasets[this.active_tab_index].source_columns = this.local_files[0].columns;
                        this.$forceUpdate();

                      });
                else {
                    this.errors.pattern = "You must specify a pattern to identify current and future files with.";
                    this.local_files = [];
                }
            },
            canSync(){
                //The SFTPs
                if(this.local_connection.integration_id == '7dbce608-4906-4606-977c-32ab1259b2e6' || this.local_connection.integration_id == '397b5b86-e954-495f-8fdb-513885f53251')
                    return false
                //File upload
                if(this.local_connection.integration_id == '71b0c3b0-8390-49ec-9984-1c04b7384d38')
                    return false;

                return this.local_connection.active_jobs.length == 0
            },
            canDelete(){
                //Can't delete the File Upload connection
                return this.local_connection.integration_id != '71b0c3b0-8390-49ec-9984-1c04b7384d38';
            },
            setActiveTab(index){
                this.active_tab_index = index;
                this.changeDatasetSelection();
                this.resetErrors();
            },
            resetErrors(){
                this.information_form = {
                    error: false,
                    busy: false
                };
                this.inclusion_filter_form = {
                    error: false,
                    busy: false
                };
                this.column_mapping_form = {
                    error: false,
                    busy: false
                };
            },
            columnsValid() {
                // If it is incremental, all of the local files columns need to match (or be in the most recent one)
                if(this.local_datasets[this.active_tab_index].settings && this.local_datasets[this.active_tab_index].settings.refresh_type == 'incremental' && this.local_files.length > 1){
                    //Check if the URL has "mapping/file" in it
                    var recent_file = this.local_files[0];
                    var file_id = -1;
                    if(window.location.pathname.indexOf("mapping/file") != -1){
                        //Get the fileID from the URL and update the "recent_file" with that file
                        var split = window.location.pathname.split("/");
                        file_id = split[split.length - 1];
                        for(var i = 0; i < this.local_files.length; i++)
                            if(this.local_files[i].id == file_id){
                                recent_file = this.local_files[i];
                                break;
                            }
                    }

                    //Get an array of the keys of the recent_file.columns object
                    var compare_columns = Object.keys(recent_file.columns);
                    if(this.local_datasets[this.active_tab_index].settings.ignore_unused){
                        compare_columns = this.getUsedColumns();
                    }

                    //Look through all of the files
                    for(var i = 0; i < this.local_files.length; i++){
                        //Don't worry about the files we're comparing it to
                        if(this.local_files[i].id == recent_file.id)
                            continue;

                        //Loop through each of the columns
                        for (let key in compare_columns) {
                            //If it isn't in the current file's list of columns, throw an error
                            if (!(compare_columns[key] in this.local_files[i].columns)){
                                var file_language = "the most recent";
                                if(file_id >= 0)
                                    file_language = "your selected";
                                this.errors.pattern = "The files identified by the given pattern have one or more columns missing when compared to "+file_language+" file. Please update the pattern or delete the files identified in the table.";
                                this.information_form.error = true;
                                this.local_files[i].status_msg = "MISMATCH";
                                break;
                            }
                            else this.local_files[i].status_msg = "";
                        }
                    }
                } else {
                    //Reset the status message of the files in case they had previously been flagged
                    for(var i = 0; i < this.local_files.length; i++)
                        this.local_files[i].status_msg = "";
                }

                return !this.information_form.error;

            },
            getUsedColumns(){
                // Get all of the mapped columns from the column definitions relationship
                let columns = this.local_datasets[this.active_tab_index].columns;
                let arr = [];

                // If there are identified ID columns
                let settings = this.local_datasets[this.active_tab_index].settings;
                if (settings.id_columns && settings.refresh_type && settings.refresh_type === 'incremental') {
                    // Merge the array into arr
                    arr = [...arr, ...settings.id_columns];
                }

                columns.forEach(c => {
                    // If it isn't a custom definition
                    if (c.source_column !== 'CUSTOM') {
                        // Insert it only if it isn't already in the array (and it has been mapped)
                        if (!arr.includes(c.source_column) && c.source_column) {
                            arr.push(c.source_column);
                        }
                    } else {
                        let logic = c.column_logic;
                        // Check each unit
                        logic.units.forEach(unit => {
                            // Check the value type
                            if (unit.value_type === 'column' && !arr.includes(unit.value)) {
                                arr.push(unit.value);
                            }

                            // Now loop through the logic units
                            unit.logic.logic_units.forEach(u => {
                                // If the right side of the equation is a column mapping
                                if (!u.is_static && !u.show_options && !arr.includes(u.value.value)) {
                                    arr.push(u.value.value);
                                }
                                // Get the left side of the equation
                                if (!arr.includes(u.db_column.value)) {
                                    arr.push(u.db_column.value);
                                }
                            });
                        });

                        // Check the default type
                        if (logic.default_type === 'column' && !arr.includes(logic.default_value)) {
                            arr.push(logic.default_value);
                        }
                    }
                });

                return arr;

                },
            changeDatasetSelection(){
                this.if_editing_dataset_name = false;
                this.search_key = '';
                this.sort_key = null;
                this.sort_order = 'asc';

                if(this.file_or_connection == 'connection'){
                    // this.local_connection = this.selected_connection_or_file;
                    this.local_datasets = [...this.selected_connection_or_file.datasets];
                } else {
                    // this.local_file = this.selected_connection_or_file;
                    if (this.selected_connection_or_file.dataset)
                        this.local_datasets = [this.selected_connection_or_file.dataset];
                }
                if(this.file_or_connection == 'file' || this.isFileConnection())
                    this.refreshFiles();
            },
            updateIsPrimary(set) {
                set.is_primary = true;
                this.saveDataSet(set);

                // var new_status = !set.is_primary;
                for(var i = 0; i < this.local_datasets.length; i++) {
                    if(this.local_datasets[i].id != set.id && this.local_datasets[i].source_type == set.source_type && (this.local_datasets[i].source_subtype == set.source_subtype)) {
                        this.local_datasets[i].is_primary = false; //Only set one dataset under the same source_type and source_subtype to true, the rest should all be false
                        this.saveDataSet(this.local_datasets[i]);
                    }
                }

                Swal.fire({
                    html: '<h4 class="mb-0 text-white">Primary Data Set Updated!</h4>',
                    target: '#org_settings_connections-view-dataset',
                    icon: 'success',
                    toast: true,
                    position: 'bottom-right',
                    showConfirmButton: false,
                    timer: 2000,
                    iconColor: 'white',
                    customClass: {
                        popup: 'bg-primary'
                    },
                });      
            },
            saveDataSet(set) {
                //Make a deep copy of the dataset so that I can remove the columns to prevent them from being saved
                set = JSON.parse(JSON.stringify(set));
                //Unset the columns
                set.columns = null;

                //Post to the api end point to update the dataset
                window.axios.put('/api/data_set/'+this.client.url + '/' + set.id, set).catch(error => {
                        Swal.fire({
                            html: '<h4 class="mb-2 text-white">Error saving the data set!</h4><p class="mb-0">' + 
                                error.response.data.message + "</p>",
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 3000,
                            customClass: {
                                popup: 'bg-danger'
                            },
                        });
                    });;
            },
            toggleEditName(){
                this.if_editing_dataset_name = true;
            },
            cancelEditName(index){
                this.if_editing_dataset_name = false;

                if(this.local_connection){
                    this.local_datasets[index].name = this.local_connection.datasets[index].name;
                } else {
                    this.local_datasets[index].name = this.local_file.dataset.name;
                }
            },
            saveDataSetName(index){
                if(this.local_datasets[index].name != ''){
                    this.if_editing_dataset_name = false;
                    this.saveDataSet(this.local_datasets[index]);                    
                } else {
                    this.errors.name = "The Data Set name cannot be empty";
                    return;
                }
            },
            saveInformation(dataset, index){
                if(dataset.name == "") {
                    this.errors.name = "Please enter a name for this data set.";
                    this.information_form.error = true;
                }else if((dataset.source != 'Airbyte' && dataset.source != 'Table') && dataset.settings.pattern == '') {
                    this.errors.pattern = "Please enter a file pattern to use to identify subsequently uploaded files.";
                    this.information_form.error = true;
                } else if(dataset.source != 'Airbyte' && dataset.source != 'Table' && this.local_files.length == 0 ) {
                    this.errors.pattern = "The current pattern doesn't have any matching files. Please update it to match files that have already been uploaded.";
                    this.information_form.error = true;
                } else {
                    this.information_form.error = false;   
                }
                
                if(!this.information_form.error){
                    //If something has changed, update the dataset
                    if(this.datasetHasChanged(dataset, index)){
                        this.information_form.busy = true;

                        //Make a deep copy of the dataset so that I can remove the columns to prevent them from being saved
                        dataset = JSON.parse(JSON.stringify(dataset));
                        //Unset the columns
                        dataset.columns = null;

                        window.axios.put('/api/data_set/'+this.client.url+ '/' + dataset.id, dataset).then(response => {
                            this.local_datasets[index] = response.data.data;
                            this.information_form.busy = false;
                            Swal.fire({
                                title: "Data Set Updated!",
                                text: "This Data Set information has been updated.",
                                icon: "success",
                                iconColor:  "#25473E",
                                confirmButtonColor: "#25473E"
                            });
                            var myCollapseElement = document.getElementById('dataset-file-information');
                            var bsCollapse = new bootstrap.Collapse(myCollapseElement, {
                                toggle: false
                            });
                            bsCollapse.hide();
                            this.$forceUpdate();
                        });
                    }
                }
            },
            datasetHasChanged(updated_dataset, index) {
                var saved_dataset = (this.file_or_connection == 'connection'? this.local_connection.datasets[index]: this.local_file.dataset);

                //If any of the following are different from the dataset prop
                if(updated_dataset.settings.ignore_unused != saved_dataset.ignore_unused)
                    return true;
                if(updated_dataset.settings.pattern != saved_dataset.pattern)
                    return true;
                if(updated_dataset.settings.refresh_type != saved_dataset.refresh_type)
                    return true;
                //ID_Columns is an array, so we need to compare the contents
                if(!this.arraysEqual(updated_dataset.settings.id_columns, saved_dataset.id_columns))
                    return true;

            },
            arraysEqual(arr1, arr2) {
                if (arr1.length !== arr2.length) return false;
                arr1 = arr1.slice().sort();
                arr2 = arr2.slice().sort();
                for (let i = 0; i < arr1.length; i++) {
                    if (arr1[i] !== arr2[i]) return false;
                }
                return true;
            },
            saveInclusionFilters(dataset, index){
                //Validate the query builder
                if(!this.$refs.filter[index].isFormValid())
                    this.inclusion_filter_form.error = true;

                else {
                    this.inclusion_filter_form.error = false;
                    //if(this.filterHasChanged(dataset, index)){
                    this.inclusion_filter_form.busy = true;
                    //Make a deep copy of the dataset so that I can remove the columns to prevent them from being saved
                    dataset = JSON.parse(JSON.stringify(dataset));
                    //Unset the columns
                    dataset.columns = null;

                    window.axios.put('/api/data_set/'+this.client.url+ '/' + dataset.id, dataset).then(response => {
                        this.local_datasets[index] = response.data.data;
                        this.inclusion_filter_form.busy = false;
                        Swal.fire({
                            title: "Data Set Updated!",
                            text: "The inclusion filters have been updated. Your data will begin to reprocess shortly to incorporate these changes.",
                            icon: "success",
                            iconColor:  "#25473E",
                            confirmButtonColor: "#25473E"
                        });
                        var myCollapseElement = document.getElementById('dataset-inclusion-filters');
                        var bsCollapse = new bootstrap.Collapse(myCollapseElement, {
                            toggle: false
                        });
                        bsCollapse.hide();
                        this.$forceUpdate();


                    }).catch(error => {
                        Swal.fire({
                            html: '<h4 class="mb-2 text-white">Error saving the data set!</h4><p class="mb-0">' + 
                                error.response.data.message + "</p>",
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 3000,
                            customClass: {
                                popup: 'bg-danger'
                            },
                        });
                    });
                }
            },
            filterHasChanged(updated_dataset, index) {
                var saved_dataset = (this.file_or_connection == 'connection'? this.selected_connection_or_file.datasets[index]: this.selected_connection_or_file.dataset);
                return !this.deepEqual(saved_dataset.filter, updated_dataset.filter);
            },
            deepEqual(obj1, obj2){
                if (obj1 === obj2) return true;

                if (typeof obj1 !== 'object' || obj1 === null ||
                    typeof obj2 !== 'object' || obj2 === null) {
                    return false;
                }

                let keys1 = Object.keys(obj1);
                let keys2 = Object.keys(obj2);

                if (keys1.length !== keys2.length) return false;

                for (let key of keys1) {
                    if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
                        return false;
                    }
                }

                return true;
            },
            saveColumnMapping(dataset, index){
                this.column_mapping_form.error = false;

                //Validate the query builder
                if(!this.$refs.column_mapping[index].isValid())
                    this.column_mapping_form.error = true;
                else if(!this.columnsValid()){
                    this.column_mapping_form.error = true;
                    Swal.fire({
                        title: "Error",
                        html: "The columns that you utilized in your column mapping aren't available with all of the matching files. Please delete the files noted in the table or remove the new mappings not present in those files.",
                        icon: "warning",
                        iconColor:  "#D11F1F",
                        confirmButtonColor: "#D11F1F"
                    });
                }
                else {
                    this.column_mapping_form.busy = true;

                    window.axios.put('/api/data_set/'+this.client.url + '/' + dataset.id, dataset)
                        .then(response => {
                            this.local_datasets[index] = response.data.data;
                            this.column_mapping_form.busy = false;
                            Swal.fire({
                                title: "Success!",
                                html: "The data set <strong>"+dataset.name +"</strong> was updated. The data will be processed shortly and can then be used for analysis and keeping your various systems in sync.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            });
                            var myCollapseElement = document.getElementById('dataset-column-mappings');
                            var bsCollapse = new bootstrap.Collapse(myCollapseElement, {
                                toggle: false
                            });
                            bsCollapse.hide();
                    }).catch(error => {
                        Swal.fire({
                            html: '<h4 class="mb-2 text-white">Error saving the data set!</h4><p class="mb-0">' + 
                                error.response.data.message + "</p>",
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 3000,
                            customClass: {
                                popup: 'bg-danger'
                            },
                        });
                    });
                      
                }
            },
            deleteSegment(seg, dataset_index, segment_index) {
                //Show a warning
                Swal.fire({
                  title: "Are you sure you want to delete this segment?",
                  text: "The segment will no longer be available for data analysis and any connections with 3rd party systems will also be removed.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.delete('/api/segment/'+this.client.url + '/' + seg.id)
                        .then(response => {
                            this.local_datasets[dataset_index].segments.splice(segment_index, 1);
                            Swal.fire({
                                title: "Deleted!",
                                text: "This segment has been removed.",
                                icon: "success",
                                iconColor:  "#25473E",
                                confirmButtonColor: "#25473E"
                            });
                            this.$forceUpdate();
                        });
                    }
                });
            },
            deleteConnection() {

                Swal.fire({
                  title: "Are you sure you want to delete this connection?",
                  text: "Deleting the connection will remove the connection and all associated data.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.axios.delete('/api/connection/' + this.local_connection.id).then(response => {
                        //Find the connection in the local_connections
                        Swal.fire({
                            title: "Deleted!",
                            text: "The asset has been removed.",
                            icon: "success",
                            iconColor:  "#46705F",
                            confirmButtonColor: "#46705F"
                        }).then(response => {
                            this.$emit('backToSelection')
                        });
                    }).catch(error => {
                        // Handle errors here
                        Swal.fire({
                            title: "Error!",
                            text: error.response.data.message,
                            icon: "error",
                            iconColor:  "#D11F1F",
                            confirmButtonColor: "#D11F1F"
                        });
                    });
                }
                });
            },
            syncConnection() {
                window.axios.get('/api/connection/' + this.local_connection.id + '/refresh').then(response => {
                    //This will change the status
                    this.local_connection.active_jobs = ["something"];
                    this.$forceUpdate();

                    Swal.fire({
                        title: "Success!",
                        text: "The connection will begin refreshing momentarily.",
                        icon: "success",
                        iconColor:  "#46705F",
                        confirmButtonColor: "#46705F"
                    });
                }).catch(error => {
                    // Handle errors here
                    Swal.fire({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        iconColor:  "#D11F1F",
                        confirmButtonColor: "#D11F1F"
                    });
                });
            },
            updateConnection(){
                this.connection_selected = this.local_connection;
            },
            isDataFile(file) {
                // If the file type is a csv, tab, tsv, or txt
                return file.status == 'ready' && ['csv', 'tsv', 'tab', 'txt'].includes(file.type);
            },
            previewTable(file) {
                var self = this;
                this.current_file = file;
                this.table_data = "";

                window.axios.post('/api/bigquery/get_detailed_samples', {'data_source': 'file', 'data_source_id': file.id }).then(response => {
                    if(response.status == 200) {
                        self.table_data = response.data.samples;
                    }
                    else
                        self.table_data = response.data.error;
                  });
            },
            closePreviewModal() {
                var myModalEl = document.getElementById('preview-modal')
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },            
            toggleModalSize() {
                if(this.modal_size == 'modal-fullscreen')
                    this.modal_size = 'modal-xl';
                else
                    this.modal_size = 'modal-fullscreen';
            },
            originalHeader(value) {
                //Do a foreach loop through this.current_file.columns with a key/val
                for (var key in this.current_file.columns) {
                    if(key == value)
                        return this.current_file.columns[key];
                }
                return value;
            },
            withinThirtyDays(file) {
                //If the file.created_at is within the last 30 days
                return moment().subtract(30, 'days').isBefore(file.created_at) && file.gcs_processed_path != null;
            },
            removeFile(file) {
                var self  = this;
                Swal.fire({
                  title: "Are you sure you want to delete <em>" + file.name + "</em>?",
                  text: "This will remove the file from the system and prevent it from being used with future data processes.",
                  icon: "warning",
                  iconColor: "#A61C3C",
                  showCancelButton: true,
                  confirmButtonColor: "#A61C3C",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.delete('/api/files/' + self.client.url + '/destroy/' + file.id)
                            .then(response => {
                                if(response.status == 200) {
                                    window.location = '/settings/v1/' + this.client.url + '/files';
                                }
                            });
                    }
                });
            },
            openSupportModal(){
                this.selected_ticket_type='SETUP_HELP';
                // var modal = new bootstrap.Modal(document.getElementById('support-modal-'+this.selected_ticket_type));
                // modal.show();
            }
        }
    }
</script>
