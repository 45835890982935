<template>
    <div :class="local_chart.type != 'table' && local_chart.type != 'scorecard' && local_chart.type != 'textbox' ? 'chart-template' : 'table-template'" :data-chart-id="local_chart_id">
        <scorecard-template v-if="local_chart.type=='scorecard'" :chart_selected="local_chart" :if_refreshing="if_refreshing"></scorecard-template>

        <pie-chart-template v-if="local_chart.type=='pie'" :chart_selected="local_chart" :id="local_chart_id" :if_refreshing="if_refreshing" 
        :if_on_report_page="if_on_report_page" :if_benchmark_report="if_benchmark_report" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart"></pie-chart-template>     

        <line-chart-template v-if="local_chart.type=='line' || local_chart.type=='cumulative_line' " :chart_selected="local_chart" :id="local_chart_id" :if_refreshing="if_refreshing" 
        :if_on_report_page="if_on_report_page" :if_benchmark_report="if_benchmark_report" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart"></line-chart-template>

        <bar-line-combo-chart-template v-if="local_chart.type=='bar_line'" :chart_selected="local_chart" :id="local_chart_id" :if_refreshing="if_refreshing" 
        :if_on_report_page="if_on_report_page" :if_benchmark_report="if_benchmark_report" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart"></bar-line-combo-chart-template>

        <column-chart-template v-if="local_chart.type=='column'" :chart_selected="local_chart" :id="local_chart_id" :if_refreshing="if_refreshing" 
        :if_on_report_page="if_on_report_page" :if_benchmark_report="if_benchmark_report" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart" ></column-chart-template>

        <stacked-column-chart-template v-if="local_chart.type=='stacked_column'" :chart_selected="local_chart" :id="local_chart_id" :if_hundred_percent="false" 
        :if_refreshing="if_refreshing" :if_benchmark_report="if_benchmark_report" :if_on_report_page="if_on_report_page" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart"></stacked-column-chart-template>

        <stacked-column-chart-template v-if="local_chart.type=='100_percent_stacked_column'" :chart_selected="local_chart" :id="local_chart_id" :if_hundred_percent="true" 
        :if_refreshing="if_refreshing" :if_on_report_page="if_on_report_page" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart"></stacked-column-chart-template>

        <horizontal-bar-chart-template v-if="local_chart.type=='horizontal_bar'" :chart_selected="local_chart" :id="local_chart_id" :if_refreshing="if_refreshing" 
        :if_on_report_page="if_on_report_page" :if_benchmark_report="if_benchmark_report" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart"></horizontal-bar-chart-template>

        <stacked-horizontal-bar-chart-template v-if="local_chart.type=='stacked_bar'" :chart_selected="local_chart" :id="local_chart_id" :if_hundred_percent="false" 
        :if_refreshing="if_refreshing" :if_benchmark_report="if_benchmark_report" :if_on_report_page="if_on_report_page" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart"></stacked-horizontal-bar-chart-template>

        <stacked-horizontal-bar-chart-template v-if="local_chart.type=='100_percent_stacked_bar'" :chart_selected="local_chart" :id="local_chart_id" :if_hundred_percent="true" 
        :if_refreshing="if_refreshing" :if_benchmark_report="if_benchmark_report" :if_on_report_page="if_on_report_page" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart"></stacked-horizontal-bar-chart-template>

        <table-template v-if="local_chart.type=='table'" :chart_selected="local_chart" :id="local_chart_id" :if_refreshing="if_refreshing" 
        :if_on_report_page="if_on_report_page" :if_benchmark_report="if_benchmark_report" :applied_chart_filter="applied_chart_filter" @exploreChart="exploreChart"></table-template>

        <div v-if="chart.type=='textbox'" class="textbox-template">
            <div v-if="chart.html != ''">
                <div v-html="decodeURIComponent(chart.html)"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            chart: {default: {} },
            id: {default: null},
            if_refreshing: {default: false},
            if_on_report_page: {default: false },
            applied_chart_filter: {default: false },
            if_benchmark_report: {default: false },
        },
        data() {
            return {
                local_chart_id: null,
                local_chart: {}
            };
        },
        watch:{
            chart(new_value, old_value){
                this.local_chart = this.chart;
                if (this.local_chart.type == 'cumulative_line' && this.local_chart.data){
                    this.computeCumulativeValues(); 
                }
            },
        },
        computed:{
            
        },
        beforeMount() {
            if (!this.id){
                this.local_chart_id = this.chart.id
            }else {
                this.local_chart_id = this.id
            }
            this.local_chart = this.chart;
            if (this.local_chart.type == 'cumulative_line' && this.local_chart.data){
                this.computeCumulativeValues(); 
            }
        },
        methods: {
            exploreChart(data){
                this.$emit('exploreChart', data)
            },
            computeCumulativeValues(){
                Object.keys(this.local_chart.data).forEach((key,index) => {
                    var dataset_temp = [];
                    var temp; 
                    for (var i = 0; i< this.local_chart.data[key].length; i++){
                        
                        if (i != 0){
                            temp = temp + this.local_chart.data[key][i];
                        }else{
                            temp = this.local_chart.data[key][i];
                        }
                        dataset_temp.push(temp);
                    }

                    this.local_chart.data[key] = [...dataset_temp];
                }); 
            },
        }
    }
</script>
