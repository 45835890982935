<template>
    <div class="line-chart-template">
        <canvas :id="'chart-'+this.id" :class="(if_chart_has_data ? '':'hide')" class="line-chart chart-template" ></canvas>
        <div :class="(!if_chart_has_data ? 'd-inline-block':'hide')" class='text-center'>
            <h1 class="text-secondary_v1 mb-1"><i class="fa-solid fa-chart-simple"></i></h1>
            <h5 class="text-secondary_v1 mb-2"><em>Press the <strong>Preview</strong> button to render the chart</em></h5>
        </div>
    </div>
</template>

<script>

   import ChartDataLabels from 'chartjs-plugin-datalabels';

   export default {
       props: ['chart_selected', 'id', 'if_refreshing', 'if_on_report_page', 'if_benchmark_report', 'applied_chart_filter'],
       data() {
           return {
               chart: null,
               color: null,
               formatType: null,
               if_chart_has_data: false,
               if_chart_filter_applied: false,
               chart_filter_label: '',
               num_of_datasets: null,
               font_size: 12,
               max_value: 0,
           };
       },
       watch:{
            chart_selected: {
                handler: function(newVal, oldVal) {
                    // Since chart_selected is a complex object, you might want to perform
                    // a more specific check to determine if renderChart should be called.
                    // For now, we'll just check if it has 'data' to decide.
                    if (newVal.data) {
                        if(this.chart_selected.metric_formats[0] != undefined){
                            this.formatType = this.chart_selected.metric_formats[0];
                        } else{
                            this.formatType = 'number';
                        }
                        if(this.chart_selected.metrics[0] != undefined && this.chart_selected.metrics[0].color){
                            this.color = this.chart_selected.metrics[0].color;
                        } else{
                            this.color = 'yellow';
                        }
                        this.num_of_datasets= Object.keys(this.chart_selected.data).length;
                        this.renderChart();
                        this.if_chart_has_data = true;
                    }
                },
                deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
            if_refreshing:{
                handler: function(newVal, oldVal) {
                    this.$forceUpdate();
                }
            },
            applied_chart_filter:{
                handler: function(newVal, oldVal) {
                    this.if_chart_filter_applied = this.applied_chart_filter.if_chart_filter_applied && this.id == this.applied_chart_filter.section_chart_id;
                    this.renderChart();
                }
            }
        },
       mounted() {
            var export_report_class = document.querySelector('.export-report');
            if (export_report_class){ // if on export view, make font bigger, set to 15 for now
                this.font_size = 15;
            }
            if (this.chart_selected.data){
                // loop through metric_objects in chart to find format 
                if(this.chart_selected.metric_formats[0] != undefined){
                    this.formatType = this.chart_selected.metric_formats[0];
                    this.color = this.chart_selected.metrics[0].color;
                } else{
                    this.formatType = 'number';
                    this.color = 'yellow';
                }
                this.num_of_datasets= Object.keys(this.chart_selected.data).length;                
                this.renderChart();
                this.if_chart_has_data = true;

            }
       },
       methods: {
            renderChart() {
                var dataset = this.getChartData(); 
                var options = this.getChartOptions(dataset); 

                if(this.chart != null)
                    this.chart.destroy();

                var ctx = document.getElementById('chart-'+this.id);
                if(ctx != undefined)
                    this.chart = new Chart(ctx, options);
            },
            getColor(color) { // colors are from dark to light shades
                const colors = {
                    yellows: [
                        'rgb(220, 150, 0)',
                        'rgb(235, 181, 0)',
                        'rgb(255, 203, 32)',
                        'rgb(255, 219, 102)',
                        'rgb(255, 229, 145)',
                        'rgb(255, 236, 174)',
                    ],
                    greens: [
                        'rgb(10, 110, 62)',
                        'rgb(15, 166, 92)',
                        'rgb(25, 207, 120)',
                        'rgb(69, 223, 149)',
                        'rgb(107, 243, 178)',
                        'rgb(167, 248, 209)',
                    ],
                    blues: [
                        'rgb(9, 71, 137)',
                        'rgb(10, 89, 250)',
                        'rgb(51, 149, 255)',
                        'rgb(122, 186, 255)',
                        'rgb(168, 204, 244)',
                        'rgb(211, 228, 247)',
                    ],
                    purples: [
                        'rgb(65, 1, 121)',
                        'rgb(133, 48, 209)',
                        'rgb(124, 82, 149)',
                        'rgb(183, 123, 244)',
                        'rgb(210, 168, 253)',
                        'rgb(223, 192, 255)',
                    ],
                    grays: [
                        'rgb(51, 51, 51)',
                        'rgb(77, 77, 77)',
                        'rgb(99, 99, 99)',
                        'rgb(120, 120, 120)',
                        'rgb(142, 142, 142)',
                        'rgb(168, 168, 168)',
                        'rgb(189, 189, 189)',
                    ],
                };
                if(color == 'yellow')
                    return colors.yellows;
                if(color == 'green')
                    return colors.greens;
                if(color == 'blue')
                    return colors.blues;
                if(color == 'purple')
                    return colors.purples;
                if(color == 'gray')
                    return colors.grays;
                return colors.yellows;
            },
            getChartData() {
                var datasetArray = [];
                var chartColors = this.getColor(this.color).slice(0, this.num_of_datasets);
                var element_on_scorecard = document.querySelector('.scorecard-page');
                var max = 0;

                if (this.num_of_datasets >1){
                    // multiple dataset
                    var chart_filter_label_index = this.chart_selected.labels.findIndex(label => label == this.chart_filter_label);  
                    Object.keys(this.chart_selected.data).forEach((key,index) => {
                        var max_temp = Math.max(...this.chart_selected.data[key]);
                        if (max_temp > max){
                            max = max_temp;
                        }
                        if (this.if_chart_filter_applied){
                            var backgroundColorArray = Array(this.chart_selected.data[key].length).fill(this.getColor('gray')[3]);
                            backgroundColorArray[chart_filter_label_index] = chartColors[0];
                            datasetArray.push({
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: backgroundColorArray,
                                borderColor: this.getColor('gray')[6-index],
                                borderWidth: this.if_benchmark_report? 3 : 1.5,
                                borderDash: this.if_benchmark_report? [10,5]: [],
                                borderRadius: 3,
                                type: 'line',
                                order: this.num_of_datasets - index + 1,
                                hidden: (this.num_of_datasets - index > 3)  //Only show the first 3 years by default
                            });  
                        } else if(this.chart_selected.highlight_last_value){
                            datasetArray.push({
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: (index== this.num_of_datasets-1)? chartColors[0]: this.getColor('gray')[6-index-1],
                                borderColor: (index== this.num_of_datasets-1)? chartColors[0]: this.getColor('gray')[6-index-1],
                                borderWidth: this.if_benchmark_report? 3 : 1.5,
                                borderDash: this.if_benchmark_report && (index!= this.num_of_datasets-1)? [10,5]: [],
                                borderRadius: 3,
                                type: 'line',
                                order: this.num_of_datasets - index + 1,
                                hidden: (this.num_of_datasets - index > 3) //Only show the first 3 years by default
                            })  
                        }else{
                            datasetArray.push({
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: chartColors[chartColors.length-index-1],
                                borderColor: chartColors[chartColors.length-index-1],
                                borderWidth: this.if_benchmark_report? 3 : 1.5,
                                borderDash: this.if_benchmark_report && (index!= this.num_of_datasets-1)? [10,5]: [],
                                borderRadius: 3,
                                type: 'line',
                                order: this.num_of_datasets - index + 1,
                                hidden: (this.num_of_datasets - index > 3) //Only show the first 3 years by default
                            })  
                        }
                    });
                }  else {
                    // single dataset           
                    const chartColor = this.getColor(this.color)[0];
                    var key = Object.keys(this.chart_selected.data)[0];
                    var backgroundColorArray = [];
                    var max_temp = Math.max(...this.chart_selected.data[key]);
                    if (max_temp > max){
                        max = max_temp;
                    }

                    if (element_on_scorecard && this.chart_selected.data[key].length >=3){
                        backgroundColorArray = Array(this.chart_selected.data[key].length).fill(chartColor);
                        for(var i = backgroundColorArray.length - 1; i >= backgroundColorArray.length - 3; i--) {
                            backgroundColorArray[i] = 'rgba(' + chartColor.substring(4, chartColor.length - 1) + ', 0.5)';
                        }
                    } else {
                        if (this.if_benchmark_report){ // benchmark with only 1 dataset means the client's own data is not shown, disregard the highlight_last_value config
                            backgroundColorArray = Array(this.chart_selected.data[key].length).fill(chartColor);
                        }else if (this.if_chart_filter_applied){
                            var index = this.chart_selected.labels.findIndex(label => label == this.chart_filter_label);
                            backgroundColorArray = Array(this.chart_selected.data[key].length).fill(this.getColor('gray')[3]);
                            backgroundColorArray[index] = chartColor;
                        } else {
                            backgroundColorArray = Array(this.chart_selected.data[key].length).fill(chartColor);
                        }
                    }

                    datasetArray.push({
                        label: key==""? '(not set)':key,
                        data: this.chart_selected.data[key],
                        backgroundColor:  backgroundColorArray,
                        borderColor: backgroundColorArray,
                        borderWidth: this.if_benchmark_report? 3 : 1.5,
                        borderDash: this.if_benchmark_report? [10,5]: [],
                        borderRadius: 3,
                        type: 'line',
                        order: 1
                    })
                }

                this.max_value = max;
                return {
                    labels: this.chart_selected.labels,
                    datasets: datasetArray
                };
            },
            getChartOptions(data) {
                var self = this;
                var config = {
                    plugins: [ChartDataLabels],
                    type: 'line',
                    data: data,

                    //All of the special aspects that make these charts unique
                    options: {
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 30
                            }
                        },
                        animations: {
                            x: {
                                duration: 1000,
                            }
                        },
                        indexAxis: 'x',
                        scales: {
                            x: {
                                grid: {
                                    drawBorder: false,
                                    display : false
                                },
                                border: {
                                    display: false,
                                },
                                ticks: {
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                    maxRotation:90,
                                    autoSkip: true,
                                    // callback: function(value, index, ticks, context) {
                                    //     // Wrap around long labels
                                    //     var label_substrings = '';
                                    //     if(self.chart_selected.labels[value]){
                                    //         let label = self.chart_selected.labels[value] + "";
                                    //         label_substrings = label.split(" ");
                                    //     }
                                    //     for(var i = 0; i <label_substrings.length-1; i++){
                                    //         if ((label_substrings[i].length + 1 + label_substrings[i+1].length) < 15){ 
                                    //             // if two substrings have total length < 15, concat them together
                                    //             // Note: length=15 is just a temporary value which makes the chart looks fine with dummy data
                                    //             label_substrings[i] = label_substrings[i]+' '+label_substrings[i+1]
                                    //             label_substrings[i+1] = ''
                                    //         }
                                    //     }
                                    //     return label_substrings;
                                    // }
                                }
                            },
                            y: {
                                display: true,
                                position: 'left',
                                beginAtZero: true,
                                border: {
                                    display: false,
                                    width: 2,
                                    dash: [5,5],
                                    dashOffset: 0,
                                },
                                grid: {
                                    drawOnChartArea: true,
                                    drawBorder: false,
                                    
                                    color: 'rgba(206, 219, 215, 1)',
                                    lineWidth: 1, // gridline thickness
                                    borderDash: [2,2], // gridline in the chart area that is perpendicular to this axis

                                    drawTicks: true,
                                    tickColor: 'rgba(206, 219, 215, 1)',
                                    tickLength: 20,
                                    tickBorderDash: [2,2],
                                    tickBorderDashOffset: 0,
                                    z: -2,
                                },
                                //For the ticks on the axes, format the numbers to make the pretty
                                ticks: {
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                    offset: false,
                                    autoSkip: true,
                                    maxTicksLimit: 5,
                                    callback: function(value, index, ticks, context) {

                                        if(self.formatType == 'money' && value < 1000)
                                            return self.$options.filters.currency_with_zero(value);
                                        if(self.formatType == 'money')
                                            return self.$options.filters.currency_abbr(value);
                                        if(self.formatType == 'number' && value >= 1000)
                                            return self.$options.filters.num_abbr(value);
                                        if(self.formatType == 'number' && self.max_value >= 10)
                                            return self.$options.filters.number_with_zero(value);
                                        if(self.formatType == 'number')
                                            return self.$options.filters.number_with_decimal(value);
                                        if(self.formatType == 'percent')
                                            return self.$options.filters.percentage(value);
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                //Only show the legend if there is more than one media type
                                position: (data.datasets.length > 1) ? 'bottom' : 'none',
                                display: true,
                                align: 'start',
                                boxHeight: 40,
                                labels: {
                                    usePointStyle: true,
                                    // color: this.getColor(this.color)[0],
                                    pointStyle: 'line',
                                    pointStyleWidth: 16,
                                    lineWidth: 1, // remove box border by default
                                    hidden: false,
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                },
                                onHover: function(e,chart) {
                                    e.native.target.style.cursor = 'pointer';
                                },
                                onLeave: function(e,chart) {
                                    e.native.target.style.cursor = 'default';
                                },
                            },
                            tooltip: {
                                enabled: false,
                                external: function(context) {
                                    // Tooltip Element
                                    let tooltipEl = document.getElementById('chart-'+self.id+'-tooltip');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chart-'+self.id+'-tooltip';
                                        tooltipEl.classList.add("chart-template-tooltip");
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    const position = context.chart.canvas.getBoundingClientRect();
                                    // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

                                    // Display, position, and set styles for font
                                    const tooltipModel = context.tooltip;
                                    tooltipEl.style.opacity = 1;
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX - tooltipModel.width/2 + 'px';// center align
                                    tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY- tooltipModel.height + 'px';
                                    tooltipEl.style.backgroundColor = 'white';
                                    tooltipEl.style.padding = '5px';
                                    tooltipEl.style.boxShadow = '2px 4px 20px 0px #0000001A';
                                    tooltipEl.style.fontFamily = 'MDSystem-Regular';

                                    // Hide if no tooltip 
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        return;
                                    }
                                    tooltipEl.addEventListener('mouseover',()=>{
                                        tooltipEl.style.opacity = 1;
                                    });
                                    tooltipEl.addEventListener('mouseout',()=>{
                                        tooltipEl.style.opacity = 0;
                                    });

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    // Set Text
                                    if (tooltipModel.body) {
                                        const titleLines = tooltipModel.title || [];
                                        const bodyLines = tooltipModel.body.map(b => b.lines);

                                        let innerHtml = '<thead>';
                                        
                                        // title
                                        innerHtml += '<tr><th>' + titleLines[0] + '</th></tr>';
                                        innerHtml += '</thead><tbody>';

                                        bodyLines.forEach(function(body, i) {
                                            var label = '';
                                            var body_label = body[0].split(': ');
                                            var value = tooltipModel.dataPoints[i].raw;
                                            
                                            var colors = tooltipModel.labelColors[i];
                                            var style = 'background-color:' + colors.backgroundColor + '; width:10px; height:2px; display:inline-block; margin-right:5px'
                                            var colorDiv = '<div style="' + style + '"></div>';

                                            if(self.num_of_datasets > 1){
                                                if(self.formatType == 'money')
                                                    label = body_label[0] + ': ' + self.$options.filters.currency_with_zero(value);
                                                if(self.formatType == 'number' && self.max_value >= 10)
                                                    label = body_label[0] + ': ' + self.$options.filters.number_with_zero(value);
                                                if(self.formatType == 'number' && self.max_value < 10)
                                                    label = body_label[0] + ': ' + self.$options.filters.number_with_decimal(value);
                                                if(self.formatType == 'percent')
                                                    label = body_label[0] + ': ' + self.$options.filters.percentage(value);
                                            }else{
                                                if(self.formatType == 'money')
                                                    label = self.$options.filters.currency_with_zero(value);
                                                if(self.formatType == 'number' && self.max_value >= 10)
                                                    label = self.$options.filters.number_with_zero(value);
                                                if(self.formatType == 'number' && self.max_value < 10)
                                                    label = self.$options.filters.number_with_decimal(value);
                                                if(self.formatType == 'percent')
                                                    label = self.$options.filters.percentage(value);
                                            }

                                            const span = '<span>' + label + '</span>';
                                            innerHtml += '<tr><td>' + colorDiv + span + '</td></tr>';
                                        });

                                        if(self.if_on_report_page && !self.if_benchmark_report){
                                            innerHtml += '<hr class="m-1">';
                                            if (self.if_chart_filter_applied && self.chart_filter_label == titleLines[0]){
                                                innerHtml += '<button class="btn btn-none tooltip-btn-none mt-1 ps-0"><img class="icon ps-0" src="/img/icons/dialexa-icons/filter-search.svg">Remove as Filter</button>'
                                            } else {
                                                innerHtml += '<button class="btn btn-none tooltip-btn-none mt-1 ps-0"><img class="icon ps-0" src="/img/icons/dialexa-icons/filter-search.svg">Explore</button>'
                                            }                                            
                                      
                                            innerHtml += '</tbody>';  

                                            let tableRoot = tooltipEl.querySelector('table');
                                            tableRoot.innerHTML = innerHtml;

                                            let btn = tooltipEl.querySelector('button');
                                            if (self.if_chart_filter_applied && self.chart_filter_label != titleLines[0]){
                                                btn.disabled=true;
                                            }
                                            // if chart filter applied on any other chart, disable explore for this current chart to block cross filtering for now
                                            // this is temporyary till we add cross filtering in
                                            if (self.applied_chart_filter.if_chart_filter_applied && self.if_chart_filter_applied == false){
                                                btn.disabled=true;
                                            }

                                            if (self.if_chart_filter_applied){
                                                btn.addEventListener('click', () => {
                                                    self.if_chart_filter_applied = false;
                                                    self.$emit('exploreChart', {
                                                        chart_selected: null,
                                                        chart_filters: []
                                                    });
                                                    var dataset = self.getChartData(); 
                                                    self.chart.data = dataset;
                                                    self.chart.update();
                                                });
                                            } else {
                                                btn.addEventListener('click', () => {
                                                    self.if_chart_filter_applied = true;
                                                    self.chart_filter_label = titleLines[0];
                                                    self.$emit('exploreChart', {
                                                        chart_selected: self.chart_selected,
                                                        chart_filters: [{
                                                            name: self.chart_selected.group_by[0].text,
                                                            column: self.chart_selected.group_by[0].value,
                                                            value: titleLines[0]
                                                        }]
                                                    });

                                                    // set the other columns as grayscale and change button text
                                                    var dataset = self.getChartData(); 
                                                    let btn = tooltipEl.querySelector('button');
                                                    btn.textContent = "Remove as Filter";
                                                    self.chart.data = dataset;
                                                    self.chart.update();
                                                });
                                            }
                                        } else { // benchmark chart tooltip
                                            innerHtml += '</tbody>';  
                                            let tableRoot = tooltipEl.querySelector('table');
                                            tableRoot.innerHTML = innerHtml;
                                        }

                                        // tableRoot.appendChild(btn);
                                    }  
                                }
                            },
                            datalabels: {
                                anchor: 'start', // Specify the anchor position for the labels
                                align: 'end', // Specify the alignment of the labels
                                color: this.getColor(this.color)[0],
                                font: {                              
                                    size: this.font_size,
                                },
                                // clamp: true,
                                formatter: function(value, context) {
                                    if(!self.chart_selected.show_all_data_labels && context.datasetIndex < data.datasets.length-1)
                                        return null;

                                    if (self.if_chart_filter_applied){
                                        var chart_filter_index = self.chart_selected.labels.findIndex((label) => label == self.chart_filter_label)// use to keep track on which data to explore one
                                        if(context.dataIndex != chart_filter_index)
                                            return null;
                                    }

                                    if(self.formatType == 'money' && value < 1000)
                                        var label = self.$options.filters.currency_with_zero(value);
                                    if(self.formatType == 'money')
                                        var label = self.$options.filters.currency_abbr(value);
                                    if(self.formatType == 'number' && value >= 1000)
                                        var label = self.$options.filters.num_abbr(value);
                                    if(self.formatType == 'number' && value < 1000 && self.max_value >= 10)
                                        var label = self.$options.filters.number_with_zero(value);
                                    if(self.formatType == 'number' && value < 1000 && self.max_value < 10)
                                        var label = self.$options.filters.number_with_decimal(value);
                                    if(self.formatType == 'percent')
                                        var label = self.$options.filters.percentage(value);

                                    return label;
                                },
                            }    
                        },
                        title: {
                            display:false,
                        },
                    }
               };
               return config;
           }
       }
   }
</script>
